import PropTypes from "prop-types";
import React from "react";
import Add from "../../../assets/add-icon.svg";
import Info from "../../../assets/info.svg";
import { Tooltip } from "react-tooltip";
import { Field } from "formik";
import Input from "../../Input/Input";
import TagsInput from "react-tagsinput";

export const AddMore = (props) => {
  const { onClick } = props;

  return (
    <div className="add-icon" onClick={onClick}>
      <img src={Add} alt="add-icon" /> <span>Add More</span>
    </div>
  );
};

AddMore.propTypes = {
  onClick: PropTypes.func,
};

export const Configuration = (props) => {
  const { state, setstate, component1, component2, component3 } = props;

  const setRequest1 = (index) => {
    setstate({
      ...state,
      request1: true,
      request2: false,
      request3: false,
      addedKeys: [{ key: "", value: "" }],
      index: index,
    });
  };

  const setRequest2 = (index) => {
    setstate({
      ...state,
      request1: false,
      request2: true,
      request3: false,
      addedKeys: [{ key: "", value: "" }],
      index: index,
    });
  };

  const setRequest3 = (index) => {
    setstate({
      ...state,
      request1: false,
      request2: false,
      request3: true,
      addedKeys: [{ key: "", value: "" }],
      index: index,
    });
  };

  const requests = [
    {
      type: "REST",
      setRequest: setRequest1,
    },
    {
      type: "SOAP",
      setRequest: setRequest2,
    },
    {
      type: "SMPP",
      setRequest: setRequest3,
    },
  ];

  return (
    <div className="configuration">
      <h2>API Configuration</h2>
      <div className="configuration-div">
        {requests &&
          requests.map((request, index) => (
            <div
              className={
                (state.request1 || state.request2 || state.request3) &&
                index === state.index
                  ? "configuration-div-box2"
                  : "configuration-div-box1"
              }
              key={index}
              data-testid={`setRequest${index}`}
              onClick={() => request.setRequest(index)}
            >
              {request.type}
            </div>
          ))}
      </div>

      {state.request1 ? (
        <div>{component1}</div>
      ) : state.request2 ? (
        <div>{component2}</div>
      ) : (
        <div>{component3}</div>
      )}
    </div>
  );
};

Configuration.propTypes = {
  component1: PropTypes.any,
  component2: PropTypes.any,
  component3: PropTypes.any,
  onClick: PropTypes.any,
  setstate: PropTypes.func,
  state: PropTypes.shape({
    index: PropTypes.any,
    request1: PropTypes.any,
    request2: PropTypes.any,
    request3: PropTypes.any,
  }),
};

export const RequestData = (props) => {
  const { title, text, name, show, placeholder } = props;

  return (
    <div className="request">
      <div className="request-header">
        <h2>{title}</h2>
        {!show && (
          <>
            <img src={Info} alt="info-icon" data-tip={text} delay-show="1000" />
            <Tooltip
              content={text}
              place="right"
              type="dark"
              multiline={true}
            />
          </>
        )}
      </div>

      <Field name={name}>
        {({ field, form, meta }) => {
          const errorCheck =
            form && form.touched[field.name] && field.value === "";

          return (
            <>
              <pre>
                <textarea
                  className={errorCheck ? "error-border" : "textarea"}
                  rows="4"
                  cols="50"
                  name={field && field.name}
                  value={field && field.value}
                  onChange={field && field.onChange}
                  onBlur={field && field.onBlur}
                  onFocus={field && field.onFocus}
                  placeholder={placeholder}
                />
              </pre>

              {errorCheck && (
                <small className="errors">{title} cannot be empty</small>
              )}
            </>
          );
        }}
      </Field>
    </div>
  );
};

RequestData.propTypes = {
  request: PropTypes.any,
  text: PropTypes.any,
  title: PropTypes.any,
};

export const ProviderInput = (props) => {
  const { className, name, placeholder, type, label } = props;

  const validUrl = /^(ftp|http|https):\/\/[^ "]+$/;
  const validNo = /^\d+$/;

  return (
    <div className={className}>
      <Field name={name}>
        {({ field, form, meta }) => {
          const errorCheck =
            form && form.touched[field.name] && field.value === "";
          const errorCheck2 = form && form.touched[field.name];
          const urlValidation =
            errorCheck2 &&
            !errorCheck &&
            field &&
            field.name === "url" &&
            !field.value.match(validUrl);
          const numberValidation =
            errorCheck2 &&
            !errorCheck &&
            field &&
            field.name === "destination" &&
            !field.value.match(validNo);

          return (
            <>
              <Input
                className={
                  errorCheck || urlValidation ? "error-border" : "input"
                }
                placeholder={placeholder}
                type={type}
                label={label}
                name={field && field.name}
                value={field && field.value}
                onChange={field && field.onChange}
                onBlur={field && field.onBlur}
                onFocus={field && field.onFocus}
              />

              {errorCheck && (
                <small className="errors">{label} cannot be empty</small>
              )}
              {urlValidation && (
                <small className="errors">{label} must be a valid URL</small>
              )}
              {numberValidation && (
                <small className="errors">
                  {label} must be a valid phone number
                </small>
              )}
            </>
          );
        }}
      </Field>
    </div>
  );
};

ProviderInput.propTypes = {
  request: PropTypes.any,
  text: PropTypes.any,
  title: PropTypes.any,
};

export const StatusResponseInput = (props) => {
  const { name, placeholder, type, label, readOnly, value } = props;

  return (
    <Field name={name}>
      {({ field, form }) => {
        const errorCheck =
          form && form.touched[field.name] && field.value === "";
        return (
          <div className="provider-input-block">
            <Input
              className={errorCheck ? "error-border" : "input"}
              placeholder={placeholder}
              type={type}
              label={label}
              name={field && field.name}
              value={(field && field.value) || value}
              onChange={field && field.onChange}
              onBlur={field && field.onBlur}
              onFocus={field && field.onFocus}
              readOnly={readOnly}
            />

            {errorCheck && (
              <small className="errors">{label} cannot be empty</small>
            )}
          </div>
        );
      }}
    </Field>
  );
};

export const StatusResponse = (props) => {
  return (
    <div className="response-div">
      <h2 className="add-provider-header">{props.title}</h2>
      <div className="form-grid3">
        <StatusResponseInput
          name={props.name1}
          label={props.label1}
          placeholder={props.placeholder1}
          readOnly={props.readOnly1}
          value={props.value1}
        />

        <StatusResponseInput
          name={props.name2}
          label={props.label2}
          placeholder={props.placeholder2}
          readOnly={props.readOnly2}
          value={props.value2}
        />
      </div>
    </div>
  );
};

export const MultiTags = (props) => {
  const { label, info, tags, handleChange, top, placeholder, testid } = props;

  return (
    <div className="provider-multitag" style={{ marginTop: top }}>
      <p>
        {label} <small>({info})</small>
      </p>
      <TagsInput
        value={tags}
        onChange={handleChange}
        inputProps={{
          placeholder: placeholder,
          "data-testid": testid,
        }}
      />
    </div>
  );
};

MultiTags.propTypes = {
  handleChange: PropTypes.any,
  info: PropTypes.any,
  label: PropTypes.any,
  placeholder: PropTypes.any,
  tags: PropTypes.any,
  top: PropTypes.any,
};
