import PropTypes from "prop-types";
import React from "react";
import { testProvider } from "../../../redux/Actions/ProviderActions";
import Button from "../../Button/Button";
import { connect } from "react-redux";
import { handleProviderTest } from "../../../utils/functions/provider";
import { ProviderInput, RequestData } from "../AddProvider/Components";
import Testing from "../AddProvider/Testing";

const EditProviderScreen4 = (props) => {
  const {
    state,
    isTesting,
    testSuccess,
    testProvider,
    goBack,
    isEditing,
    values,
    setStep3,
    setState,
  } = props;

  const disabled =
    values.sender === "" ||
    values.message === "" ||
    values.destination === "" ||
    !values.destination.match(/^\d+$/);

  const runTestStart = () => {
    setState({
      ...state,
      showLoaders: true,
    });
    runTest();
  };

  const runTest = () => {
    handleProviderTest(state, values, testProvider, state.addHook);
  };

  return state.showLoaders ? (
    <Testing
      isTesting={isTesting}
      testSuccess={testSuccess}
      goBack={goBack}
      text={isEditing ? "Saving..." : "Save Changes"}
      disabled={isEditing}
    />
  ) : (
    <div>
      {state.requestStep4 && <p className="steps">Step 4 of 4</p>}

      <h2 className="add-provider-header">Test Credentials</h2>

      <div className="form-grid">
        <ProviderInput name="sender" label="Sender" placeholder="Sender" />
      </div>

      <div>
        <RequestData
          title={"Message"}
          name={"message"}
          show={true}
          placeholder={"Message"}
        />
      </div>

      <div className="form-grid">
        <ProviderInput
          name="destination"
          label="Destination"
          placeholder="Destination"
        />
      </div>

      <div className="provider-btn-flex">
        <Button
          width={"47%"}
          height={"48px"}
          testId={"button"}
          type={"button"}
          marginTop={"32px"}
          className={"button2"}
          onClick={() => setStep3()}
        >
          Previous
        </Button>
        <Button
          width={"47%"}
          height={"48px"}
          testId={"button"}
          type={"submit"}
          marginTop={"32px"}
          onClick={() => runTestStart()}
          disabled={disabled}
        >
          Test
        </Button>
      </div>
    </div>
  );
};

EditProviderScreen4.propTypes = {
  goBack: PropTypes.any,
  isEditing: PropTypes.bool,
  isTesting: PropTypes.bool,
  setState: PropTypes.func,
  setStep3: PropTypes.func,
  state: PropTypes.shape({
    addHook: PropTypes.any,
    requestStep4: PropTypes.any,
    showLoaders: PropTypes.any,
  }),
  testProvider: PropTypes.any,
  testSuccess: PropTypes.bool,
  values: PropTypes.shape({
    destination: PropTypes.shape({
      match: PropTypes.func,
    }),
    message: PropTypes.string,
    sender: PropTypes.string,
  }),
};

const mapDispatchToProps = (dispatch) => ({
  testProvider: (payload) => dispatch(testProvider(payload)),
});

const mapStateToProps = (state) => ({
  isEditing: state.provider.isEditing,
  isTesting: state.provider.isTesting,
  testSuccess: state.provider.testSuccess,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditProviderScreen4);
