import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";

// GET REPORTING
export const getReportsStart = () => ({
  type: Actions.GET_ROUTER_REPORTS_START,
});

export const getReportsSuccess = (payload) => ({
  type: Actions.GET_ROUTER_REPORTS_SUCCESS,
  payload,
});

export const getReportsFailure = (payload) => ({
  type: Actions.GET_ROUTER_REPORTS_FAILURE,
  payload,
});

// GET SINGLE REPORTING
export const getSingleReportStart = () => ({
  type: Actions.GET_SINGLE_ROUTER_REPORT_START,
});

export const getSingleReportSuccess = (payload) => ({
  type: Actions.GET_SINGLE_ROUTER_REPORT_SUCCESS,
  payload,
});

export const getSingleReportFailure = (payload) => ({
  type: Actions.GET_SINGLE_ROUTER_REPORT_FAILURE,
  payload,
});

// SEARCH REPORTING
export const searchReportsStart = () => ({
  type: Actions.SEARCH_ROUTER_REPORTS_START,
});

export const searchReportsSuccess = (payload) => ({
  type: Actions.SEARCH_ROUTER_REPORTS_SUCCESS,
  payload,
});

export const searchReportsFailure = (payload) => ({
  type: Actions.SEARCH_ROUTER_REPORTS_FAILURE,
  payload,
});

// FILTER REPORTING
export const filterReportsStart = () => ({
  type: Actions.FILTER_ROUTER_REPORTS_START,
});

export const filterReportsSuccess = (payload) => ({
  type: Actions.FILTER_ROUTER_REPORTS_SUCCESS,
  payload,
});

export const filterReportsFailure = (payload) => ({
  type: Actions.FILTER_ROUTER_REPORTS_FAILURE,
  payload,
});

export const getReports = (page, limit) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(getReportsStart());
  API.get(`rsms/history?page=${page}&limit=${limit}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getReportsSuccess(data && data.data));
    } else {
      dispatch(getReportsFailure(data));
      responseHandler(response);
    }
  });
};

export const getSingleReport = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(getSingleReportStart());
  API.get(`rsms/history/${id}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getSingleReportSuccess(data && data.data));
    } else {
      dispatch(getSingleReportFailure(data));
      responseHandler(response);
    }
  });
};

export const searchReports = (page, limit, search) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(searchReportsStart());
  API.get(`rsms/history?page=${page}&limit=${limit}&search=${search}`).then(
    (response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(searchReportsSuccess(data && data.data));
      } else {
        dispatch(searchReportsFailure(data));
        responseHandler(response);
      }
    }
  );
};

export const filterReports = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));
  dispatch(filterReportsStart());
  API.get(
    payload.search !== ""
      ? `rsms/history?page=${payload.page}&limit=${payload.limit}&search=${payload.search}&country=${payload.country}&status=${payload.status}&startDate=${payload.startDate}&endDate=${payload.endDate}`
      : payload.status === ""
      ? `rsms/history?page=${payload.page}&limit=${payload.limit}&country=${payload.country}&startDate=${payload.startDate}&endDate=${payload.endDate}`
      : payload.search === "" && payload.status === ""
      ? `rsms/history?page=${payload.page}&limit=${payload.limit}&country=${payload.country}&startDate=${payload.startDate}&endDate=${payload.endDate}`
      : `rsms/history?page=${payload.page}&limit=${payload.limit}&country=${payload.country}&status=${payload.status}&startDate=${payload.startDate}&endDate=${payload.endDate}`
  ).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(filterReportsSuccess(data && data.data));
    } else {
      dispatch(filterReportsFailure(data));
      responseHandler(response);
    }
  });
};
