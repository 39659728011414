import React from "react";
import AuthWrapper, { Headers } from "../../components/Wrappers/AuthWrapper";
import Button from "../../components/Button/Button";
import { Formik, Field } from "formik";
import Input from "../../components/Input/Input";

export const ForgotPassword = () => {
  return (
    <AuthWrapper>
      <Headers
        title1={"Forgot Password"}
        title2={"Enter your email to reset password"}
      />

      <Formik initialValues={{ email: "" }} onSubmit={""}>
        {({ isValid, dirty }) => {
          return (
            <div>
              <div className="form-grid">
                <Field
                  name="email"
                  label="Email Address"
                  placeholder="Enter email address"
                  component={Input}
                />
              </div>

              <Button
                width={"100%"}
                // onClick={""}
                disabled={""}
                height={"50px"}
                testId={"button"}
                type={"submit"}
                marginTop={"32px"}
              >
                Recover Password
              </Button>
              <a href="/" className="forgot-password login-link">
                Log In
              </a>
            </div>
          );
        }}
      </Formik>
    </AuthWrapper>
  );
};

export default ForgotPassword;
