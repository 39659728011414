import PropTypes from "prop-types";
import React from "react";
import Back from "../../../assets/back.svg";
import { StatusResponse } from "./Components";

const Example = (props) => {
  const data = [
    {
      response: `{
          "account_sid": "ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
          "api_version": "2010-04-01",
          "body": "Hi there!",
          "date_created": "Thu, 30 Jul 2015 20:12:31 +0000",
          "date_sent": "Thu, 30 Jul 2015 20:12:33 +0000",
          "date_updated": "Thu, 30 Jul 2015 20:12:33 +0000",
          "direction": "outbound-api",
          "error_code": null,
          "error_message": null,
          "from": "+14155552345",
          "messaging_service_sid": null,
          "num_media": "0",
          "num_segments": "1",
          "price": null,
          "price_unit": null,
          "sid": "SMXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX",
          "status": "sent",
          "subresource_uris": {
            "media": "/2010-04-01/Accounts/ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX/Messages/SMXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX/Media.json"
          },
          "to": "+14155552345",
          "uri": "/2010-04-01/Accounts/ACXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX/Messages/SMXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX.json"
        }`,
    },
    {
      response: `{
      "bulkId": "2034072219640523072", 
      "messages": [
      {
        "to": "41793026727",
        "status": {
          "groupId": 1,
          "groupName": "PENDING",
          "id": 26,
          "name": "MESSAGE_ACCEPTED",
          "description": "Message sent to next instance"
        },
       "messageId": "2250be2d4219-3af1-78856-aabe-1362af1edfd2"
      }
      ]
     }`,
    },
    {
      response: `{
        "bulkId": "2034072219640523072", 
        "messages": [
        {
          "to": "41793026727",
          "status": {
            "groupId": 1,
            "groupName": "PENDING",
            "id": 26,
            "name": "MESSAGE_ACCEPTED",
            "description": "Message sent to next instance"
          },
         "messageId": "2250be2d4219-3af1-78856-aabe-1362af1edfd2"
        }
        ]
       }`,
    },
  ];

  return (
    <div className="provider-example">
      <div className="provider-example-header">
        <img src={Back} alt="back-icon" onClick={() => props.hideExample()} />{" "}
        <span>Back</span>
      </div>

      <h2 className="add-provider-header">Initial Response Example</h2>
      <div className="provider-example-scroll">
        {data &&
          data.map((item, index) => (
            <div className="provider-example-scroll-box-wrap" key={index}>
              <div className="provider-example-scroll-box">
                <pre>{item.response}</pre>
              </div>
            </div>
          ))}
      </div>

      <StatusResponse
        title="Initial Response"
        name1="status_path"
        label1="Status Path"
        placeholder1="Status Path"
        value1={"data/name/status"}
        readOnly1
        name2="id_path"
        label2="Messsage ID Path"
        placeholder2="Messsage ID Path"
        value2={"data/name/message"}
        readOnly2
      />

      <StatusResponse
        title="Final Response"
        name1="status_path"
        label1="Status Path"
        placeholder1="Status Path"
        value1={"data/name/status"}
        readOnly1
        name2="id_path"
        label2="Messsage ID Path"
        placeholder2="Messsage ID Path"
        value2={"data/name/message"}
        readOnly2
      />
    </div>
  );
};

Example.propTypes = {
  hideExample: PropTypes.func,
};

export default Example;
