import React from "react";
import PasswordWrap from "../../components/Wrappers/PasswordWrap";

const ResetPassword = () => {
  return (
    <PasswordWrap
      title1="Reset Password"
      title2="Create a new password to proceed"
      submitValues={""}
      type="Reset Password"
    />
  );
};
export default ResetPassword;
