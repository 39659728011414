import PropTypes from "prop-types";
import React from "react";
import AuthWrapper, { Headers } from "./AuthWrapper";
import Button from "../Button/Button";
import { Formik, Field } from "formik";
import Input from "../Input/Input";

const PasswordWrap = (props) => {
  const { title1, title2, submitValues, type } = props;

  return (
    <AuthWrapper>
      <Headers title1={title1} title2={title2} />
      <div>
        <Formik
          initialValues={{ password: "", confirmPassword: "" }}
          onSubmit={submitValues}
        >
          {({ isValid, dirty }) => {
            return (
              <div>
                <div className="form-grid">
                  <Field
                    name="password"
                    label="Password"
                    placeholder="Enter Password"
                    component={Input}
                    type="password"
                    password
                  />
                </div>
                <div className="form-grid">
                  <Field
                    name="confirmPassword"
                    label="Confirm Password"
                    placeholder="Enter password"
                    component={Input}
                    type="password"
                    password
                  />
                </div>

                <Button
                  width={"100%"}
                  // onClick={""}
                  disabled={""}
                  height={"50px"}
                  testId={"button"}
                  type={"submit"}
                  marginTop={"32px"}
                >
                  {type}
                </Button>
              </div>
            );
          }}
        </Formik>
      </div>
    </AuthWrapper>
  );
};

PasswordWrap.propTypes = {
  submitValues: PropTypes.any,
  title1: PropTypes.any,
  title2: PropTypes.any,
  type: PropTypes.any,
};
export default PasswordWrap;
