import React from "react";
import AuthWrapper, { Headers } from "../../components/Wrappers/AuthWrapper";
import Storage from "../../utils/services/storage";
import AuthenticateForm from "./VerifyForm";

const VerifyAccount = () => {
  const emailAddress = Storage.get("email-value");

  return (
    <AuthWrapper>
      <Headers
        title1={"Please enter your OTP to verify account"}
        title2={`A code has been sent to ${emailAddress}`}
      />
      <AuthenticateForm emailAddress={emailAddress} />
    </AuthWrapper>
  );
};
export default VerifyAccount;
