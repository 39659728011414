import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";

// ADMIN
export const getAdminRolesStart = () => ({
  type: Actions.GET_ADMIN_ROLES_START,
});

export const getAdminRolesSuccess = (payload) => ({
  type: Actions.GET_ADMIN_ROLES_SUCCESS,
  payload,
});

export const getAdminRolesFailure = (payload) => ({
  type: Actions.GET_ADMIN_ROLES_FAILURE,
  payload,
});

export const searchUserStart = () => ({
  type: Actions.SEARCH_USER_START,
});

export const searchUserSuccess = (payload) => ({
  type: Actions.SEARCH_USER_SUCCESS,
  payload,
});

export const searchUserFailure = (payload) => ({
  type: Actions.SEARCH_USER_FAILURE,
  payload,
});

export const sendInviteStart = () => ({
  type: Actions.SEND_INVITE_START,
});

export const sendInviteSuccess = (payload) => ({
  type: Actions.SEND_INVITE_SUCCESS,
  payload,
});

export const sendInviteFailure = (payload) => ({
  type: Actions.SEND_INVITE_FAILURE,
  payload,
});

export const getUsersStart = () => ({
  type: Actions.GET_USERS_START,
});

export const getUsersSuccess = (payload) => ({
  type: Actions.GET_USERS_SUCCESS,
  payload,
});

export const getUsersFailure = (payload) => ({
  type: Actions.GET_USERS_FAILURE,
  payload,
});

export const updateUserInfoStart = () => ({
  type: Actions.UPDATE_USER_INFO_START,
});

export const updateUserInfoSuccess = (payload) => ({
  type: Actions.UPDATE_USER_INFO_SUCCESS,
  payload,
});

export const updateUserInfoFailure = (payload) => ({
  type: Actions.UPDATE_USER_INFO_FAILURE,
  payload,
});

export const deleteUserInfoStart = () => ({
  type: Actions.DELETE_USER_INFO_START,
});

export const deleteUserInfoSuccess = (payload) => ({
  type: Actions.DELETE_USER_INFO_SUCCESS,
  payload,
});

export const deleteUserInfoFailure = (payload) => ({
  type: Actions.DELETE_USER_INFO_FAILURE,
  payload,
});

export const searchAdminUsersStart = () => ({
  type: Actions.SEARCH_ADMIN_USERS_START,
});

export const searchAdminUsersSuccess = (payload) => ({
  type: Actions.SEARCH_ADMIN_USERS_SUCCESS,
  payload,
});

export const searchAdminUsersFailure = (payload) => ({
  type: Actions.SEARCH_ADMIN_USERS_FAILURE,
  payload,
});

export const getAdminRoles = () => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(getAdminRolesStart());
  API.get("users/roles").then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getAdminRolesSuccess(data && data.data));
    } else {
      dispatch(getAdminRolesFailure(data));
      responseHandler(response);
    }
  });
};

export const searchUser = (payload, func) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(searchUserStart());
  API.post("users/active-directory/search", payload).then((response) => {
    const { data, ok } = response;
    responseHandler(response);
    if (ok) {
      dispatch(searchUserSuccess(data && data.data));
      const result = {
        email: data && data.data && data.data.mail,
        name: data && data.data && data.data.cn,
      };
      func(result);
    } else {
      dispatch(searchUserFailure(data));
    }
  });
};

export const sendInvite = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(sendInviteStart());
  API.post("users/invite", payload).then((response) => {
    const { data, ok } = response;
    responseHandler(response);
    if (ok) {
      dispatch(sendInviteSuccess(data && data.data));
    } else {
      dispatch(sendInviteFailure(data));
    }
  });
};

export const getUsers = (page, limit) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(getUsersStart());
  API.get(`users?page=${page}&limit=${limit}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getUsersSuccess(data && data.data));
    } else {
      dispatch(getUsersFailure(data));
      responseHandler(response);
    }
  });
};

export const updateUserInfo = (id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(updateUserInfoStart());
  API.post(`users/update/${id}`, payload).then((response) => {
    const { data, ok } = response;
    responseHandler(response);
    if (ok) {
      dispatch(updateUserInfoSuccess(data && data.data));
    } else {
      dispatch(updateUserInfoFailure(data));
    }
  });
};

export const deleteUserInfo = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(deleteUserInfoStart());
  API.delete(`users/${id}`).then((response) => {
    const { data, ok } = response;
    responseHandler(response);
    if (ok) {
      dispatch(deleteUserInfoSuccess(data && data.data));
    } else {
      dispatch(deleteUserInfoFailure(data));
    }
  });
};

export const searchAdminUsers = (search) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(searchAdminUsersStart());
  API.get(`users?search=${search}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(searchAdminUsersSuccess(data && data.data));
    } else {
      dispatch(searchAdminUsersFailure(data));
      responseHandler(response);
    }
  });
};
