import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { nameAcronym } from "../../utils/functions/acroymn";
import Sidebar2 from "../Sidebar/Sidebar2";
import Alarm from "../../assets/alarm.svg";
import Occupied from "../../assets/occupied.svg";
import DropdownIcon from "../../assets/dropdown.svg";
import Dropdown from "./Dropdown";
import Notifications from "../Notifications/Notifications";
import RequestsModal from "../Modal/RequestsModal";
import { getDecodedData } from "../../utils/functions/getStorageItems";
import { formatText } from "../../utils/functions/formatText";
import {
  authoriseRequest,
  getRequests,
} from "../../redux/Actions/AuthoriserActions";
import { connect } from "react-redux";

const Navbar = (props) => {
  const {
    getRequests,
    isGetting,
    data,
    getRequestsSuccess,
    isAuthorising,
    authoriseRequest,
    authoriseSuccess,
  } = props;

  const { name, type } = getDecodedData();
  const userRole = formatText(type);
  const role = userRole && userRole.toLowerCase();

  useEffect(
    () => {
      if (authoriseSuccess) {
        getRequests(1, 20);
      }
    },
    // eslint-disable-next-line
    [authoriseSuccess]
  );

  useEffect(
    () => {
      if (role === "checker") {
        getRequests(1, 20);
      }
    },
    // eslint-disable-next-line
    []
  );

  const [show, setShow] = useState(false);
  const [notif, setNotif] = useState(false);
  const lengthCheck = data && data.data && data.data.length > 0;
  const pendingRequests =
    lengthCheck &&
    data.data &&
    data.data.filter(
      (item) =>
        item.approval_state && item.approval_state.toLowerCase() === "pending"
    );

  return (
    <header className="navbar">
      <input type="checkbox" id="nav-check" />
      <Sidebar2 />

      <div className="navbar-left">
        <label htmlFor="nav-check">
          <div className="navbar-left-item1"></div>
          <div className="navbar-left-item2"></div>
          <div className="navbar-left-item3"></div>
        </label>
      </div>

      <div className="navbar-right">
        {role === "checker" && (
          <div
            className="navbar-right-notif"
            data-testid="setNotif"
            onClick={() => setNotif((prevCheck) => !prevCheck)}
          >
            <img
              src={
                pendingRequests && pendingRequests.length > 0 ? Occupied : Alarm
              }
              alt="alarm"
            />
          </div>
        )}

        <div className="navbar-right-user">
          <div className="navbar-right-user-names">
            <h2>{name}</h2>
            <h4>{formatText(type)}</h4>
          </div>
          <div className="navbar-right-user-image">{nameAcronym(name)}</div>
        </div>

        <div
          className="navbar-right-dropdown"
          data-testid="setShow"
          onClick={() => setShow((prevCheck) => !prevCheck)}
        >
          <img src={DropdownIcon} alt="dropdown" />
        </div>
        {show && (
          <Dropdown closeDropdown={() => setShow((prevCheck) => !prevCheck)} />
        )}
      </div>

      <RequestsModal
        displayModal={notif}
        closeModal={() => setNotif(false)}
        title={"Request Notifications"}
        width={"488px"}
      >
        <Notifications
          setShow={() => setNotif(true)}
          isGetting={isGetting}
          getRequestsSuccess={getRequestsSuccess}
          getRequests={getRequests}
          data={data}
          isAuthorising={isAuthorising}
          authoriseSuccess={authoriseSuccess}
          authoriseRequest={(url, payload) => authoriseRequest(url, payload)}
        />
      </RequestsModal>
    </header>
  );
};

Navbar.propTypes = {
  authoriseRequest: PropTypes.func,
  authoriseSuccess: PropTypes.bool,
  data: PropTypes.shape({
    data: PropTypes.shape({
      filter: PropTypes.func,
      length: PropTypes.number,
    }),
  }),
  getRequests: PropTypes.func,
  getRequestsSuccess: PropTypes.bool,
  isAuthorising: PropTypes.bool,
  isGetting: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isGetting: state.checker.isGettingRequests,
  getRequestsSuccess: state.checker.getRequestsSuccess,
  data: state.checker.data,
  isAuthorising: state.checker.isAuthorisingRequest,
  authoriseSuccess: state.checker.authoriseRequestSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getRequests: (page, limit) => dispatch(getRequests(page, limit)),
  authoriseRequest: (url, payload) => dispatch(authoriseRequest(url, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
