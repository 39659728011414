import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Routes from "./routes";
import { NotificationContainer } from "react-notifications";
import { setCurrentUser } from "./redux/Actions/AuthActions";

function App({ checkUser }) {
  useEffect(() => {
    checkUser();
  }, [checkUser]);

  return (
    <>
      <NotificationContainer /> <Routes />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  checkUser: () => dispatch(setCurrentUser()),
});

export default connect(null, mapDispatchToProps)(withRouter(App));
