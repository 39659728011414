import PropTypes from "prop-types";
import React from "react";
import { useHistory } from "react-router";
import Error from "../../assets/notfound.svg";
import Button from "../../components/Button/Button";
import Navbar from "../../components/Navbar/Navbar";
import { connect } from "react-redux";
import { jwtDecode } from "jwt-decode";
import Storage from "../../utils/services/storage";

const NotFound = ({ currentUser }) => {
  const history = useHistory();

  const storageUser =
    Storage.get("user-access-token") &&
    jwtDecode(Storage.get("user-access-token"));

  const noUser = !currentUser && !storageUser;

  const routeUser = () => {
    if (noUser) {
      history.push("/");
    } else {
      history.push("/dashboard");
    }
  };

  return (
    <div className="container2">
      {!noUser && <Navbar />}
      <div className="notfound">
        <div className="notfound-container">
          <div className="notfound-container-image">
            <img src={Error} alt="notfound-icon" />
          </div>
          <h1>Page not found</h1>
          <h3>
            Oops!!! Sorry, we can’t find the page that you are looking for.
            Kindly return to the {noUser ? "login" : "dashboard"} page.
          </h3>
          <Button
            width={"auto"}
            height={"48px"}
            testId={"not-found-button"}
            type={"submit"}
            marginTop={"32px"}
            onClick={() => routeUser()}
          >
            {noUser ? "Back to Login" : "Back to Dashboard"}
          </Button>
        </div>
      </div>
    </div>
  );
};

NotFound.propTypes = {
  currentUser: PropTypes.object,
};

const mapStateToProps = (state) => ({
  currentUser: state.auth.currentUser,
});

export default connect(mapStateToProps)(NotFound);
