import React, { useState } from "react";
import {
  ErrorBtn,
  SuccessBtn,
  DropBtn,
  PendingBtn,
} from "../../Tables/TableButtons";
import { nameAcronym } from "../../../utils/functions/acroymn";
import ReactCountryFlag from "react-country-flag";
import TableDropdown from "../../Tables/Dropdown";
import { useHistory } from "react-router";
import moment from "moment";
import {countryCodeToName, countryCodeToProvider} from "../../../utils/mappers/mappers.js"

export const reportColumns = [
  {
    Header: "Provider Name",
    accessor: "provider_name",
    Cell: ({ row }) => (
      <div className="table-flex">
        <div className="table-flex-image">
          {nameAcronym(countryCodeToProvider[row.original.country_code])}
        </div>
        <span style={{ marginLeft: "12px" }}>{countryCodeToProvider[row.original.country_code]}</span>
      </div>
    ),
  },
  {
    Header: "Country",
    accessor: "country",
    Cell: ({ row }) => (
      <div className="table-flex">
        <ReactCountryFlag
          countryCode={`${row.original.country_code}`}
          style={{
            width: "32px",
            height: "32px",
            borderRadius: "50%",
          }}
          svg
          title={`${row.original.country_code}`}
        />
        <span style={{ marginLeft: "12px" }}>{countryCodeToName[row.original.country_code]}</span>
      </div>
    ),
  },
  {
    Header: "SMS Source",
    accessor: "sender",
  },
  {
    Header: "Receiver's Phone No.",
    accessor: "destination",
    Cell: ({ row }) => (
      <div className="table-flex">
        <span style={{ marginLeft: "12px" }}>
          &#x2b; {row.original.destination}
        </span>
      </div>
    ),
  },
  {
    Header: "Status",
    accessor: "status",

    Cell: ({ row }) =>
    row.original.success === true ? (
      <SuccessBtn text={`${moment(row.original.request_received).format("LL") + " | " + moment(row.original.sms_sent).format("LTS")}`} />
    ) : row.original.success === false ? (
      <ErrorBtn text="failed" />
    ) : row.original.status === null ? (
      <PendingBtn text="pending" />
    ) : (
        "Nil"
      ),
  },

  {
    Header: "Date Added",
    accessor: "date",
    Cell: ({ row }) => {
      const [show, setShow] = useState(false);

      const history = useHistory();

      const goToSingleReport = () => {
        const id = row.original && row.original.reference_id;
        history.push({
          pathname: `/router_reporting/${id}`,
          state: {
            id: id,
            data: row.original,
          },
        });
      };
      const options = [
        {
          name: "View",
          action: () => goToSingleReport(),
        },
      ];

      return (
        <div className="table-flex">
          <span className="table-flex-date">
            {moment(row.original.request_received).format("LL")} |{" "}
            <small>{moment(row.original.request_received).format("LTS")}</small>
          </span>
          <DropBtn
            marginLeft={"16px"}
            onClick={() => setShow((prevCheck) => !prevCheck)}
          />

          {show && <TableDropdown options={options} />}
        </div>
      );
    },
  },
];
