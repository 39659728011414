import PropTypes from "prop-types";
import React from "react";

export const TableDropdown = (props) => {
  const { options } = props;

  return (
    <div className="table-dropdown">
      {options &&
        options.map((option, index) => (
          <div className="table-dropdown-item" onClick={option.action} key={index}>
            <div className="table-dropdown-item-text">{option.name}</div>
          </div>
        ))}
    </div>
  );
};

TableDropdown.propTypes = {
  closeDropdown: PropTypes.func,
};

export default TableDropdown;
