import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./RootReducer";
import logger from "redux-logger";
import { thunk } from "redux-thunk";

const middleware = [thunk];

if (process.env.NODE_ENV === "develop") {
  middleware.push(logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(
  applyMiddleware(...middleware)
));

export default store;
