import PropTypes from "prop-types";
import React from "react";
import {
  testProvider,
  testProviderSmpp,
} from "../../../redux/Actions/ProviderActions";
import Button from "../../Button/Button";
import { connect } from "react-redux";
import { ProviderInput, RequestData } from "./Components";
import Testing from "./Testing";
import {
  handleProviderSmppTest,
  handleProviderTest,
} from "../../../utils/functions/provider";

const AddProviderScreen4 = (props) => {
  const {
    addstate,
    setAddState,
    state,
    isTesting,
    testSuccess,
    isTestingSmpp,
    testSmppSuccess,
    testProviderSmpp,
    testProvider,
    goBack,
    isAdding,
    values,
    setStep3,
  } = props;

  const disabled =
    values.message === "" ||
    values.destination === "" ||
    !values.destination.match(/^\d+$/);

  const runTestStart = () => {
    setAddState({
      ...addstate,
      showLoaders: true,
    });
    runTest();
  };

  const runTest = () => {
    if (state.request3) {
      handleProviderSmppTest(state, values, testProviderSmpp);
    } else {
      handleProviderTest(state, values, testProvider, addstate.addHook);
    }
  };

  return addstate.showLoaders ? (
    <Testing
      isTesting={state.request3 ? isTestingSmpp : isTesting}
      testSuccess={state.request3 ? testSmppSuccess : testSuccess}
      goBack={goBack}
      text={isAdding ? "Saving..." : "Save Provider"}
      disabled={isAdding}
    />
  ) : (
    <div>
      {state.requestStep4 && (
        <p className="steps">
          {state.request3 ? "Step 3 of 3" : "Step 4 of 4"}
        </p>
      )}

      <h2 className="add-provider-header">Test Credentials</h2>

      {!state.request3 && (
        <div className="form-grid">
          <ProviderInput name="sender" label="Sender" placeholder="Sender" />
        </div>
      )}

      <div>
        <RequestData
          title={"Message"}
          name={"message"}
          show={true}
          placeholder={"Message"}
        />
      </div>

      <div className="form-grid">
        <ProviderInput
          name="destination"
          label="Destination"
          placeholder="Destination"
        />
      </div>

      <div className="provider-btn-flex">
        <Button
          width={"47%"}
          height={"48px"}
          testId={"button"}
          type={"button"}
          marginTop={"32px"}
          className={"button2"}
          onClick={() => setStep3()}
        >
          Previous
        </Button>
        <Button
          width={"47%"}
          height={"48px"}
          testId={"button"}
          type={"submit"}
          marginTop={"32px"}
          onClick={() => runTestStart()}
          disabled={disabled}
        >
          Test
        </Button>
      </div>
    </div>
  );
};

AddProviderScreen4.propTypes = {
  addstate: PropTypes.shape({
    addHook: PropTypes.any,
    showLoaders: PropTypes.any,
  }),
  goBack: PropTypes.any,
  isAdding: PropTypes.bool,
  isTesting: PropTypes.bool,
  setAddState: PropTypes.func,
  setStep3: PropTypes.func,
  state: PropTypes.shape({
    requestStep4: PropTypes.bool,
  }),
  testProvider: PropTypes.any,
  testSuccess: PropTypes.bool,
  values: PropTypes.shape({
    destination: PropTypes.shape({
      match: PropTypes.func,
    }),
    message: PropTypes.string,
    sender: PropTypes.string,
  }),
};

const mapDispatchToProps = (dispatch) => ({
  testProvider: (payload) => dispatch(testProvider(payload)),
  testProviderSmpp: (payload) => dispatch(testProviderSmpp(payload)),
});

const mapStateToProps = (state) => ({
  isAdding: state.provider.isAdding,
  isTesting: state.provider.isTesting,
  testSuccess: state.provider.testSuccess,
  isTestingSmpp: state.provider.isTestingSmpp,
  testSmppSuccess: state.provider.testSmppSuccess,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddProviderScreen4);
