import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";
import { updateState } from "../../utils/functions/assignValues";

// ADD PROVIDER
export const addProviderStart = () => ({
  type: Actions.ADD_PROVIDER_START,
});

export const addProviderSuccess = (payload) => ({
  type: Actions.ADD_PROVIDER_SUCCESS,
  payload,
});

export const addProviderFailure = (payload) => ({
  type: Actions.ADD_PROVIDER_FAILURE,
  payload,
});

// TEST PROVIDER
export const testProviderStart = () => ({
  type: Actions.TEST_PROVIDER_START,
});

export const testProviderSuccess = (payload) => ({
  type: Actions.TEST_PROVIDER_SUCCESS,
  payload,
});

export const testProviderFailure = (payload) => ({
  type: Actions.TEST_PROVIDER_FAILURE,
  payload,
});

// TEST PROVIDER SMPP
export const testProviderSmppStart = () => ({
  type: Actions.TEST_PROVIDER_SMPP_START,
});

export const testProviderSmppSuccess = (payload) => ({
  type: Actions.TEST_PROVIDER_SMPP_SUCCESS,
  payload,
});

export const testProviderSmppFailure = (payload) => ({
  type: Actions.TEST_PROVIDER_SMPP_FAILURE,
  payload,
});

// EDIT PROVIDER
export const editProviderStart = () => ({
  type: Actions.EDIT_PROVIDER_START,
});

export const editProviderSuccess = (payload) => ({
  type: Actions.EDIT_PROVIDER_SUCCESS,
  payload,
});

export const editProviderFailure = (payload) => ({
  type: Actions.EDIT_PROVIDER_FAILURE,
  payload,
});

// DELETE PROVIDER
export const deleteProviderStart = () => ({
  type: Actions.DELETE_PROVIDER_START,
});

export const deleteProviderSuccess = (payload) => ({
  type: Actions.DELETE_PROVIDER_SUCCESS,
  payload,
});

export const deleteProviderFailure = (payload) => ({
  type: Actions.DELETE_PROVIDER_FAILURE,
  payload,
});

// GET ALL PROVIDERS
export const getAllProvidersStart = () => ({
  type: Actions.GET_ALL_PROVIDERS_START,
});

export const getAllProvidersSuccess = (payload) => ({
  type: Actions.GET_ALL_PROVIDERS_SUCCESS,
  payload,
});

export const getAllProvidersFailure = (payload) => ({
  type: Actions.GET_ALL_PROVIDERS_FAILURE,
  payload,
});

// SEARCH PROVIDERS
export const searchProvidersStart = () => ({
  type: Actions.SEARCH_PROVIDERS_START,
});

export const searchProvidersSuccess = (payload) => ({
  type: Actions.SEARCH_PROVIDERS_SUCCESS,
  payload,
});

export const searchProvidersFailure = (payload) => ({
  type: Actions.SEARCH_PROVIDERS_FAILURE,
  payload,
});

// FILTER PROVIDERS
export const filterProvidersStart = () => ({
  type: Actions.FILTER_PROVIDERS_START,
});

export const filterProvidersSuccess = (payload) => ({
  type: Actions.FILTER_PROVIDERS_SUCCESS,
  payload,
});

export const filterProvidersFailure = (payload) => ({
  type: Actions.FILTER_PROVIDERS_FAILURE,
  payload,
});

// GET SINGLE PROVIDER
export const getSingleProviderStart = () => ({
  type: Actions.GET_SINGLE_PROVIDER_START,
});

export const getSingleProviderSuccess = (payload) => ({
  type: Actions.GET_SINGLE_PROVIDER_SUCCESS,
  payload,
});

export const getSingleProviderFailure = (payload) => ({
  type: Actions.GET_SINGLE_PROVIDER_FAILURE,
  payload,
});

export const addProvider = (type, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(addProviderStart());
  API.post(`sms/add-provider/${type}`, payload).then((response) => {
    const { data, ok } = response;
    responseHandler(response);
    if (ok) {
      dispatch(addProviderSuccess(data && data.data));
    } else {
      dispatch(addProviderFailure(data));
    }
  });
};

export const testProvider = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(testProviderStart());
  API.post("sms/test-send-sms", payload).then((response) => {
    const { data, ok } = response;
    responseHandler(response);
    if (ok) {
      dispatch(testProviderSuccess(data && data.data));
    } else {
      dispatch(testProviderFailure(data));
    }
  });
};

export const testProviderSmpp = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(testProviderSmppStart());
  API.post("sms/test-send-sms-smpp", payload).then((response) => {
    const { data, ok } = response;
    responseHandler(response);
    if (ok) {
      dispatch(testProviderSmppSuccess(data && data.data));
    } else {
      dispatch(testProviderSmppFailure(data));
    }
  });
};

export const editProvider = (type, id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(editProviderStart());
  API.post(`sms/update-provider/${type}/${id}`, payload).then((response) => {
    const { data, ok } = response;
    responseHandler(response);
    if (ok) {
      dispatch(editProviderSuccess(data && data.data));
    } else {
      dispatch(editProviderFailure(data));
    }
  });
};

export const deleteProvider = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(deleteProviderStart());
  API.delete(`sms/provider/${id}`).then((response) => {
    const { data, ok } = response;
    responseHandler(response);
    if (ok) {
      dispatch(deleteProviderSuccess(data && data.data));
    } else {
      dispatch(deleteProviderFailure(data));
    }
  });
};

export const getAllProviders = (page, limit) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(getAllProvidersStart());
  API.get(`sms/providers?page=${page}&limit=${limit}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getAllProvidersSuccess(data && data.data));
    } else {
      dispatch(getAllProvidersFailure(data));
      responseHandler(response);
    }
  });
};

export const searchProviders = (search, page, limit) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(searchProvidersStart());
  API.get(`sms/providers?search=${search}&page=${page}&limit=${limit}`).then(
    (response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(searchProvidersSuccess(data && data.data));
      } else {
        dispatch(searchProvidersFailure(data));
        responseHandler(response);
      }
    }
  );
};

export const filterProviders = (page, limit, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(filterProvidersStart());
  API.get(
    payload.search !== ""
      ? `sms/providers?page=${page}&limit=${limit}&startDate=${payload.startDate}&endDate=${payload.endDate}&country=${payload.country}&enabled=${payload.status}&search=${payload.search}`
      : `sms/providers?page=${page}&limit=${limit}&startDate=${payload.startDate}&endDate=${payload.endDate}&country=${payload.country}&enabled=${payload.status}`
  ).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(filterProvidersSuccess(data && data.data));
    } else {
      dispatch(filterProvidersFailure(data));
      responseHandler(response);
    }
  });
};

export const getSingleProvider =
  (id, type, state, setState) => async (dispatch) => {
    const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

    dispatch(getSingleProviderStart());
    API.get(`sms/providers/${id}?type=${type}`).then((response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(getSingleProviderSuccess(data && data.data));
        updateState(setState, state, data);
      } else {
        dispatch(getSingleProviderFailure(data));
        responseHandler(response);
      }
    });
  };
