import PropTypes from "prop-types";
import React from "react";
import Chart from 'chart.js/auto';
import { Line } from "react-chartjs-2";
import Dropdown from "react-dropdown";
import {
  assignSuccessCount,
  assignFailureCount,
  assignChartLabel,
} from "../../utils/functions/assignValues";
import { LoaderSpin } from "../Loader/Loader";

const category = [
  {
    label: "Daily",
    value: 1,
  },
  {
    label: "Weekly",
    value: 2,
  },
  {
    label: "Monthly",
    value: 3,
  },
  {
    label: "Yearly",
    value: 4,
  },
];

const LineCharts = (props) => {
  const { getGraph, isGetting, graph } = props;

  const datastate = {
    labels: assignChartLabel(graph),
    datasets: [
      {
        label: "Successful",
        data: assignSuccessCount(graph),
        fill: true,
        backgroundColor: "#F9FEFB",
        borderColor: "#27BE63",
      },
      {
        label: "Failed",
        data: assignFailureCount(graph),
        fill: false,
        borderColor: "#CA1B1B",
      },
    ],
  };

  const filterAction = (data) => {
    if (data.value === 1) {
      let duration = "daily";
      getGraph(duration);
    }
    if (data.value === 2) {
      let duration = "weekly";
      getGraph(duration);
    }
    if (data.value === 3) {
      let duration = "monthly";
      getGraph(duration);
    }
    if (data.value === 4) {
      let duration = "yearly";
      getGraph(duration);
    }
  };

  var today = new Date();
  let hours = today.getHours();
  let minutes = today.getMinutes();
  var suffix = hours >= 12 ? "PM" : "AM";
  const time = ((hours + 11) % 12) + 1 + ":" + minutes + suffix;

  var date =
    today.getDate() + "-" + (today.getMonth() + 1) + "-" + today.getFullYear();

  return (
    <section className="chart-wrap">
      <div className="chart-top">
        <div className="chart-top-left">
          <div className="chart-wrap2-title">Today's trend</div>
          <div className="lengend-box">
            <div className="lengend">
              <div className="dash"></div>
              <span>Successful</span>
            </div>
            <div className="lengend">
              <div className="dash dash2"></div>
              <span>Failed</span>
            </div>
          </div>
        </div>
        <div className="chart-top-right">
          <span className="chart-top-duration">Duration:</span>{" "}
          <Dropdown
            placeholder="Daily"
            options={category}
            onChange={filterAction}
          />
        </div>
      </div>
      <div className="chart-wrap2-title2">
        As of {date}, {time}
      </div>
      {isGetting ? (
        <LoaderSpin width="40px" height="40px" />
      ) : (
        <Line data={datastate} options={{ legend: false }} />
      )}
    </section>
  );
};

LineCharts.propTypes = {
  getGraph: PropTypes.func,
  graph: PropTypes.any,
  isGetting: PropTypes.bool,
};

export default LineCharts;
