import React from "react";
import AuthWrapper, { Headers } from "../../components/Wrappers/AuthWrapper";
import LoginForm from "./LoginForm";

export const Login = () => {
  return (
    <AuthWrapper>
      <Headers title1={"Login"} title2={"Enter details to proceed"} />
      <LoginForm />
    </AuthWrapper>
  );
};

export default Login;
