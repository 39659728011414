import PropTypes from "prop-types";
import React from "react";
import { Field } from "formik";
import ReactFlagsSelect from "react-flags-select";
import Input from "../../Input/Input";
import Label from "../../Input/Label";
import { AddMore } from "../AddProvider/Components";
import { Soap } from "../AddProvider/Soap";
import { Smpp } from "../AddProvider/Smpp";
import { Rest } from "../AddProvider/Rest";
import { ToggleButton } from "../../Input/ToggleButton";
import { countries } from "country-data";
import Button from "../../Button/Button";
import { ModalButton } from "../../Modal/Modal";

const EditProviderScreen1 = (props) => {
  const {
    state,
    setState,
    addNewRow,
    handleToggle,
    setStep2,
    isEditing,
    type,
    data,
  } = props;

  const setSelectedCountry = (code, index) => {
    const newSelectedCountries = state.selectedCountries.map((country, idx) => {
      if (index !== idx) return country;
      return { ...country, country_code: code };
    });
    setState({ ...state, selectedCountries: newSelectedCountries });
  };

  const setSelectedCurrency = (index) => (event) => {
    const newSelectedCountries = state.selectedCountries.map((country, idx) => {
      if (index !== idx) return country;
      return { ...country, country_currency: event.target.value };
    });
    setState({ ...state, selectedCountries: newSelectedCountries });
  };

  return (
    <div>
      {state.requestStep1 && type === "curl" && (
        <p className="steps">Step 1 of 3</p>
      )}

      <Field
        name="name"
        label="Name"
        placeholder="Provider Name"
        component={Input}
      />

      {state.selectedCountries &&
        state.selectedCountries.map((country, index) => (
          <div className="form-grid2" key={index}>
            <Label label={"Provider Country"} id={index}>
              <div className="add-provider-flags">
                <ReactFlagsSelect
                  selected={country.country_code}
                  onSelect={(code) => setSelectedCountry(code, index)}
                  searchable={true}
                  placeholder="Select Country"
                  optionsSize="16px"
                  id={`edit-provider-country${data && data.id}`}
                />
              </div>
            </Label>

            <Label label={"Price per SMS"} id={index}>
              <div className="country-currency">
                <div className="country-currency-code">
                  {countries &&
                    countries[country.country_code] &&
                    countries[country.country_code].currencies &&
                    countries[country.country_code].currencies[0]}
                </div>
                <div className="divider"></div>
                <div className="country-currency-value">
                  <input
                    type="number"
                    step="0.01"
                    onChange={setSelectedCurrency(index)}
                    value={country.country_currency}
                    placeholder="0.00"
                  />
                </div>
              </div>
            </Label>
          </div>
        ))}

      <AddMore onClick={addNewRow} />

      {type === "curl" && <Rest state={state} setState={setState} />}

      {type === "soap" && <Soap state={state} setState={setState} />}

      {type === "smpp" && <Smpp />}

      <ToggleButton
        text={`Toggle to ${
          state.checked ? "deactivate" : "activate"
        } sms provider`}
        handleChange={handleToggle}
        checked={state.checked}
        id={`edit-toggle${data && data.id}`}
      />

      {type !== "curl" && (
        <ModalButton
          text={"Save Changes"}
          marginBottom={"50px"}
          disabled={isEditing}
          loadingText={"Saving Changes..."}
          isLoading={isEditing}
        />
      )}

      {state.requestStep1 && type === "curl" && (
        <div style={{ textAlign: "right", marginBottom: "50px" }}>
          <Button
            width={"50%"}
            height={"48px"}
            testId={"button"}
            type={"button"}
            marginTop={"32px"}
            onClick={() => setStep2()}
          >
            Next
          </Button>
        </div>
      )}
    </div>
  );
};

EditProviderScreen1.propTypes = {
  addNewRow: PropTypes.any,
  data: PropTypes.shape({
    id: PropTypes.any,
  }),
  handleToggle: PropTypes.any,
  isEditing: PropTypes.bool,
  setState: PropTypes.func,
  setStep2: PropTypes.func,
  state: PropTypes.shape({
    checked: PropTypes.bool,
    requestStep1: PropTypes.bool,
    selectedCountries: PropTypes.shape({
      map: PropTypes.func,
    }),
  }),
  type: PropTypes.string,
};

export default EditProviderScreen1;
