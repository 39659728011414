import PropTypes from "prop-types";
import React from "react";
import Button from "../Button/Button";
import { LoaderSpin } from "../Loader/Loader";

export const ModalButton = ({
  onClick,
  text,
  disabled,
  marginBottom,
  isLoading,
  loadingText,
}) => {
  return (
    <div style={{ marginBottom: `${marginBottom}` }}>
      <Button
        width={"100%"}
        height={"48px"}
        testId={"button"}
        type={"submit"}
        marginTop={"32px"}
        onClick={onClick}
        disabled={disabled}
      >
        {isLoading ? (
          <div className="loader">
            <div className="loader-text">{loadingText}</div>
            <LoaderSpin width="25px" height="25px" />
          </div>
        ) : (
          text
        )}
      </Button>
    </div>
  );
};

ModalButton.propTypes = {
  disabled: PropTypes.any,
  isLoading: PropTypes.any,
  loadingText: PropTypes.any,
  marginBottom: PropTypes.any,
  onClick: PropTypes.any,
  text: PropTypes.any
}

const Modal = (props) => {
  const { title, width, children } = props;

  const divStyle = {
    display: props.displayModal ? "block" : "none",
  };

  const closeModal = () => {
    props.closeModal();
  };

  return (
    <div className="modal" style={divStyle}>
      <div style={{ width: `${width}` }} className="modal-content">
        {!props.show && (
          <div className="modal-content-header">
            <h1>{title}</h1>
            <span
              className="close-modal"
              onClick={closeModal}
              data-testid="close-modal"
            >
              &times;
            </span>
          </div>
        )}

        <div
          className={
            props.show ? "modal-content-container2" : "modal-content-container"
          }
        >
          <div className="modal-content-container-form">{children}</div>
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: PropTypes.any,
  closeModal: PropTypes.func,
  displayModal: PropTypes.any,
  show: PropTypes.any,
  title: PropTypes.any,
  width: PropTypes.any
}

export default Modal;
