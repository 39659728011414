import PropTypes from "prop-types";
import React from "react";
import ReactCountryFlag from "react-country-flag";
import { ToggleButton } from "../../components/Input/ToggleButton";
import moment from "moment";
import { countries } from "country-data";

const SingleDetails = (props) => {
  const { type, data, provider, keys_and_values, statuses } = props;

  return (
    <div className="single-provider-wrap">
      <div className="single-provider-wrap-details">
        <div className="single-provider-wrap-details-section">
          <p>Provider</p>
          <h4>{provider && provider.name}</h4>
        </div>
        <div className="single-provider-wrap-details-section">
          <p>Date</p>
          <h4>
            {moment(provider && provider.created_at).format("LL")} |{" "}
            <small>
              {moment(provider && provider.created_at).format("LT")}
            </small>
          </h4>
        </div>
        <div className="single-provider-wrap-details-section">
          <p>Request Type</p>
          <h4 style={{ textTransform: "uppercase" }}>
            {provider && provider.request_type}
          </h4>
        </div>
        <div className="single-provider-wrap-details-section">
          <p>Status</p>
          <h4>
            <ToggleButton
              style1={{ marginTop: "0px" }}
              style2={{ marginTop: "0px" }}
              checked={provider && provider.enabled}
              id={`single-switch${data.id}`}
            />
          </h4>
        </div>
      </div>

      <div className="single-provider-grid2">
        {provider &&
          provider.meta &&
          provider.meta.map((data, index) => (
            <div className="single-provider-wrap-details3" key={index}>
              <div className="single-provider-wrap-details3-section">
                <p>Country</p>
                <div className="country">
                  <ReactCountryFlag
                    countryCode={`${data.country_code}`}
                    style={{
                      width: "24px",
                      height: "24px",
                      borderRadius: "50%",
                    }}
                    svg
                    title={`${data.country_code}`}
                  />{" "}
                  <span style={{ marginLeft: "4px" }}>
                    {countries &&
                      countries[data.country_code] &&
                      countries[data.country_code].name}
                  </span>
                </div>
              </div>
              <div className="single-provider-wrap-details3-section">
                <p>Price</p>
                <h4>
                  {data.price}{" "}
                  {countries &&
                    countries[data.country_code] &&
                    countries[data.country_code].currencies &&
                    countries[data.country_code].currencies[0]}
                </h4>
              </div>
            </div>
          ))}
      </div>

      {type === "curl" && (
        <div className="form-grid2" style={{ marginTop: "0px" }}>
          <div className="single-provider-wrap-details4">
            <h2>Initial Response</h2>
            <div className="single-provider-wrap-details3">
              <div className="single-provider-wrap-details-section">
                <p>Status Path</p>
                <h3>{provider.initial_status_path || "Nil"}</h3>
              </div>
              <div className="single-provider-wrap-details-section">
                <p>Messsage ID Path</p>
                <h3>{provider.initial_message_id_path || "Nil"}</h3>
              </div>
            </div>
          </div>

          <div className="single-provider-wrap-details4">
            <h2>Final Response</h2>
            <div className="single-provider-wrap-details3">
              <div className="single-provider-wrap-details-section">
                <p>Status Path</p>
                <h3>{provider.final_status_path || "Nil"}</h3>
              </div>
              <div className="single-provider-wrap-details-section">
                <p>Messsage ID Path</p>
                <h3>{provider.final_message_id_path || "Nil"}</h3>
              </div>
            </div>
          </div>
        </div>
      )}

      {type === "smpp" && (
        <div className="form-grid">
          <div className="single-provider-wrap-request">
            <h1 className="single-provider-wrap-request-title">URL</h1>
            <div className="single-provider-wrap-request-body2">
              <pre>{(provider && provider.url) || "Nil"}</pre>
            </div>
          </div>
        </div>
      )}

      {type === "smpp" && (
        <div className="single-provider-wrap-request">
          <h1 className="single-provider-wrap-request-title">System ID</h1>
          <div className="single-provider-wrap-request-body2">
            <pre>{provider && provider.system_id}</pre>
          </div>
        </div>
      )}

      {type === "smpp" && (
        <div className="single-provider-wrap-request">
          <h1 className="single-provider-wrap-request-title">Password</h1>
          <div className="single-provider-wrap-request-body2">
            <pre>{provider && provider.password}</pre>
          </div>
        </div>
      )}

      {type === "curl" && (
        <div className="form-grid">
          <div className="single-provider-wrap-request">
            <h1 className="single-provider-wrap-request-title">Web Hook</h1>
            <div className="single-provider-wrap-request-body2">
              {provider && provider.webhook_enabled
                ? provider && provider.webhookUrl
                : "Nil"}
            </div>
          </div>
        </div>
      )}

      {type !== "smpp" && (
        <div className="single-provider-wrap-request">
          <h1 className="single-provider-wrap-request-title">
            {type && type.toUpperCase()} Request
          </h1>
          <div className="single-provider-wrap-request-body">
            <pre>{provider && provider.request}</pre>
          </div>
        </div>
      )}

      {type !== "smpp" && (
        <div className="single-provider-wrap-request">
          <h1 className="single-provider-wrap-request-title">
            Keys and Values
          </h1>
          <div className="single-provider-wrap-request-body">
            <pre>{keys_and_values}</pre>
          </div>
        </div>
      )}

      {type === "curl" && (
        <div className="single-provider-wrap-request">
          <h1 className="single-provider-wrap-request-title">
            Response Mapping
          </h1>
          <div className="single-provider-wrap-request-body3">
            {Object.entries(statuses).map((key, index) => (
              <div className="status-section" key={index}>
                <p className="status-section-header">{key[0]}</p>
                <div className="status-section-box">
                  {key[1] &&
                    key[1].map((item, index) => (
                      <div className="status-section-box-status" key={index}>
                        {item}
                      </div>
                    ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

SingleDetails.propTypes = {
  data: PropTypes.shape({
    country_code: PropTypes.any,
    id: PropTypes.any,
    price: PropTypes.any,
  }),
  keys_and_values: PropTypes.any,
  provider: PropTypes.shape({
    created_at: PropTypes.any,
    enabled: PropTypes.any,
    final_message_id_path: PropTypes.string,
    final_status_path: PropTypes.string,
    initial_message_id_path: PropTypes.string,
    initial_status_path: PropTypes.string,
    meta: PropTypes.shape({
      map: PropTypes.func,
    }),
    name: PropTypes.any,
    password: PropTypes.any,
    request: PropTypes.any,
    request_type: PropTypes.any,
    system_id: PropTypes.any,
    url: PropTypes.any,
    webhookUrl: PropTypes.any,
    webhook_enabled: PropTypes.any,
  }),
  statuses: PropTypes.any,
  type: PropTypes.string,
};

export default SingleDetails;
