import PropTypes from "prop-types";
import React from "react";

const RequestsModal = (props) => {
  const { title, width, children } = props;

  const divStyle = {
    display: props.displayModal ? "block" : "none",
  };

  const closeModal = () => {
    props.closeModal();
  };

  return (
    <div className="requests-modal" style={divStyle}>
      <div style={{ width: `${width}` }} className="requests-modal-content">
        <div className="requests-modal-content-header">
          <h1>{title}</h1>
          <span
            className="close-modal"
            onClick={closeModal}
            data-testid="close-modal"
          >
            &times;
          </span>
        </div>

        <div className="requests-modal-content-container">
          <div className="requests-modal-content-container-form">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

RequestsModal.propTypes = {
  children: PropTypes.any,
  closeModal: PropTypes.func,
  displayModal: PropTypes.any,
  title: PropTypes.any,
  width: PropTypes.any,
};

export default RequestsModal;
