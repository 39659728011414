import PropTypes from "prop-types";
import React from "react";
import SuccessIcon from "../../assets/success.svg";

export const Success = ({ maintext, belowtext, children }) => {
  return (
    <div className="invite-loader">
      <div className="invite-loader-image2">
        <img src={SuccessIcon} alt="success" />
      </div>

      <div className="invite-loader-status1">{maintext}</div>

      <div className="invite-loader-message">{belowtext}</div>

      <div className="invite-loader-button">{children}</div>
    </div>
  );
};

Success.propTypes = {
  belowtext: PropTypes.any,
  children: PropTypes.any,
  maintext: PropTypes.any
}
