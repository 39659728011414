import PropTypes from "prop-types";
import React from "react";
import { Loading } from "../../../components/Verification/Loading";
import { Success } from "../../../components/Verification/Success";
import { Failed } from "../../../components/Verification/Failed";
import Button from "../../Button/Button";

const Testing = (props) => {
  const { isTesting, testSuccess, goBack, text, disabled } = props;

  return (
    <div>
      {isTesting ? (
        <Loading text={"Loading"} />
      ) : testSuccess ? (
        <Success maintext={"Successful"} belowtext={"Test was successful"}>
          <Button
            width={"100%"}
            height={"48px"}
            testId={"button"}
            type={"submit"}
            marginTop={"40px"}
            disabled={disabled}
          >
            {text}
          </Button>
        </Success>
      ) : (
        <Failed
          maintext={"Not Successful"}
          belowtext={"Test was not successful, please check the configuration"}
        >
          <div className="invite-loader-resend" onClick={() => goBack()}>
            Back to configuration
          </div>
        </Failed>
      )}
    </div>
  );
};

Testing.propTypes = {
  disabled: PropTypes.any,
  goBack: PropTypes.func,
  isTesting: PropTypes.bool,
  testSuccess: PropTypes.bool,
  text: PropTypes.any,
};

export default Testing;
