import React from "react";
import moment from "moment";

export const loginColumns = [
  {
    Header: "User Name",
    accessor: "user_name",
  },
  {
    Header: "Email",
    accessor: "email",
    Cell: ({ row }) => <div className="table-email">{row.original.email}</div>,
  },

  {
    Header: "Login Date",
    accessor: "date",
    Cell: ({ row }) => {
      return (
        <div className="table-flex">
          <span className="table-flex-date">
            {moment(row.original.login_time).format("LL")} |{" "}
            <small>{moment(row.original.login_time).format("LT")}</small>
          </span>
        </div>
      );
    },
  },
];
