import Actions from "../Types";

const initialState = {
  isGettingReports: false,
  getReportsSuccess: false,
  reports: [],

  isGettingSingleReport: false,
  getSingleReportSuccess: false,
  report: {},

  isSearchingReport: false,
  searchReportSuccess: false,
  searchedReports: [],

  isFilteringReport: false,
  filterReportSuccess: false,
  filteredReports: [],

  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_ROUTER_REPORTS_START:
      return {
        ...state,
        isGettingReports: true,
        getReportsSuccess: false,
        reports: [],
        error: null,
      };
    case Actions.GET_ROUTER_REPORTS_SUCCESS:
      return {
        ...state,
        isGettingReports: false,
        getReportsSuccess: true,
        reports: action.payload && action.payload.data,
        reportsData: action.payload,
        error: null,
      };
    case Actions.GET_ROUTER_REPORTS_FAILURE:
      return {
        ...state,
        isGettingReports: false,
        getReportsSuccess: false,
        reports: [],
        error: action.payload,
      };
    case Actions.GET_SINGLE_ROUTER_REPORT_START:
      return {
        ...state,
        isGettingSingleReport: true,
        getSingleReportSuccess: false,
        report: {},
        error: null,
      };
    case Actions.GET_SINGLE_ROUTER_REPORT_SUCCESS:
      return {
        ...state,
        isGettingSingleReport: false,
        getSingleReportSuccess: true,
        report: action.payload,
        error: null,
      };
    case Actions.GET_SINGLE_ROUTER_REPORT_FAILURE:
      return {
        ...state,
        isGettingSingleReport: false,
        getSingleReportSuccess: false,
        report: {},
        error: action.payload,
      };
    case Actions.SEARCH_ROUTER_REPORTS_START:
      return {
        ...state,
        isSearchingReport: true,
        searchReportSuccess: false,
        searchedReports: [],
        error: null,
      };
    case Actions.SEARCH_ROUTER_REPORTS_SUCCESS:
      return {
        ...state,
        isSearchingReport: false,
        searchReportSuccess: true,
        searchedReports: action.payload && action.payload.data,
        searchedReportsData: action.payload,
        error: null,
      };
    case Actions.SEARCH_ROUTER_REPORTS_FAILURE:
      return {
        ...state,
        isSearchingReport: false,
        searchReportSuccess: false,
        searchedReports: [],
        error: action.payload,
      };
    case Actions.FILTER_ROUTER_REPORTS_START:
      return {
        ...state,
        isFilteringReport: true,
        filterReportSuccess: false,
        filteredReports: [],
        error: null,
      };
    case Actions.FILTER_ROUTER_REPORTS_SUCCESS:
      return {
        ...state,
        isFilteringReport: false,
        filterReportSuccess: true,
        filteredReports: action.payload && action.payload.data,
        filteredReportsData: action.payload,
        error: null,
      };
    case Actions.FILTER_ROUTER_REPORTS_FAILURE:
      return {
        ...state,
        isFilteringReport: false,
        filterReportSuccess: false,
        filteredReports: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reduxState;
