import PropTypes from "prop-types";
import React from "react";
import Empty from "../../assets/empty.svg";
import { LoaderSpin } from "../Loader/Loader";

export const TableRow = (props) => {
  const { row, testRowClick } = props;
  return (
    <tr {...row.getRowProps()} onClick={() => testRowClick(row.original)}>
      {row.cells.map((cell) => {
        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
      })}
    </tr>
  );
};

TableRow.propTypes = {
  row: PropTypes.shape({
    cells: PropTypes.shape({
      map: PropTypes.func,
    }),
    getRowProps: PropTypes.func,
    original: PropTypes.any,
  }),
  testRowClick: PropTypes.func,
};

export const TableRow2 = (props) => {
  const { row } = props;
  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
      })}
    </tr>
  );
};

TableRow2.propTypes = {
  row: PropTypes.shape({
    cells: PropTypes.shape({
      map: PropTypes.func,
    }),
    getRowProps: PropTypes.func,
  }),
};

export const Body = (props) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    emptyText1,
    emptyText2,
    className,
    testRowClick,
    isLoading,
    sortSuccess,
    success,
    emptyResultText,
  } = props;

  return (
    <table {...getTableProps()} className={className}>
      <thead>
        {headerGroups &&
          headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
      </thead>
      {isLoading ? (
        <div className="loader-table">
          <LoaderSpin width="40px" height="40px" />
        </div>
      ) : success && page && page.length > 0 ? (
        <tbody {...getTableBodyProps()}>
          {page &&
            page.map((row, i) => {
              prepareRow(row);
              return className === "table2" ? (
                <TableRow row={row} testRowClick={testRowClick} key={i} />
              ) : (
                <TableRow2 row={row} key={i} />
              );
            })}
        </tbody>
      ) : (
        <div className="table-empty">
          <div className="table-empty-container">
            <div className="table-empty-container-image">
              <img src={Empty} alt="empty-table" />
            </div>
            {sortSuccess ? (
              <div>
                <h2>{emptyResultText}</h2>
              </div>
            ) : (
              <div>
                <h2>{emptyText1}</h2>
                <h3>{emptyText2}</h3>
              </div>
            )}
          </div>
        </div>
      )}
    </table>
  );
};

Body.propTypes = {
  className: PropTypes.string,
  emptyResultText: PropTypes.any,
  emptyText1: PropTypes.any,
  emptyText2: PropTypes.any,
  getTableBodyProps: PropTypes.func,
  getTableProps: PropTypes.func,
  headerGroups: PropTypes.shape({
    map: PropTypes.func,
  }),
  isLoading: PropTypes.any,
  page: PropTypes.shape({
    length: PropTypes.number,
    map: PropTypes.func,
  }),
  prepareRow: PropTypes.func,
  sortSuccess: PropTypes.any,
  success: PropTypes.any,
  testRowClick: PropTypes.any,
};
