import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { ModalButton } from "../Modal/Modal";
import SelectInput from "../Input/Select";
import Input from "../Input/Input";
import { ToggleButton } from "../Input/ToggleButton";
import { Field, Form, Formik } from "formik";
import { updateUserInfo } from "../../redux/Actions/AdminActions";

const EditInfoModal = (props) => {
  const { data, isUpdatingInfo, updateInfoSuccess, updateUserInfo } = props;
  const [state, setState] = useState({
    checked: data.enabled,
    selected: data.role_id,
  });

  const category = [
    { value: "1", label: "Maker" },
    { value: "2", label: "Checker" },
    { value: "3", label: "Viewer" },
  ];

  const closeModal = () => {
    props.closeModal();
  };

  useEffect(
    () => {
      if (updateInfoSuccess) {
        closeModal();
      }
    }, // eslint-disable-next-line
    [updateInfoSuccess]
  );

  const userId = data && data.id;

  const handleToggle = () => {
    setState({
      ...state,
      checked: (state.checked = !state.checked),
    });
  };

  const initialValues = {
    name: data.name,
    email: data.email,
  };

  const handleChangeRole = (data) => {
    setState({
      ...state,
      selected: data.value,
    });
  };

  const handleUpdateInfo = () => {
    const updatevalues = {
      role_id: state.selected,
      enabled: state.checked,
    };
    updateUserInfo(userId, updatevalues);
  };

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleUpdateInfo}>
        {({ values, isValid, dirty }) => {
          return (
            <Form>
              <Field
                name="name"
                label="Name"
                placeholder="Enter Name"
                component={Input}
                readOnly
              />
              <div className="edit-email">
                <Field
                  name="email"
                  label="Email"
                  placeholder="Email"
                  component={Input}
                  readOnly
                />
              </div>

              <div className="role-select">
                <SelectInput
                  label={"Role"}
                  options={category}
                  defaultOption={data.role_id}
                  onChange={(data) => handleChangeRole(data)}
                />
              </div>
              <ToggleButton
                text={
                  state.checked
                    ? "Toggle to deactivate user"
                    : "Toggle to activate user"
                }
                handleChange={handleToggle}
                checked={state.checked}
                id={"edit-info-toggle"}
              />
              <ModalButton
                text={"Save Changes"}
                loadingText={"Saving..."}
                isLoading={isUpdatingInfo}
                disabled={isUpdatingInfo}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

EditInfoModal.propTypes = {
  closeModal: PropTypes.func,
  data: PropTypes.shape({
    email: PropTypes.any,
    enabled: PropTypes.any,
    id: PropTypes.any,
    name: PropTypes.any,
    role_id: PropTypes.any,
    value: PropTypes.any,
  }),
  isUpdatingInfo: PropTypes.bool,
  updateInfoSuccess: PropTypes.bool,
  updateUserInfo: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  updateUserInfo: (id, payload) => dispatch(updateUserInfo(id, payload)),
});

const mapStateToProps = (state) => ({
  isUpdatingInfo: state.admin.isUpdatingInfo,
  updateInfoSuccess: state.admin.updateInfoSuccess,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditInfoModal);
