import PropTypes from "prop-types";
import React from "react";
import Profile from "../../assets/user.svg";
import LogOutIcon from "../../assets/logout.svg";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import { userLogout } from "../../redux/Actions/AuthActions";

export const Dropdown = (props) => {
  const history = useHistory();

  const { closeDropdown, logOutUser } = props;

  const routeUser = (type) => {
    if (type === "logout") {
      logOutUser();
    } else {
      closeDropdown();
      history.push("/profile");
    }
  };

  return (
    <div className="dropdown">
      <div
        className="dropdown-item"
        data-testid="routeUserProfile"
        onClick={() => routeUser("profile")}
      >
        <div className="dropdown-item-image">
          <img src={Profile} alt="profile-icon" />
        </div>
        <div className="dropdown-item-text">Profile</div>
      </div>

      <div
        className="dropdown-item"
        data-testid="routeUserLogout"
        onClick={() => routeUser("logout")}
      >
        <div className="dropdown-item-image">
          <img src={LogOutIcon} alt="logout-icon" />
        </div>
        <div className="dropdown-item-text">Log Out</div>
      </div>
    </div>
  );
};

Dropdown.propTypes = {
  closeDropdown: PropTypes.func,
  logOutUser: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  logOutUser: () => dispatch(userLogout()),
});

export default connect(null, mapDispatchToProps)(Dropdown);
