import React from "react";
import { Link, NavLink } from "react-router-dom";
import HomeIcon from "../../assets/home.svg";
import Provider from "../../assets/providers.svg";
import Products from "../../assets/products.svg";
import Reporting from "../../assets/reporting.svg";
import Admin from "../../assets/admin.svg";
import Audit from "../../assets/audit.svg";
import Logo from "../../assets/brand-logo.svg";
import { getDecodedData } from "../../utils/functions/getStorageItems";
import { formatText } from "../../utils/functions/formatText";

export const Sidebar = () => {
  const { type } = getDecodedData();

  const userRole = formatText(type);
  const role = userRole && userRole.toLowerCase();

  return (
    <div className="sidebar">
      <div className="sidebar-logo">
        <Link to="/dashboard">
          <div className="sidebar-logo-section">
            <img src={Logo} alt="logo" />
          </div>
        </Link>
      </div>
      <div className="side-links">
        <NavLink to="/dashboard" activeClassName="grey">
          <div className="sidebar-item">
            <div className="sidebar-item-icon">
              <img src={HomeIcon} alt="home-icon" />
            </div>
            <div>
              <span>Dashboard</span>
            </div>
          </div>
        </NavLink>
        {role !== "checker" && (
          <NavLink to="/providers" activeClassName="grey">
            <div className="sidebar-item">
              <div className="sidebar-item-icon">
                <img src={Provider} alt="provider-icon" />
              </div>
              <div>
                <span>SMS Providers</span>
              </div>
            </div>
          </NavLink>
        )}
        {role !== "checker" && (
          <NavLink to="/products" activeClassName="grey">
            <div className="sidebar-item">
              <div className="sidebar-item-icon">
                <img src={Products} alt="product-icon" />
              </div>
              <div>
                <span>Products</span>
              </div>
            </div>
          </NavLink>
        )}
        {role !== "checker" && (
          <NavLink to="/reporting" activeClassName="grey">
            <div className="sidebar-item">
              <div className="sidebar-item-icon">
                <img src={Reporting} alt="report-icon" />
              </div>
              <div>
                <span>Reporting</span>
              </div>
            </div>
          </NavLink>
        )}
        {role !== "checker" && (
          <NavLink to="/router_reporting" activeClassName="grey">
            <div className="sidebar-item">
              <div className="sidebar-item-icon">
                <img src={Reporting} alt="report-icon" />
              </div>
              <div>
                <span>Router Reporting</span>
              </div>
            </div>
          </NavLink>
        )}
        {role !== "checker" && (
          <NavLink to="/admin" activeClassName="grey">
            <div className="sidebar-item">
              <div className="sidebar-item-icon">
                <img src={Admin} alt="admin-icon" />
              </div>
              <div>
                <span>Admin Management</span>
              </div>
            </div>
          </NavLink>
        )}
        {role !== "checker" && (
          <NavLink to="/trails" activeClassName="grey">
            <div className="sidebar-item">
              <div className="sidebar-item-icon">
                <img src={Audit} alt="audit-icon" />
              </div>
              <div>
                <span>Trail Logs</span>
              </div>
            </div>
          </NavLink>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
