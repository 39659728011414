import PropTypes from "prop-types";
import React from "react";
import FailedIcon from "../../assets/failure.svg";

export const Failed = ({ maintext, belowtext, children }) => {
  return (
    <div className="invite-loader">
      <div className="invite-loader-image2">
        <img src={FailedIcon} alt="failed" />
      </div>

      <div className="invite-loader-status2">{maintext}</div>

      <div className="invite-loader-message">{belowtext}</div>

      <div>{children}</div>
    </div>
  );
};

Failed.propTypes = {
  belowtext: PropTypes.any,
  children: PropTypes.any,
  maintext: PropTypes.any,
};
