const Actions = {
  SET_CURRENT_USER: "SET_CURRENT_USER",
  USER_LOGIN_START: "USER_LOGIN_START",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILURE: "USER_LOGIN_FAILURE",
  USER_LOG_OUT_START: "USER_LOG_OUT_START",
  USER_LOG_OUT_SUCCESS: "USER_LOG_OUT_SUCCESS",
  USER_LOG_OUT_FAILURE: "USER_LOG_OUT_FAILURE",
  ADD_PROVIDER_START: "ADD_PROVIDER_START",
  ADD_PROVIDER_SUCCESS: "ADD_PROVIDER_SUCCESS",
  ADD_PROVIDER_FAILURE: "ADD_PROVIDER_FAILURE",
  TEST_PROVIDER_START: "TEST_PROVIDER_START",
  TEST_PROVIDER_SUCCESS: "TEST_PROVIDER_SUCCESS",
  TEST_PROVIDER_FAILURE: "TEST_PROVIDER_FAILURE",
  TEST_PROVIDER_SMPP_START: "TEST_PROVIDER_SMPP_START",
  TEST_PROVIDER_SMPP_SUCCESS: "TEST_PROVIDER_SMPP_SUCCESS",
  TEST_PROVIDER_SMPP_FAILURE: "TEST_PROVIDER_SMPP_FAILURE",
  EDIT_PROVIDER_START: "EDIT_PROVIDER_START",
  EDIT_PROVIDER_SUCCESS: "EDIT_PROVIDER_SUCCESS",
  EDIT_PROVIDER_FAILURE: "EDIT_PROVIDER_FAILURE",
  DELETE_PROVIDER_START: "DELETE_PROVIDER_START",
  DELETE_PROVIDER_SUCCESS: "DELETE_PROVIDER_SUCCESS",
  DELETE_PROVIDER_FAILURE: "DELETE_PROVIDER_FAILURE",
  GET_ALL_PROVIDERS_START: "GET_ALL_PROVIDERS_START",
  GET_ALL_PROVIDERS_SUCCESS: "GET_ALL_PROVIDERS_SUCCESS",
  GET_ALL_PROVIDERS_FAILURE: "GET_ALL_PROVIDERS_FAILURE",
  SEARCH_PROVIDERS_START: "SEARCH_PROVIDERS_START",
  SEARCH_PROVIDERS_SUCCESS: "SEARCH_PROVIDERS_SUCCESS",
  SEARCH_PROVIDERS_FAILURE: "SEARCH_PROVIDERS_FAILURE",
  FILTER_PROVIDERS_START: "FILTER_PROVIDERS_START",
  FILTER_PROVIDERS_SUCCESS: "FILTER_PROVIDERS_SUCCESS",
  FILTER_PROVIDERS_FAILURE: "FILTER_PROVIDERS_FAILURE",
  GET_SINGLE_PROVIDER_START: "GET_SINGLE_PROVIDER_START",
  GET_SINGLE_PROVIDER_SUCCESS: "GET_SINGLE_PROVIDER_SUCCESS",
  GET_SINGLE_PROVIDER_FAILURE: "GET_SINGLE_PROVIDER_FAILURE",
  GET_ALL_RANKS_START: "GET_ALL_RANKS_START",
  GET_ALL_RANKS_SUCCESS: "GET_ALL_RANKS_SUCCESS",
  GET_ALL_RANKS_FAILURE: "GET_ALL_RANKS_FAILURE",
  SEARCH_RANKS_START: "SEARCH_RANKS_START",
  SEARCH_RANKS_SUCCESS: "SEARCH_RANKS_SUCCESS",
  SEARCH_RANKS_FAILURE: "SEARCH_RANKS_FAILURE",
  GET_RANKS_LIST_START: "GET_RANKS_LIST_START",
  GET_RANKS_LIST_SUCCESS: "GET_RANKS_LIST_SUCCESS",
  GET_RANKS_LIST_FAILURE: "GET_RANKS_LIST_FAILURE",
  EDIT_RANKS_START: "EDIT_RANKS_START",
  EDIT_RANKS_SUCCESS: "EDIT_RANKS_SUCCESS",
  EDIT_RANKS_FAILURE: "EDIT_RANKS_FAILURE",
  VERIFY_INVITE_START: "VERIFY_INVITE_START",
  VERIFY_INVITE_SUCCESS: "VERIFY_INVITE_SUCCESS",
  VERIFY_INVITE_FAILURE: "VERIFY_INVITE_FAILURE",
  RESEND_USER_INVITE_START: "RESEND_USER_INVITE_START",
  RESEND_USER_INVITE_SUCCESS: "RESEND_USER_INVITE_SUCCESS",
  RESEND_USER_INVITE_FAILURE: "RESEND_USER_INVITE_FAILURE",
  GET_ADMIN_ROLES_START: "GET_ADMIN_ROLES_START",
  GET_ADMIN_ROLES_SUCCESS: "GET_ADMIN_ROLES_SUCCESS",
  GET_ADMIN_ROLES_FAILURE: "GET_ADMIN_ROLES_FAILURE",
  SEARCH_USER_START: "SEARCH_USER_START",
  SEARCH_USER_SUCCESS: "SEARCH_USER_SUCCESS",
  SEARCH_USER_FAILURE: "SEARCH_USER_FAILURE",
  SEND_INVITE_START: "SEND_INVITE_START",
  SEND_INVITE_SUCCESS: "SEND_INVITE_SUCCESS",
  SEND_INVITE_FAILURE: "SEND_INVITE_FAILURE",
  ADD_PRODUCT_START: "ADD_PRODUCT_START",
  ADD_PRODUCT_SUCCESS: "ADD_PRODUCT_SUCCESS",
  ADD_PRODUCT_FAILURE: "ADD_PRODUCT_FAILURE",
  GET_USERS_START: "GET_USERS_START",
  GET_USERS_SUCCESS: "GET_USERS_SUCCESS",
  GET_USERS_FAILURE: "GET_USERS_FAILURE",
  GET_SINGLE_PRODUCT_START: "GET_SINGLE_PRODUCT_START",
  GET_SINGLE_PRODUCT_SUCCESS: "GET_SINGLE_PRODUCT_SUCCESS",
  GET_SINGLE_PRODUCT_FAILURE: "GET_SINGLE_PRODUCT_FAILURE",
  EDIT_PRODUCT_START: "EDIT_PRODUCT_START",
  EDIT_PRODUCT_SUCCESS: "EDIT_PRODUCT_SUCCESS",
  EDIT_PRODUCT_FAILURE: "EDIT_PRODUCT_FAILURE",
  RESET_PRODUCT_KEY_START: "RESET_PRODUCT_KEY_START",
  RESET_PRODUCT_KEY_SUCCESS: "RESET_PRODUCT_KEY_SUCCESS",
  RESET_PRODUCT_KEY_FAILURE: "RESET_PRODUCT_KEY_FAILURE",
  DELETE_PRODUCT_START: "DELETE_PRODUCT_START",
  DELETE_PRODUCT_SUCCESS: "DELETE_PRODUCT_SUCCESS",
  DELETE_PRODUCT_FAILURE: "DELETE_PRODUCT_FAILURE",
  FETCH_PRODUCTS_START: "FETCH_PRODUCTS_START",
  FETCH_PRODUCTS_SUCCESS: "FETCH_PRODUCTS_SUCCESS",
  FETCH_PRODUCTS_FAILURE: "FETCH_PRODUCTS_FAILURE",
  SEARCH_PRODUCTS_START: "SEARCH_PRODUCTS_START",
  SEARCH_PRODUCTS_SUCCESS: "SEARCH_PRODUCTS_SUCCESS",
  SEARCH_PRODUCTS_FAILURE: "SEARCH_PRODUCTS_FAILURE",
  GET_REQUESTS_START: "GET_REQUESTS_START",
  GET_REQUESTS_SUCCESS: "GET_REQUESTS_SUCCESS",
  GET_REQUESTS_FAILURE: "GET_REQUESTS_FAILURE",
  AUTHORISE_REQUEST_START: "AUTHORISE_REQUEST_START",
  AUTHORISE_REQUEST_SUCCESS: "AUTHORISE_REQUEST_SUCCESS",
  AUTHORISE_REQUEST_FAILURE: "AUTHORISE_REQUEST_FAILURE",
  UPDATE_USER_INFO_START: "UPDATE_USER_INFO_START",
  UPDATE_USER_INFO_SUCCESS: "UPDATE_USER_INFO_SUCCESS",
  UPDATE_USER_INFO_FAILURE: "UPDATE_USER_INFO_FAILURE",
  DELETE_USER_INFO_START: "DELETE_USER_INFO_START",
  DELETE_USER_INFO_SUCCESS: "DELETE_USER_INFO_SUCCESS",
  DELETE_USER_INFO_FAILURE: "DELETE_USER_INFO_FAILURE",
  AUTHENTICATION_START: "AUTHENTICATION_START",
  AUTHENTICATION_SUCCESS: "AUTHENTICATION_SUCCESS",
  AUTHENTICATION_FAILURE: "AUTHENTICATION_FAILURE",
  RESEND_AUTHENTICATION_START: "RESEND_AUTHENTICATION_START",
  RESEND_AUTHENTICATION_SUCCESS: "RESEND_AUTHENTICATION_SUCCESS",
  RESEND_AUTHENTICATION_FAILURE: "RESEND_AUTHENTICATION_FAILURE",
  CLEAR_AUTHENTICATION_STATE: "CLEAR_AUTHENTICATION_STATE",
  GET_LOGIN_TRAILS_START: "GET_LOGIN_TRAILS_START",
  GET_LOGIN_TRAILS_SUCCESS: "GET_LOGIN_TRAILS_SUCCESS",
  GET_LOGIN_TRAILS_FAILURE: "GET_LOGIN_TRAILS_FAILURE",
  GET_AUDIT_TRAILS_START: "GET_AUDIT_TRAILS_START",
  GET_AUDIT_TRAILS_SUCCESS: "GET_AUDIT_TRAILS_SUCCESS",
  GET_AUDIT_TRAILS_FAILURE: "GET_AUDIT_TRAILS_FAILURE",
  SEARCH_AUDIT_TRAILS_START: "SEARCH_AUDIT_TRAILS_START",
  SEARCH_AUDIT_TRAILS_SUCCESS: "SEARCH_AUDIT_TRAILS_SUCCESS",
  SEARCH_AUDIT_TRAILS_FAILURE: "SEARCH_AUDIT_TRAILS_FAILURE",
  SEARCH_LOGIN_TRAILS_START: "SEARCH_LOGIN_TRAILS_START",
  SEARCH_LOGIN_TRAILS_SUCCESS: "SEARCH_LOGIN_TRAILS_SUCCESS",
  SEARCH_LOGIN_TRAILS_FAILURE: "SEARCH_LOGIN_TRAILS_FAILURE",
  SEARCH_ADMIN_USERS_START: "SEARCH_ADMIN_USERS_START",
  SEARCH_ADMIN_USERS_SUCCESS: "SEARCH_ADMIN_USERS_SUCCESS",
  SEARCH_ADMIN_USERS_FAILURE: "SEARCH_ADMIN_USERS_FAILURE",
  GET_REPORTS_START: "GET_REPORTS_START",
  GET_REPORTS_SUCCESS: "GET_REPORTS_SUCCESS",
  GET_REPORTS_FAILURE: "GET_REPORTS_FAILURE",
  SEARCH_REPORTS_START: "SEARCH_REPORTS_START",
  SEARCH_REPORTS_SUCCESS: "SEARCH_REPORTS_SUCCESS",
  SEARCH_REPORTS_FAILURE: "SEARCH_REPORTS_FAILURE",
  FILTER_REPORTS_START: "FILTER_REPORTS_START",
  FILTER_REPORTS_SUCCESS: "FILTER_REPORTS_SUCCESS",
  FILTER_REPORTS_FAILURE: "FILTER_REPORTS_FAILURE",
  GET_SINGLE_REPORT_START: "GET_SINGLE_REPORT_START",
  GET_SINGLE_REPORT_SUCCESS: "GET_SINGLE_REPORT_SUCCESS",
  GET_SINGLE_REPORT_FAILURE: "GET_SINGLE_REPORT_FAILURE",
  GET_ROUTER_REPORTS_START: "GET_ROUTER_REPORTS_START",
  GET_ROUTER_REPORTS_SUCCESS: "GET_ROUTER_REPORTS_SUCCESS",
  GET_ROUTER_REPORTS_FAILURE: "GET_ROUTER_REPORTS_FAILURE",
  SEARCH_ROUTER_REPORTS_START: "SEARCH_ROUTER_REPORTS_START",
  SEARCH_ROUTER_REPORTS_SUCCESS: "SEARCH_ROUTER_REPORTS_SUCCESS",
  SEARCH_ROUTER_REPORTS_FAILURE: "SEARCH_ROUTER_REPORTS_FAILURE",
  FILTER_ROUTER_REPORTS_START: "FILTER_ROUTER_REPORTS_START",
  FILTER_ROUTER_REPORTS_SUCCESS: "FILTER_ROUTER_REPORTS_SUCCESS",
  FILTER_ROUTER_REPORTS_FAILURE: "FILTER_ROUTER_REPORTS_FAILURE",
  GET_SINGLE_ROUTER_REPORT_START: "GET_SINGLE_REPORT_START",
  GET_SINGLE_ROUTER_REPORT_SUCCESS: "GET_SINGLE_REPORT_SUCCESS",
  GET_SINGLE_ROUTER_REPORT_FAILURE: "GET_SINGLE_REPORT_FAILURE",
  GET_DASHBOARD_METRICS_START: "GET_DASHBOARD_METRICS_START",
  GET_DASHBOARD_METRICS_SUCCESS: "GET_DASHBOARD_METRICS_SUCCESS",
  GET_DASHBOARD_METRICS_FAILURE: "GET_DASHBOARD_METRICS_FAILURE",
  GET_GRAPH_METRICS_START: "GET_GRAPH_METRICS_START",
  GET_GRAPH_METRICS_SUCCESS: "GET_GRAPH_METRICS_SUCCESS",
  GET_GRAPH_METRICS_FAILURE: "GET_GRAPH_METRICS_FAILURE",
};

export default Actions;
