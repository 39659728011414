import PropTypes from "prop-types";
import React from "react";
import FilterIcon from "../../assets/filter.svg";
import OptionIcon from "../../assets/options.svg";

export const Options = (props) => {
  const { onClick } = props;
  return (
    <div className="options-div" onClick={onClick}>
      <div className="options-div-img">
        <img src={OptionIcon} alt="options-icon" />
      </div>
    </div>
  );
};

Options.propTypes = {
  onClick: PropTypes.any,
};

const Filter = (props) => {
  const { onClick, marginLeft, marginRight } = props;
  return (
    <div
      className="filter-div"
      onClick={onClick}
      style={{ marginLeft: `${marginLeft}`, marginRight: `${marginRight}` }}
    >
      <span>Filter</span>
      <div className="filter-div-img">
        <img src={FilterIcon} alt="filter" />
      </div>
    </div>
  );
};

Filter.propTypes = {
  marginLeft: PropTypes.any,
  marginRight: PropTypes.any,
  onClick: PropTypes.any,
};

export default Filter;
