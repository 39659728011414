import PropTypes from "prop-types";
import React from "react";
import { loginColumns } from "../../components/TableData/Audit/Login";
import Table from "../../components/Tables/Table";
import { showSingleDetails } from "../Admin/Users";

export const LoginTrail = (props) => {
  const {
    loginTrailsData,
    loginTrails,
    isFetchingLogs,
    getLoginTrailsSuccess,
    state,
    searchLogin,
    searchState,
    searchSuccess,
    isSearching,
    searchedLogin,
    searchedLoginData,
    getLoginTrails,
  } = props;

  const moreData = (page, perPage) => {
    state.showSearchLoginResults
      ? searchLogin(page, perPage, searchState.searchValue)
      : getLoginTrails(page, perPage);
  };

  const allLoginTrails = state.showSearchLoginResults
    ? searchedLoginData
    : loginTrailsData;

  const totalLoginTrails = state.showSearchLoginResults
    ? searchedLogin && searchedLogin.total
    : loginTrails && loginTrails.total;

  return (
    <div>
      <Table
        columns={loginColumns}
        data={allLoginTrails}
        getData={getLoginTrails}
        emptyText1={"No available logs"}
        showData={showSingleDetails}
        getMoreData={(page, perPage) => moreData(page, perPage)}
        isLoading={isFetchingLogs || isSearching}
        success={getLoginTrailsSuccess || searchSuccess}
        emptyResultText={"Login trail not found!"}
        sortSuccess={searchSuccess}
        total={totalLoginTrails}
      />
    </div>
  );
};

LoginTrail.propTypes = {
  getLoginTrails: PropTypes.func,
  getLoginTrailsSuccess: PropTypes.any,
  isFetchingLogs: PropTypes.any,
  isSearching: PropTypes.any,
  loginTrails: PropTypes.shape({
    total: PropTypes.any,
  }),
  loginTrailsData: PropTypes.any,
  searchLogin: PropTypes.func,
  searchState: PropTypes.shape({
    searchValue: PropTypes.any,
  }),
  searchSuccess: PropTypes.any,
  searchedLogin: PropTypes.shape({
    total: PropTypes.any,
  }),
  searchedLoginData: PropTypes.any,
  state: PropTypes.shape({
    showSearchLoginResults: PropTypes.any,
  }),
};

export default LoginTrail;
