import PropTypes from "prop-types";
import React from "react";
import Button from "../../Button/Button";
import { MultiTags } from "./Components";

const ProviderScreen3 = (props) => {
  const { state, setStep2, handleChange, setStep4 } = props;

  const disabled =
    (state.delivered && state.delivered.length === 0) ||
    (state.success && state.success.length === 0) ||
    (state.undelivered && state.undelivered.length === 0) ||
    (state.pending && state.pending.length === 0) ||
    (state.failed && state.failed.length === 0);

  return (
    <div>
      {state.requestStep3 && (
        <p className="steps">
          {state.request3 ? "Step 2 of 3" : "Step 3 of 4"}
        </p>
      )}

      <h2 className="add-provider-header">Status Mapping</h2>
      <p className="add-provider-header-small">
        (Click enter to register a value)
      </p>

      <MultiTags
        label={"Delivered"}
        info={"sms sent and delivered"}
        tags={state.delivered}
        handleChange={(tags) => handleChange(tags, "delivered")}
        top={"25px"}
        placeholder={
          state.delivered && state.delivered.length > 0 ? "" : "e.g delivered"
        }
        testid={"content-input1"}
      />

      <MultiTags
        label={"Success"}
        info={"sms sent but not yet delivered"}
        tags={state.success}
        handleChange={(tags) => handleChange(tags, "success")}
        top={"24px"}
        placeholder={
          state.success && state.success.length > 0 ? "" : "e.g success"
        }
        testid={"content-input2"}
      />

      <MultiTags
        label={"Undelivered"}
        info={"sms didn’t delivered due to issues from Telco etc."}
        tags={state.undelivered}
        handleChange={(tags) => handleChange(tags, "undelivered")}
        top={"24px"}
        placeholder={
          state.undelivered && state.undelivered.length > 0
            ? ""
            : "e.g undelivered"
        }
        testid={"content-input3"}
      />

      <MultiTags
        label={"Pending"}
        info={"sms queued by the provider"}
        tags={state.pending}
        handleChange={(tags) => handleChange(tags, "pending")}
        top={"24px"}
        placeholder={
          state.pending && state.pending.length > 0 ? "" : "e.g pending"
        }
        testid={"content-input4"}
      />

      <MultiTags
        label={"Failed"}
        info={"sms wasn’t sent due to issues e.g no credit etc."}
        tags={state.failed}
        handleChange={(tags) => handleChange(tags, "failed")}
        top={"24px"}
        placeholder={
          state.failed && state.failed.length > 0 ? "" : "e.g failed"
        }
        testid={"content-input5"}
      />

      <div className="provider-btn-flex">
        <Button
          width={"47%"}
          height={"48px"}
          testId={"button"}
          type={"button"}
          marginTop={"32px"}
          className={"button2"}
          onClick={() => setStep2()}
        >
          Previous
        </Button>
        <Button
          width={"47%"}
          height={"48px"}
          testId={"button"}
          type={"button"}
          marginTop={"32px"}
          onClick={() => setStep4()}
          disabled={disabled}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

ProviderScreen3.propTypes = {
  handleChange: PropTypes.func,
  setStep2: PropTypes.func,
  setStep4: PropTypes.func,
  state: PropTypes.shape({
    delivered: PropTypes.shape({
      length: PropTypes.number,
    }),
    failed: PropTypes.shape({
      length: PropTypes.number,
    }),
    pending: PropTypes.shape({
      length: PropTypes.number,
    }),
    requestStep3: PropTypes.any,
    success: PropTypes.shape({
      length: PropTypes.number,
    }),
    undelivered: PropTypes.shape({
      length: PropTypes.number,
    }),
  }),
};

export default ProviderScreen3;
