import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "../../components/Button/Button";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import Add from "../../assets/add.svg";
import SwitchWrapper from "../../components/Wrappers/SwitchWrapper";
import Users from "./Users";
import Roles from "./Roles";
import Search from "../../components/Sorting/Search";
import Modal from "../../components/Modal/Modal";
import SendInvite from "../../components/Admin/SendInvite";
import { cancelModal, viewModal } from "../SMS Providers/SMSProviders";
import {
  getAdminRoles,
  getUsers,
  searchAdminUsers,
  searchUser,
  sendInvite,
} from "../../redux/Actions/AdminActions";
import { assignRoleData } from "../../utils/functions/assignValues";

export const AdminButton = (props) => (
  <Button
    width={"auto"}
    onClick={props.onClick}
    disabled={""}
    height={"48px"}
    testId={"button"}
    type={"submit"}
  >
    <div className="button-data">
      <img src={Add} alt="add-icon" />
      <span>{props.text}</span>
    </div>
  </Button>
);

export const Admin = (props) => {
  const {
    getRoles,
    roles,
    searchUserEmail,
    sendUserInvite,
    searchedUser,
    searchUserSuccess,
    getUserData,
    users,
    usersData,
    isGettingUsers,
    getUsersSuccess,
    isSending,
    isSearching,
    sendInviteSuccess,
    isGetting,
    getRolesSuccess,
    searchAdminUsers,
    isSearchingAdmin,
    searchedAdminUsers,
    searchAdminUsersSuccess,
  } = props;

  const [state, setState] = useState({
    active1: true,
    active2: false,
    inviteModal: false,
    searchValue: "",
    showSearchResults: false,
  });

  let newAdminRoles = [];
  const category =
    roles && roles.length > 0 ? assignRoleData(roles, newAdminRoles) : [];

  useEffect(
    () => {
      setState({
        ...state,
        showSearchResults: false,
      });
      getRoles();
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (searchAdminUsersSuccess) {
        setState({
          ...state,
          showSearchResults: true,
        });
      }
    },
    // eslint-disable-next-line
    [searchAdminUsersSuccess]
  );

  const searchItems = () => {
    if (state.searchValue === "") {
      setState({
        ...state,
        showSearchResults: false,
      });
      return getUserData;
    } else {
      searchAdminUsers(state.searchValue, 1, 10);
    }
  };

  const openModal = (type) => viewModal(type, state, setState);

  const closeModal = (type) => cancelModal(type, state, setState);

  return (
    <div>
      <HeaderWrapper title={"Admin Management"}>
        {state.active1 ? (
          <AdminButton
            onClick={() => openModal("inviteModal")}
            text={"Send Invite"}
          />
        ) : (
          ""
        )}
      </HeaderWrapper>

      <SwitchWrapper
        title1={"Admin Users"}
        title2={"Roles"}
        state={state}
        setstate={setState}
        component1={
          <Users
            users={users}
            usersData={usersData}
            getUserData={getUserData}
            isGettingUsers={isGettingUsers}
            getUsersSuccess={getUsersSuccess}
            state={state}
            searchSuccess={searchAdminUsersSuccess}
            isSearchingAdmin={isSearchingAdmin}
            searchedAdminUsers={searchedAdminUsers}
            searchAdminUsers={searchAdminUsers}
          />
        }
        component2={
          <Roles
            roles={roles}
            getRoles={getRoles}
            isGetting={isGetting}
            getRolesSuccess={getRolesSuccess}
          />
        }
        right1={
          <Search
            placeholder={"Search by name"}
            searchItems={searchItems}
            setState={setState}
            state={state}
            width={"400px"}
          />
        }
      />

      <Modal
        displayModal={state.inviteModal}
        closeModal={() => closeModal("inviteModal")}
        title={"Admin Invite"}
        width={"496px"}
      >
        <SendInvite
          category={category}
          searchUserEmail={searchUserEmail}
          sendUserInvite={sendUserInvite}
          searchedUser={searchedUser}
          searchUserSuccess={searchUserSuccess}
          isSending={isSending}
          isSearching={isSearching}
          sendInviteSuccess={sendInviteSuccess}
          closeModal={() => cancelModal("inviteModal", state, setState)}
        />
      </Modal>
    </div>
  );
};

Admin.propTypes = {
  getRoles: PropTypes.func,
  getRolesSuccess: PropTypes.bool,
  getUserData: PropTypes.func,
  getUsersSuccess: PropTypes.bool,
  isGetting: PropTypes.bool,
  isGettingUsers: PropTypes.bool,
  isSearching: PropTypes.bool,
  isSearchingAdmin: PropTypes.bool,
  isSending: PropTypes.bool,
  roles: PropTypes.shape({
    length: PropTypes.number,
  }),
  searchAdminUsers: PropTypes.func,
  searchAdminUsersSuccess: PropTypes.bool,
  searchUserEmail: PropTypes.func,
  searchUserSuccess: PropTypes.bool,
  searchedAdminUsers: PropTypes.array,
  searchedUser: PropTypes.array,
  sendInviteSuccess: PropTypes.bool,
  sendUserInvite: PropTypes.func,
  users: PropTypes.array,
  usersData: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  getRoles: () => dispatch(getAdminRoles()),
  searchUserEmail: (payload, func) => dispatch(searchUser(payload, func)),
  sendUserInvite: (payload) => dispatch(sendInvite(payload)),
  getUserData: (page, limit) => dispatch(getUsers(page, limit)),
  searchAdminUsers: (search) => dispatch(searchAdminUsers(search)),
});

const mapStateToProps = (state) => ({
  isGetting: state.admin.isGetting,
  getRolesSuccess: state.admin.getRolesSuccess,
  roles: state.admin.roles,
  userEmail: state.admin.user,
  isSearching: state.admin.isSearching,
  searchedUser: state.admin.searchedUser,
  searchUserSuccess: state.admin.searchUserSuccess,
  isSending: state.admin.isSending,
  sendInviteSuccess: state.admin.sendInviteSuccess,
  users: state.admin.users,
  usersData: state.admin.usersData,
  isGettingUsers: state.admin.isGettingUsers,
  getUsersSuccess: state.admin.getUsersSuccess,
  isSearchingAdmin: state.admin.isSearchingAdmin,
  searchedAdminUsers: state.admin.searchedAdminUsers,
  searchAdminUsersSuccess: state.admin.searchAdminUsersSuccess,
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
