import PropTypes from "prop-types";
import React from "react";

const DeleteModal = (props) => {
  const {
    title,
    width,
    name,
    text,
    type,
    onClick,
    show,
    disabled,
    isLoading,
    loadingText,
  } = props;

  const divStyle = {
    display: props.displayModal ? "flex" : "none",
  };

  const closeModal = () => {
    props.closeModal();
  };

  return (
    <div className="modal2" style={divStyle}>
      <div style={{ width: `${width}` }} className="modal2-content">
        <div className="modal2-content-header">
          <h1>{title}</h1>
          <span
            className="close-modal"
            onClick={closeModal}
            data-testid="closeModal"
          >
            &times;
          </span>
        </div>

        <div className="modal2-content-container">
          <div className="modal2-content-container-text">
            Kindly confirm that you want to {type} <span>{name}</span>{" "}
            {show ? "SMS provider?" : ""}
          </div>
          <div className="modal2-content-container-button">
            <span onClick={closeModal}>Cancel</span>

            <button
              data-testid="button"
              type="submit"
              onClick={onClick}
              disabled={disabled}
            >
              {isLoading ? (
                <div className="loader">
                  <div className="loader-text">{loadingText}</div>
                </div>
              ) : (
                text
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

DeleteModal.propTypes = {
  children: PropTypes.any,
  closeModal: PropTypes.func,
  disabled: PropTypes.any,
  displayModal: PropTypes.any,
  isLoading: PropTypes.any,
  loadingText: PropTypes.any,
  name: PropTypes.any,
  onClick: PropTypes.any,
  show: PropTypes.any,
  text: PropTypes.any,
  title: PropTypes.any,
  type: PropTypes.any,
  width: PropTypes.any,
};

export default DeleteModal;
