import React from "react";
import { PropTypes } from "prop-types";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";

const SelectInput = ({
  field,
  name,
  options,
  defaultOption,
  form,
  label,
  id,
  onChange,
}) => {
  return (
    <div className="input-wrapper">
      <div className="input-container">
        {label && <p className="label">{label}</p>}
        <Dropdown
          options={options}
          onChange={onChange}
          value={defaultOption}
          placeholder="Select an option"
        />
      </div>
    </div>
  );
};

SelectInput.propTypes = {
  options: PropTypes.array,
  field: PropTypes.object,
  form: PropTypes.object,
  onChange: PropTypes.func,
  handleBlur: PropTypes.func,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

export default SelectInput;
