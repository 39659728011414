import React, { useState } from "react";
import { PropTypes } from "prop-types";

const Input = (props) => {
  const {
    type,
    placeholder,
    field,
    form,
    onChange,
    onBlur,
    name,
    onFocus,
    label,
    password,
    onKeyDown,
    onPaste,
    value,
    readOnly,
    className,
  } = props;

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleType = () => {
    if (type === "password" && !showPassword) {
      return "password";
    } else if (type !== "password" && !showPassword) {
      return type;
    }
  };

  const errorCheck =
    form && form.touched[field.name] && form && form.errors[field.name];

  return (
    <div className="input-wrapper">
      <div className="input-container">
        <label className="label">{label}</label>
        <input
          type={handleType()}
          onFocus={(field && field.onFocus) || onFocus}
          onChange={(field && field.onChange) || onChange}
          onBlur={(field && field.onBlur) || onBlur}
          value={(field && field.value) || value || ""}
          name={(field && field.name) || name}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
          onPaste={onPaste}
          className={
            className
              ? className
              : errorCheck
              ? "error-border"
              : readOnly
              ? "input readonly-input"
              : "input"
          }
          readOnly={readOnly}
        />
        {password && (
          <span
            className="showhide-btn"
            role="button"
            onClick={handleShowPassword}
          >
            {showPassword ? "Hide" : "Show"}
          </span>
        )}
      </div>

      {errorCheck && (
        <small className="errors">{form.errors[field.name]}</small>
      )}
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.any,
  field: PropTypes.object,
  form: PropTypes.object,
  label: PropTypes.any,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.any,
  onPaste: PropTypes.any,
  password: PropTypes.any,
  placeholder: PropTypes.string,
  readOnly: PropTypes.any,
  type: PropTypes.string,
  value: PropTypes.string
}

export default Input;
