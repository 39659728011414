import React from "react";
import AuthWrapper, { Headers } from "../../components/Wrappers/AuthWrapper";
import Checkemail from "../../assets/check-email.svg";

export const CheckEmail = () => {
  return (
    <AuthWrapper>
      <Headers
        title1={"Check Your Inbox"}
        title2={"Password reset link have been sent to your inbox"}
      />
      <div className="check-email">
        <img src={Checkemail} alt="check Email" />{" "}
      </div>
    </AuthWrapper>
  );
};

export default CheckEmail;
