import React, { useState } from "react";
import { nameAcronym } from "../../../utils/functions/acroymn";
import { ErrorBtn, SuccessBtn, DropBtn } from "../../Tables/TableButtons";
import {
  cancelModal,
  viewModal,
} from "../../../pages/SMS Providers/SMSProviders";
import TableDropdown from "../../Tables/Dropdown";
import Modal from "../../Modal/Modal";
import { useHistory } from "react-router-dom";
import moment from "moment";
import EditProduct from "../../Products/EditProduct";
import DeleteProduct from "../../Products/DeleteProduct";

export const productColumns = [
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ row }) => (
      <div className="table-flex">
        <div className="table-flex-image">{nameAcronym(row.original.name)}</div>
        <span style={{ marginLeft: "12px" }}>{row.original.name}</span>
      </div>
    ),
  },
  {
    Header: "SMS Limit",
    accessor: "sms_rate_limit",
  },
  {
    Header: "SMS Sent",
    accessor: "sms_sent",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row }) =>
      row.original.enabled === true ? (
        <SuccessBtn text={"Active"} />
      ) : (
        <ErrorBtn text={"Inactive"} />
      ),
  },
  {
    Header: "Date Sent",
    accessor: "date",
    Cell: ({ row }) => {
      const [show, setShow] = useState(false);

      const [state, setState] = useState({
        editModal: false,
        viewModal: false,
        deleteModal: false,
        deactivateModal: false,
      });

      const history = useHistory();

      const open = (type) => {
        viewModal(type, state, setState);
        setShow((prevCheck) => !prevCheck);
      };

      const goToSinglePage = () => {
        const id = row.original && row.original.id;
        history.push({
          pathname: `/products/${id}`,
          state: {
            id: id,
            data: row.original,
          },
        });
      };

      const options = [
        {
          name: "View",
          action: () => goToSinglePage(),
        },
        {
          name: "Edit",
          action: () => open("editModal"),
        },
        {
          name: "Delete",
          action: () => open("deleteModal"),
        },
      ];

      const closeEditModal = () => {
        cancelModal("editModal", state, setState);
      };

      return (
        <div className="table-flex">
          <span className="table-flex-date">
            {moment(row.original.created_at).format("LL")} |{" "}
            <small>{moment(row.original.created_at).format("LT")}</small>
          </span>
          <DropBtn
            marginLeft={"16px"}
            onClick={() => setShow((prevCheck) => !prevCheck)}
          />

          {show && <TableDropdown options={options} />}

          {state.editModal && (
            <Modal
              displayModal={state.editModal}
              closeModal={() => closeEditModal()}
              title={"Edit"}
              width={"574px"}
            >
              <EditProduct
                data={row.original}
                closeModal={() => closeEditModal()}
              />
            </Modal>
          )}

          <DeleteProduct
            state={state}
            data={row.original}
            closeModal={() => cancelModal("deleteModal", state, setState)}
          />
        </div>
      );
    },
  },
];
