import PropTypes from "prop-types";
import React from "react";

const Search = (props) => {
  const { placeholder, width, searchItems, setState, state } = props;

  const checkKey = (e) => {
    if (e.key === "Enter") {
      searchItems();
    }
  };

  const onSearch = (event) => {
    setState({
      ...state,
      searchValue: event.target.value,
    });
  };

  return (
    <input
      onChange={onSearch}
      placeholder={placeholder}
      value={state && state.searchValue}
      onKeyDown={checkKey}
      className="search-div"
      style={{
        width: `${width}`,
      }}
    />
  );
};

Search.propTypes = {
  onChange: PropTypes.any,
  placeholder: PropTypes.any,
  searchItems: PropTypes.func,
  setState: PropTypes.func,
  state: PropTypes.shape({
    searchValue: PropTypes.any,
  }),
  width: PropTypes.any,
};

export default Search;
