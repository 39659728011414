import PropTypes from "prop-types";
import React from "react";
import ProgressBar from "@ramonak/react-progress-bar";

const SmsProgress = (props) => {
  const { metrics } = props;

  const successPercentage = (suc, fail) => {
    let total = suc + fail;
    return (suc / total) * 100;
  };

  const failurePercentage = (suc, fail) => {
    let total = suc + fail;
    return (fail / total) * 100;
  };

  return (
    <div className="sms-details">
      <div className="title-wrap">
        <div className="chart-wrap2-title">SMS Details</div>
        <div className="chart-wrap2-title2">
          Total SMS sent: <span>{metrics && metrics.totalSmsCount}</span>
        </div>
      </div>
      <div className="sms-details-bar">
        <div className="sms-details-title">Successful SMS</div>
        <ProgressBar
          completed={successPercentage(
            metrics && metrics.totalSuccessfulSmsCount,
            metrics && metrics.totalFailedSmsCount
          )}
          bgColor={"#27be63"}
          height={"8px"}
          width={"290px"}
          isLabelVisible={false}
        />
        <div className="sms-details-count">
          {metrics && metrics.totalSuccessfulSmsCount}
        </div>
      </div>
      <div className="sms-details-bar">
        <div className="sms-details-title">Failed SMS</div>
        <ProgressBar
          completed={failurePercentage(
            metrics && metrics.totalSuccessfulSmsCount,
            metrics && metrics.totalFailedSmsCount
          )}
          bgColor={"#ea6262"}
          height={"8px"}
          width={"290px"}
          isLabelVisible={false}
        />
        <div className="sms-details-count">
          {metrics && metrics.totalFailedSmsCount}
        </div>
      </div>
    </div>
  );
};

SmsProgress.propTypes = {
  metrics: PropTypes.shape({
    totalFailedSmsCount: PropTypes.any,
    totalSmsCount: PropTypes.any,
    totalSuccessfulSmsCount: PropTypes.any,
  }),
};

export default SmsProgress;
