import PropTypes from "prop-types";
import React from "react";

const SwitchWrapper = (props) => {
  const {
    title1,
    title2,
    right1,
    right2,
    component1,
    component2,
    state,
    setstate
  } = props;

  const setActiveClass1 = () => {
    setstate({
      ...state,
      active1: true,
      active2: false,
    });
  };

  const setActiveClass2 = () => {
    setstate({
      ...state,
      active1: false,
      active2: true,
    });
  };

  return (
    <div className="switch-wrapper">
      <div className="switch-wrapper-top">
        <div className="switch-wrapper-top-link">
          <div
            className={
              state.active1
                ? "switch-wrapper-top-link-two"
                : "switch-wrapper-top-link-one"
            }
            data-testid="setActiveClass1"
            onClick={setActiveClass1}
          >
            {title1}
          </div>
          <div
            className={
              state.active2
                ? "switch-wrapper-top-link-two"
                : "switch-wrapper-top-link-one"
            }
            data-testid="setActiveClass2"
            onClick={setActiveClass2}
          >
            {title2}
          </div>
        </div>

        {state.active1 ? (
          <div className="switch-wrapper-top-right">{right1}</div>
        ) : (
          <div className="switch-wrapper-top-right">{right2}</div>
        )}
      </div>
      {state.active1 ? <div>{component1}</div> : <div>{component2}</div>}
    </div>
  );
};

SwitchWrapper.propTypes = {
  component1: PropTypes.any,
  component2: PropTypes.any,
  right1: PropTypes.any,
  right2: PropTypes.any,
  title1: PropTypes.any,
  title2: PropTypes.any
}

export default SwitchWrapper;
