import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { isEmail, ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import { ModalButton } from "../Modal/Modal";
import Dropdown from "react-dropdown";

const SendInvite = (props) => {
  const {
    category,
    searchUserEmail,
    // searchedUser,
    isSending,
    isSearching,
    sendInviteSuccess,
    // searchUserSuccess,
    sendUserInvite,
  } = props;

  const [state, setState] = useState({
    emails: [],
    email: [],
    value: "",
    error: null,
    selected: "",
    searchUserValue: "",
    search: "",
  });

  const closeModal = () => {
    props.closeModal();
  };

  useEffect(
    () => {
      if (sendInviteSuccess) {
        closeModal();
      }
    }, // eslint-disable-next-line
    [sendInviteSuccess]
  );

  const handleDataFetch = (email) => {
    let emails = [...state.emails, email];
    setState({ ...state, emails });
  };

  const searchEmail = (setEmails) => {
    const payload = {
      email: setEmails[setEmails.length - 1],
    };
    searchUserEmail(payload, handleDataFetch);
  };

  const { selected, emails } = state;

  const handleInvite = () => {
    const invitevalues = {
      roleId: selected,
      userInfo: emails,
    };
    sendUserInvite(invitevalues);
  };

  const runFunction = (emailAddress) => {
    setState({ ...state, error: null });
    if (state.error === null) {
      searchEmail(emailAddress);
    }
  };

  const validateEmail = (email) => {
    if (isEmail(email)) {
      return isEmail(email);
    } else {
      setState({
        ...state,
        error: `${email} is not a valid email address.`,
        email: [],
      });
    }
  };

  const disable = emails === [] || selected === "";

  return (
    <div>
      <p className="registered-email">
        Enter a registered email address to send admin invite
      </p>

      <h3 className="email-text">Email </h3>

      <ReactMultiEmail
        id="formselect"
        label="Admin Type"
        placeholder="Enter an email"
        emails={state.email}
        onChange={runFunction}
        validateEmail={(email) => validateEmail(email)}
        getLabel={(email, index, removeEmail) => {
          return (
            <div data-tag key={index}>
              {email}
              <span data-tag-handle onClick={() => removeEmail(index)}>
                ×
              </span>
            </div>
          );
        }}
      />

      {isSearching && <div className="searching-email">Searching...</div>}
      {state.error && <p className="error">{state.error}</p>}

      <div className="role-select">
        <h3 className="email-text">Role</h3>

        <Dropdown
          id="formselect"
          options={category}
          onChange={(data) =>
            setState({
              ...state,
              selected: data.value,
            })
          }
          label="Admin Type"
          placeholder="Select an Admin Type"
        />
      </div>

      <ModalButton
        text={"Send Invite"}
        onClick={handleInvite}
        loadingText={"Sending..."}
        isLoading={isSending}
        disabled={isSending || isSearching || disable}
      />
    </div>
  );
};

SendInvite.propTypes = {
  category: PropTypes.any,
  closeModal: PropTypes.func,
  isSearching: PropTypes.bool,
  isSending: PropTypes.bool,
  searchUserEmail: PropTypes.func,
  sendInviteSuccess: PropTypes.bool,
  sendUserInvite: PropTypes.func,
};

export default SendInvite;
