import React from "react";
import ReactCountryFlag from "react-country-flag";
import { nameAcronym } from "../../../utils/functions/acroymn";

export const rankingColumns = [
  {
    Header: "Country",
    accessor: "country",
    Cell: ({ row }) => (
      <div className="table-flex">
        <ReactCountryFlag
          countryCode={`${row.original.country_code}`}
          style={{
            width: "32px",
            height: "32px",
            borderRadius: "50%",
          }}
          svg
          title={`${row.original.country_code}`}
        />
        <span style={{ marginLeft: "12px" }}>{row.original.country}</span>
      </div>
    ),
  },
  {
    Header: "Default Provider",
    accessor: "name",
    Cell: ({ row }) => (
      <div className="table-flex">
        <div className="table-flex-image">{nameAcronym(row.original.name)}</div>
        <span style={{ marginLeft: "12px" }}>{row.original.name}</span>
      </div>
    ),
  },
  {
    Header: "No of Providers",
    accessor: "no_of_providers",
  }
];
