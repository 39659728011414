import React from "react";
import { PropTypes } from "prop-types";

const Label = ({
  label,
  children
}) => {
  return (
    <div className="input-wrapper">
      <div className="input-container">
        {label && <p className="label">{label}</p>}
        {children}
      </div>
    </div>
  );
};

Label.propTypes = {
  children: PropTypes.any,
  label: PropTypes.any
}

export default Label;
