import PropTypes from "prop-types";
import React from "react";
import { Field } from "formik";
import ReactFlagsSelect from "react-flags-select";
import Input from "../../Input/Input";
import Label from "../../Input/Label";
import { AddMore, Configuration } from "./Components";
import { Soap } from "./Soap";
import { Smpp } from "./Smpp";
import { Rest } from "./Rest";
import { ToggleButton } from "../../Input/ToggleButton";
import { countries } from "country-data";
import Button from "../../Button/Button";
import { ModalButton } from "../../Modal/Modal";

const AddProviderScreen1 = (props) => {
  const {
    state,
    setState,
    addNewRow,
    handleToggle,
    disableBtnRest,
    isValid,
    dirty,
    setStep2,
    isAdding,
    disableBtn,
  } = props;

  const setSelectedCountry = (code, index) => {
    const newSelectedCountries = state.selectedCountries.map((country, idx) => {
      if (index !== idx) return country;
      return { ...country, country_code: code };
    });
    setState({ ...state, selectedCountries: newSelectedCountries });
  };

  const setSelectedCurrency = (index) => (event) => {
    const newSelectedCountries = state.selectedCountries.map((country, idx) => {
      if (index !== idx) return country;
      return { ...country, country_currency: event.target.value };
    });
    setState({ ...state, selectedCountries: newSelectedCountries });
  };

  return (
    <div>
      {state.requestStep1 && state.request1 && (
        <p className="steps">Step 1 of 4</p>
      )}
      <Field
        name="name"
        label="Name"
        placeholder="Provider Name"
        component={Input}
      />

      {state.selectedCountries &&
        state.selectedCountries.map((country, index) => (
          <div className="form-grid2" key={index}>
            <Label label={"Provider Country"} id={index}>
              <div className="add-provider-flags">
                <ReactFlagsSelect
                  selected={country.country_code}
                  onSelect={(code) => setSelectedCountry(code, index)}
                  searchable={true}
                  placeholder="Select Country"
                  optionsSize="16px"
                  id={`add-provider-country${index}`}
                />
              </div>
            </Label>

            <Label label={"Price per SMS"} id={index}>
              <div className="country-currency">
                <div className="country-currency-code">
                  {countries &&
                    countries[country.country_code] &&
                    countries[country.country_code].currencies &&
                    countries[country.country_code].currencies[0]}
                </div>
                <div className="divider"></div>
                <div className="country-currency-value">
                  <input
                    type="number"
                    placeholder="0.00"
                    onChange={setSelectedCurrency(index)}
                    value={country.country_currency || ""}
                  />
                </div>
              </div>
            </Label>
          </div>
        ))}

      <AddMore onClick={addNewRow} />

      <Configuration
        state={state}
        setstate={setState}
        component1={<Rest state={state} setState={setState} />}
        component2={<Soap state={state} setState={setState} />}
        component3={<Smpp />}
      />

      <ToggleButton
        text={`Toggle to ${
          state.checked ? "deactivate" : "activate"
        } sms provider`}
        handleChange={handleToggle}
        checked={state.checked}
        id={"add-toggle"}
      />

      {(state.request1 || state.request3) && state.requestStep1 && (
        <div style={{ textAlign: "right", marginBottom: "50px" }}>
          <Button
            width={"50%"}
            height={"48px"}
            testId={"button"}
            type={"button"}
            marginTop={"32px"}
            onClick={() => setStep2()}
            disabled={disableBtnRest || disableBtn || !(isValid && dirty)}
          >
            Next
          </Button>
        </div>
      )}

      {state.request2 && (
        <ModalButton
          loadingText={"Saving..."}
          text={"Save"}
          isLoading={isAdding}
          marginBottom={"50px"}
          disabled={disableBtn || !(isValid && dirty) || isAdding}
        />
      )}
    </div>
  );
};

AddProviderScreen1.propTypes = {
  addNewRow: PropTypes.any,
  dirty: PropTypes.any,
  disableBtn: PropTypes.any,
  disableBtnRest: PropTypes.any,
  handleToggle: PropTypes.any,
  isAdding: PropTypes.bool,
  isValid: PropTypes.bool,
  setState: PropTypes.func,
  setStep2: PropTypes.func,
  state: PropTypes.shape({
    checked: PropTypes.bool,
    request1: PropTypes.bool,
    requestStep1: PropTypes.bool,
    selectedCountries: PropTypes.shape({
      map: PropTypes.func,
    }),
  }),
};

export default AddProviderScreen1;
