export const countryCodeToName = {
    "BW": "Botswana",
    "NG": "Nigeria",
    "NA": "Namibia",
    "GH": "Ghana",
    "UG": "Uganda",
    "MZ": "Mozambique",
    "LS": "Lesotho",
    "SZ": "Eswatini",
    "KE": "Kenya",
    "RW": "Rwanda",
    "TZ": "Tanzania"
}

export const countryCodeToProvider = {
    "BW": "Internal Drift",
    "NG": "Inter Switch",
    "NA": "MTC",
    "GH": "Hubtel",
    "UG": "Infobip",
    "MZ": "Infobip",
    "LS": "Econet",
    "SZ": "Infobip",
    "KE": "Collection Africa",
    "RW": "Infobip",
    "TZ": "Radian"
}
