import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";

// GET ALL RANKS
export const getAllRanksStart = () => ({
  type: Actions.GET_ALL_RANKS_START,
});

export const getAllRanksSuccess = (payload) => ({
  type: Actions.GET_ALL_RANKS_SUCCESS,
  payload,
});

export const getAllRanksFailure = (payload) => ({
  type: Actions.GET_ALL_RANKS_FAILURE,
  payload,
});

// SEARCH RANKS
export const searchRanksStart = () => ({
  type: Actions.SEARCH_RANKS_START,
});

export const searchRanksSuccess = (payload) => ({
  type: Actions.SEARCH_RANKS_SUCCESS,
  payload,
});

export const searchRanksFailure = (payload) => ({
  type: Actions.SEARCH_RANKS_FAILURE,
  payload,
});

// GET RANK LIST
export const getRankListStart = () => ({
  type: Actions.GET_RANKS_LIST_START,
});

export const getRankListSuccess = (payload) => ({
  type: Actions.GET_RANKS_LIST_SUCCESS,
  payload,
});

export const getRankListFailure = (payload) => ({
  type: Actions.GET_RANKS_LIST_FAILURE,
  payload,
});

// EDIT RANKS
export const editRanksStart = () => ({
  type: Actions.EDIT_RANKS_START,
});

export const editRanksSuccess = (payload) => ({
  type: Actions.EDIT_RANKS_SUCCESS,
  payload,
});

export const editRanksFailure = (payload) => ({
  type: Actions.EDIT_RANKS_FAILURE,
  payload,
});

export const getAllRanks = (page, limit) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(getAllRanksStart());
  API.get(`/sms/ranking/providers?page=${page}&limit=${limit}`).then(
    (response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(getAllRanksSuccess(data && data.data));
      } else {
        dispatch(getAllRanksFailure(data));
        responseHandler(response);
      }
    }
  );
};

export const searchRanks = (search, page, limit) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(searchRanksStart());
  API.get(
    `/sms/ranking/providers?search=${search}&page=${page}&limit=${limit}`
  ).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(searchRanksSuccess(data && data.data));
    } else {
      dispatch(searchRanksFailure(data));
      responseHandler(response);
    }
  });
};

export const getRankList = (country, updateCharacters) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(getRankListStart());
  API.get(`sms/providers-in-country/${country}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getRankListSuccess(data && data.data));
      updateCharacters(data && data.data);
    } else {
      dispatch(getRankListFailure(data));
      responseHandler(response);
    }
  });
};

export const editRanks = (payload, closeModal) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(editRanksStart());
  API.put("sms/ranking/providers", payload).then((response) => {
    const { data, ok } = response;
    responseHandler(response);
    if (ok) {
      dispatch(editRanksSuccess(data && data.data));
      closeModal();
    } else {
      dispatch(editRanksFailure(data));
    }
  });
};
