import PropTypes from "prop-types";
import React from "react";
import { Form, Formik } from "formik";
import {
  handleCurlSubmit,
  handleSmppSubmit,
  handleSoapSubmit,
} from "../../utils/functions/provider";
import { filterObj } from "../../utils/functions/filterObj";
import EditProviderScreen1 from "./EditProvider/EditProviderScreen1";
import EditProviderScreen4 from "./EditProvider/EditProviderScreen4";
import ProviderScreen2 from "./AddProvider/ProviderScreen2";
import ProviderScreen3 from "./AddProvider/ProviderScreen3";

const EditProvider = (props) => {
  const { data, state, setState, isEditing, editProvider } = props;

  const initialValues = {
    name: data.name,
    curl_request: state.request,
    soap_request: state.request,
    url: state.url,
    system_id: state.system_id,
    password: state.password,
    initial_statusPath: state.initial_status_path,
    initial_messagePath: state.initial_message_id_path,
    final_statusPath: state.final_status_path,
    final_messagePath: state.final_message_id_path,
    sender: "",
    message: "",
    destination: "",
  };

  const type = data && data.request_type;

  const addNewRow = () => {
    setState({
      ...state,
      selectedCountries: state.selectedCountries.concat([
        { country_code: "", country_currency: "" },
      ]),
    });
  };

  const uploadCountries = filterObj(state.selectedCountries);
  const uploadedKeys = filterObj(state.addedKeys);

  let variableDefault = {};
  let priceAndCountryCode = [];

  const submitCurlValues = handleCurlSubmit(
    uploadedKeys,
    variableDefault,
    state,
    uploadCountries,
    priceAndCountryCode,
    editProvider,
    state.addHook
  );

  const submitSoapValues = handleSoapSubmit(
    uploadedKeys,
    variableDefault,
    state,
    uploadCountries,
    priceAndCountryCode,
    editProvider
  );

  const submitSmppValues = handleSmppSubmit(
    state,
    uploadCountries,
    priceAndCountryCode,
    editProvider
  );

  const handleSubmit = (values) => {
    if (type === "curl") {
      submitCurlValues(values, "curl", "edit");
    } else if (type === "soap") {
      submitSoapValues(values, "soap", "edit");
    } else {
      submitSmppValues(values, "smpp", "edit");
    }
  };

  const handleToggle = () => {
    setState({
      ...state,
      checked: (state.checked = !state.checked),
    });
  };

  const handleChange = (tags, type) => {
    setState({
      ...state,
      [type]: tags,
    });
  };

  const goBack = () => {
    setState({
      ...state,
      showLoaders: false,
      requestStep1: true,
      requestStep2: false,
      requestStep3: false,
      requestStep4: false,
    });
  };

  const setStep1 = () => {
    setState({
      ...state,
      requestStep1: true,
      requestStep2: false,
      requestStep3: false,
      requestStep4: false,
    });
  };

  const setStep2 = () => {
    setState({
      ...state,
      requestStep1: false,
      requestStep2: true,
      requestStep3: false,
      requestStep4: false,
    });
  };

  const setStep3 = () => {
    setState({
      ...state,
      requestStep1: false,
      requestStep2: false,
      requestStep3: true,
      requestStep4: false,
    });
  };

  const setStep4 = () => {
    setState({
      ...state,
      requestStep1: false,
      requestStep2: false,
      requestStep3: false,
      requestStep4: true,
    });
  };

  return (
    <div className="add-provider">
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ isValid, dirty, values }) => {
          return (
            <Form id="editProvider">
              {state.requestStep1 && (
                <EditProviderScreen1
                  state={state}
                  setState={setState}
                  addNewRow={addNewRow}
                  handleToggle={handleToggle}
                  setStep2={setStep2}
                  isEditing={isEditing}
                  type={type}
                  data={data}
                />
              )}

              {state.requestStep2 && (
                <ProviderScreen2
                  state={state}
                  setStep1={setStep1}
                  setStep3={setStep3}
                  setState={setState}
                  values={values}
                  webHookUrl={state.webHookUrl}
                />
              )}

              {state.requestStep3 && (
                <ProviderScreen3
                  state={state}
                  setStep2={setStep2}
                  setStep4={setStep4}
                  handleChange={handleChange}
                  setState={setState}
                />
              )}

              {state.requestStep4 && (
                <EditProviderScreen4
                  state={state}
                  setStep3={setStep3}
                  goBack={goBack}
                  setState={setState}
                  values={values}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

EditProvider.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.any,
    request_type: PropTypes.any,
  }),
  editProvider: PropTypes.any,
  isEditing: PropTypes.bool,
  setState: PropTypes.func,
  state: PropTypes.shape({
    addHook: PropTypes.any,
    addedKeys: PropTypes.any,
    checked: PropTypes.any,
    final_message_id_path: PropTypes.any,
    final_status_path: PropTypes.any,
    initial_message_id_path: PropTypes.any,
    initial_status_path: PropTypes.any,
    password: PropTypes.any,
    request: PropTypes.any,
    requestStep1: PropTypes.bool,
    requestStep2: PropTypes.bool,
    requestStep3: PropTypes.bool,
    requestStep4: PropTypes.bool,
    selectedCountries: PropTypes.shape({
      concat: PropTypes.func,
    }),
    system_id: PropTypes.any,
    url: PropTypes.any,
    webHookUrl: PropTypes.any,
  }),
};

export default EditProvider;
