import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";
import axios from "axios";

// GET REQUESTS
export const getRequestsStart = () => ({
  type: Actions.GET_REQUESTS_START,
});

export const getRequestsSuccess = (payload) => ({
  type: Actions.GET_REQUESTS_SUCCESS,
  payload,
});

export const getRequestsFailure = (payload) => ({
  type: Actions.GET_REQUESTS_FAILURE,
  payload,
});

// AUTHORISE REQUEST
export const authoriseRequestStart = () => ({
  type: Actions.AUTHORISE_REQUEST_START,
});

export const authoriseRequestSuccess = (payload) => ({
  type: Actions.AUTHORISE_REQUEST_SUCCESS,
  payload,
});

export const authoriseRequestFailure = (payload) => ({
  type: Actions.AUTHORISE_REQUEST_FAILURE,
  payload,
});

export const getRequests = (page, limit) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(getRequestsStart());
  API.get(`authorizations?page=${page}&limit=${limit}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getRequestsSuccess(data && data.data && data.data));
    } else {
      responseHandler(response);
      dispatch(getRequestsFailure(data));
    }
  });
};

export const authoriseRequest = (url, payload) => async (dispatch) => {
  const token = Storage.get("user-access-token");
  let config = {
    headers: {
      Authorization: token,
    },
  };
  let data = payload;
  dispatch(authoriseRequestStart());
  axios
    .post(`${process.env.REACT_APP_LETSHEGO_CHECKER_API_KEY}${url}`, data, config)
    .then((response) => {
      const { data, status, ok } = response;
      responseHandler(response);
      if (status === 200 || status === 201 || ok) {
        dispatch(authoriseRequestSuccess(data && data.data));
      } else {
        dispatch(authoriseRequestFailure(data));
      }
    })
    .catch(function (error) {
      if (error.response) {
        responseHandler(error.response);
        dispatch(authoriseRequestFailure(error.response));
      }
    });
};
