import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { deleteProduct } from "../../redux/Actions/ProductAction";
import DeleteModal from "../Modal/DeleteModal";

const DeleteProduct = (props) => {
  const { deleteProduct, isDeleting, deleteSuccess, state, data } = props;

  const id = data && data.id;

  useEffect(
    () => {
      if (deleteSuccess) {
        props.closeModal();
      }
    }, // eslint-disable-next-line
    [deleteSuccess]
  );

  const handleDelete = (id) => {
    deleteProduct(id);
  };

  return (
    <DeleteModal
      displayModal={state.deleteModal}
      closeModal={props.closeModal}
      title={"Delete Product"}
      name={data && data.name}
      type={"delete"}
      text={"Delete"}
      show={false}
      disabled={isDeleting}
      isLoading={isDeleting}
      loadingText={"Deleting..."}
      onClick={() => handleDelete(id)}
      width={"496px"}
    />
  );
};

DeleteProduct.propTypes = {
  closeModal: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.any,
  }),
  deleteProduct: PropTypes.func,
  deleteSuccess: PropTypes.bool,
  isDeleting: PropTypes.bool,
  state: PropTypes.shape({
    deleteModal: PropTypes.any,
  }),
};

const mapStateToProps = (state) => ({
  isDeleting: state.product.isDeletingProduct,
  deleteSuccess: state.product.deleteProductSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  deleteProduct: (id) => dispatch(deleteProduct(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteProduct);
