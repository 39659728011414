import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import { filterObj } from "../../utils/functions/filterObj";
import { AddProviderSchema } from "../../utils/validation/validation";
import {
  handleCurlSubmit,
  handleSmppSubmit,
  handleSoapSubmit,
  submitProviderValues,
} from "../../utils/functions/provider";
import AddProviderScreen1 from "./AddProvider/AddProviderScreen1";
import AddProviderScreen4 from "./AddProvider/AddProviderScreen4";
import { createUrlQuery } from "../../utils/functions/formatText";
import ProviderScreen2 from "./AddProvider/ProviderScreen2";
import ProviderScreen3 from "./AddProvider/ProviderScreen3";

const initialValues = {
  name: "",
  curl_request: "",
  initial_statusPath: "",
  initial_messagePath: "",
  final_statusPath: "",
  final_messagePath: "",
  soap_request: "",
  url: "",
  system_id: "",
  password: "",
  sender: "",
  message: "",
  destination: "",
};

const AddProvider = (props) => {
  const {
    isAdding,
    addProvider,
    addstate,
    setAddState,
    addSuccess,
    closeLoaders,
  } = props;
  const [state, setState] = useState({
    checked: true,
    request1: true,
    request2: false,
    request3: false,
    requestStep1: true,
    requestStep2: false,
    requestStep3: false,
    requestStep4: false,
    index: 0,
    selectedCountries: [{ country_code: "", country_currency: "" }],
    addedKeys: [{ key: "", value: "" }],
    delivered: [],
    success: [],
    undelivered: [],
    pending: [],
    failed: [],
    showLoaders: false,
  });

  const handleToggle = () => {
    setState({
      ...state,
      checked: (state.checked = !state.checked),
    });
  };

  const handleChange = (tags, type) => {
    setState({
      ...state,
      [type]: tags,
    });
  };

  const goBack = () => {
    setAddState({
      ...addstate,
      showLoaders: false,
    });
    setStep1();
  };

  const setStep1 = () => {
    setState({
      ...state,
      requestStep1: true,
      requestStep2: false,
      requestStep3: false,
      requestStep4: false,
    });
  };

  const setStep2 = () => {
    setState({
      ...state,
      requestStep1: false,
      requestStep2: true,
      requestStep3: false,
      requestStep4: false,
    });
  };

  const setStep3 = () => {
    setState({
      ...state,
      requestStep1: false,
      requestStep2: false,
      requestStep3: true,
      requestStep4: false,
    });
  };

  const setStep4 = () => {
    setState({
      ...state,
      requestStep1: false,
      requestStep2: false,
      requestStep3: false,
      requestStep4: true,
    });
  };

  const addNewRow = () => {
    setState({
      ...state,
      selectedCountries: state.selectedCountries.concat([
        { country_code: "", country_currency: "" },
      ]),
    });
  };

  const uploadCountries = filterObj(state.selectedCountries);
  const uploadedKeys = filterObj(state.addedKeys);

  let variableDefault = {};
  let priceAndCountryCode = [];

  const submitCurlValues = handleCurlSubmit(
    uploadedKeys,
    variableDefault,
    state,
    uploadCountries,
    priceAndCountryCode,
    addProvider,
    addstate.addHook
  );

  const submitSoapValues = handleSoapSubmit(
    uploadedKeys,
    variableDefault,
    state,
    uploadCountries,
    priceAndCountryCode,
    addProvider
  );

  const submitSmppValues = handleSmppSubmit(
    state,
    uploadCountries,
    priceAndCountryCode,
    addProvider,
    addstate.addHook
  );

  const handleSubmit = submitProviderValues(
    state,
    submitCurlValues,
    submitSoapValues,
    submitSmppValues
  );

  const returnState = () => {
    setState({
      ...state,
      selectedCountries: [{ country_code: "", country_currency: "" }],
      addedKeys: [{ key: "", value: "" }],
      delivered: [],
      success: [],
      undelivered: [],
      pending: [],
      failed: [],
      requestStep1: true,
      requestStep2: false,
      requestStep3: false,
      requestStep4: false,
    });
  };

  useEffect(
    () => {
      if (addSuccess) {
        returnState();
        closeLoaders();
        document.getElementById("addProvider").reset();
      }
    },
    // eslint-disable-next-line
    [addSuccess]
  );

  return (
    <div className="add-provider">
      <Formik
        initialValues={initialValues}
        validationSchema={AddProviderSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ values, isValid, dirty }) => {
          let disableBtn = null;
          let disableBtnRest = null;
          if (state.request1) {
            if (state.requestStep1) {
              disableBtnRest =
                uploadCountries.length === 0 ||
                uploadedKeys.length === 0 ||
                values.curl_request === "";
            }
          }
          if (state.request2) {
            disableBtn =
              uploadCountries.length === 0 ||
              uploadedKeys.length === 0 ||
              values.soap_request === "";
          } else if (state.request3) {
            if (state.requestStep1) {
              disableBtn =
                uploadCountries.length === 0 ||
                values.url === "" ||
                values.system_id === "" ||
                values.password === "";
            }
          }

          return (
            <Form id="addProvider">
              {state.requestStep1 && (
                <AddProviderScreen1
                  state={state}
                  setState={setState}
                  addNewRow={addNewRow}
                  handleToggle={handleToggle}
                  disableBtnRest={disableBtnRest}
                  isValid={isValid}
                  dirty={dirty}
                  setStep2={state.request3 ? setStep3 : setStep2}
                  isAdding={isAdding}
                  disableBtn={disableBtn}
                />
              )}

              {state.requestStep2 && (
                <ProviderScreen2
                  setStep1={setStep1}
                  setStep3={setStep3}
                  state={addstate}
                  setState={setAddState}
                  values={values}
                  webHookUrl={createUrlQuery(values.name)}
                />
              )}

              {state.requestStep3 && (
                <ProviderScreen3
                  state={state}
                  setStep2={state.request3 ? setStep1 : setStep2}
                  handleChange={handleChange}
                  setStep4={setStep4}
                />
              )}

              {state.requestStep4 && (
                <AddProviderScreen4
                  state={state}
                  setStep3={setStep3}
                  goBack={goBack}
                  addstate={addstate}
                  setAddState={setAddState}
                  values={values}
                  setStep4={setStep4}
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

AddProvider.propTypes = {
  addProvider: PropTypes.any,
  addSuccess: PropTypes.bool,
  addstate: PropTypes.shape({
    addHook: PropTypes.any,
  }),
  closeLoaders: PropTypes.func,
  isAdding: PropTypes.bool,
  setAddState: PropTypes.func,
};

export default AddProvider;
