import React from "react";
import { PropTypes } from "prop-types";

const Button = ({
  children,
  color,
  onClick,
  disabled,
  width,
  height,
  testId,
  type,
  background,
  marginTop,
  className,
}) => {
  return (
    <button
      data-testid={testId}
      disabled={disabled}
      onClick={onClick}
      style={{
        color: `${color}`,
        height: `${height}`,
        width: `${width}`,
        background: `${background}`,
        marginTop: `${marginTop}`,
      }}
      className={className ? className : "button"}
      type={type}
    >
      {children}
    </button>
  );
};

Button.propTypes = {
  background: PropTypes.any,
  children: PropTypes.any,
  className: PropTypes.any,
  color: PropTypes.any,
  disabled: PropTypes.any,
  formdisable: PropTypes.any,
  height: PropTypes.any,
  img: PropTypes.any,
  marginTop: PropTypes.any,
  onClick: PropTypes.func,
  testId: PropTypes.any,
  type: PropTypes.any,
  width: PropTypes.any,
};

export default Button;
