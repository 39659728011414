import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";

// ADD PRODUCT
export const addProductStart = () => ({
  type: Actions.ADD_PRODUCT_START,
});

export const addProductSuccess = (payload) => ({
  type: Actions.ADD_PRODUCT_SUCCESS,
  payload,
});

export const addProductFailure = (payload) => ({
  type: Actions.ADD_PRODUCT_FAILURE,
  payload,
});

// GET SINGLE PRODUCT
export const getSingleProductStart = () => ({
  type: Actions.GET_SINGLE_PRODUCT_START,
});

export const getSingleProductSuccess = (payload) => ({
  type: Actions.GET_SINGLE_PRODUCT_SUCCESS,
  payload,
});

export const getSingleProductFailure = (payload) => ({
  type: Actions.GET_SINGLE_PRODUCT_FAILURE,
  payload,
});

// EDIT PRODUCT
export const editProductStart = () => ({
  type: Actions.EDIT_PRODUCT_START,
});

export const editProductSuccess = (payload) => ({
  type: Actions.EDIT_PRODUCT_SUCCESS,
  payload,
});

export const editProductFailure = (payload) => ({
  type: Actions.EDIT_PRODUCT_FAILURE,
  payload,
});

// RESET PRODUCT KEY
export const resetProductKeyStart = () => ({
  type: Actions.RESET_PRODUCT_KEY_START,
});

export const resetProductKeySuccess = (payload) => ({
  type: Actions.RESET_PRODUCT_KEY_SUCCESS,
  payload,
});

export const resetProductKeyFailure = (payload) => ({
  type: Actions.RESET_PRODUCT_KEY_FAILURE,
  payload,
});

// DELETE PRODUCT
export const deleteProductStart = () => ({
  type: Actions.DELETE_PRODUCT_START,
});

export const deleteProductSuccess = (payload) => ({
  type: Actions.DELETE_PRODUCT_SUCCESS,
  payload,
});

export const deleteProductFailure = (payload) => ({
  type: Actions.DELETE_PRODUCT_FAILURE,
  payload,
});

// FETCH ALL PRODUCTS
export const fetchProductsStart = () => ({
  type: Actions.FETCH_PRODUCTS_START,
});

export const fetchProductsSuccess = (payload) => ({
  type: Actions.FETCH_PRODUCTS_SUCCESS,
  payload,
});

export const fetchProductsFailure = (payload) => ({
  type: Actions.FETCH_PRODUCTS_FAILURE,
  payload,
});

// SEARCH PRODUCTS
export const searchProductsStart = () => ({
  type: Actions.SEARCH_PRODUCTS_START,
});

export const searchProductsSuccess = (payload) => ({
  type: Actions.SEARCH_PRODUCTS_SUCCESS,
  payload,
});

export const searchProductsFailure = (payload) => ({
  type: Actions.SEARCH_PRODUCTS_FAILURE,
  payload,
});

export const addProduct = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(addProductStart());
  API.post("products", payload).then((response) => {
    const { data, ok } = response;
    responseHandler(response);
    if (ok) {
      dispatch(addProductSuccess(data && data.data));
    } else {
      dispatch(addProductFailure(data));
    }
  });
};

export const getSingleProduct = (id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(getSingleProductStart());
  API.get(`products/${id}`, payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getSingleProductSuccess(data && data.data));
    } else {
      responseHandler(response);
      dispatch(getSingleProductFailure(data));
    }
  });
};

export const editProduct = (id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(editProductStart());
  API.put(`products/${id}`, payload).then((response) => {
    const { data, ok } = response;
    responseHandler(response);
    if (ok) {
      dispatch(editProductSuccess(data && data.data));
    } else {
      dispatch(editProductFailure(data));
    }
  });
};

export const resetKey = (id, payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(resetProductKeyStart());
  API.patch(`products/api-key/${id}`, payload).then((response) => {
    const { data, ok } = response;
    responseHandler(response);
    if (ok) {
      dispatch(resetProductKeySuccess(data && data.data));
    } else {
      dispatch(resetProductKeyFailure(data));
    }
  });
};

export const deleteProduct = (id) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(deleteProductStart());
  API.delete(`products/${id}`).then((response) => {
    const { data, ok } = response;
    responseHandler(response);
    if (ok) {
      dispatch(deleteProductSuccess(data && data.data));
    } else {
      dispatch(deleteProductFailure(data));
    }
  });
};

export const fetchProducts = (page, limit) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(fetchProductsStart());
  API.get(`products?page=${page}&limit=${limit}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(fetchProductsSuccess(data && data.data));
    } else {
      responseHandler(response);
      dispatch(fetchProductsFailure(data));
    }
  });
};

export const searchProducts = (page, limit, search) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(searchProductsStart());
  API.get(`products?page=${page}&limit=${limit}&search=${search}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(searchProductsSuccess(data && data.data));
    } else {
      responseHandler(response);
      dispatch(searchProductsFailure(data));
    }
  });
};
