import PropTypes from "prop-types";
import React from "react";
import { auditColumns } from "../../components/TableData/Audit/Audit";
import Table from "../../components/Tables/Table";
import { showSingleDetails } from "../Admin/Users";

export const AuditTrail = (props) => {
  const {
    getAuditTrails,
    auditTrailsData,
    auditTrails,
    isFetchingAudit,
    getAuditTrailsSuccess,
    state,
    searchSuccess,
    isSearching,
    searchedAudit,
    searchedAuditData,
    searchAudit,
  } = props;

  const moreData = (page, perPage) => {
    state.showSearchResults
      ? searchAudit(page, perPage, state.searchValue)
      : getAuditTrails(page, perPage);
  };

  const allAudits = state.showSearchResults
    ? searchedAuditData
    : auditTrailsData;

  const totalAudit = state.showSearchResults
    ? searchedAudit && searchedAudit.total
    : auditTrails && auditTrails.total;

  return (
    <div>
      <Table
        columns={auditColumns}
        data={allAudits}
        getData={getAuditTrails}
        emptyText1={"No available logs"}
        showData={showSingleDetails}
        getMoreData={(page, perPage) => moreData(page, perPage)}
        isLoading={isFetchingAudit || isSearching}
        success={getAuditTrailsSuccess || searchSuccess}
        emptyResultText={"Audit trail not found!"}
        sortSuccess={searchSuccess}
        total={totalAudit}
      />
    </div>
  );
};

AuditTrail.propTypes = {
  auditTrails: PropTypes.shape({
    total: PropTypes.any,
  }),
  auditTrailsData: PropTypes.any,
  getAuditTrails: PropTypes.func,
  getAuditTrailsSuccess: PropTypes.any,
  isFetchingAudit: PropTypes.any,
  isSearching: PropTypes.any,
  searchAudit: PropTypes.func,
  searchSuccess: PropTypes.any,
  searchedAudit: PropTypes.shape({
    total: PropTypes.any,
  }),
  searchedAuditData: PropTypes.any,
  state: PropTypes.shape({
    searchValue: PropTypes.any,
    showSearchResults: PropTypes.any,
  }),
};

export default AuditTrail;
