import React, { useState } from "react";
import {
  ErrorBtn,
  SuccessBtn,
  DropBtn,
  PendingBtn,
} from "../../Tables/TableButtons";
import { nameAcronym } from "../../../utils/functions/acroymn";
import ReactCountryFlag from "react-country-flag";
import TableDropdown from "../../Tables/Dropdown";
import { useHistory } from "react-router";
import moment from "moment";

export const reportColumns = [
  {
    Header: "Provider Name",
    accessor: "provider_name",
    Cell: ({ row }) => (
      <div className="table-flex">
        <div className="table-flex-image">
          {nameAcronym(row.original.provider_name)}
        </div>
        <span style={{ marginLeft: "12px" }}>{row.original.provider_name}</span>
      </div>
    ),
  },
  {
    Header: "Country",
    accessor: "country",
    Cell: ({ row }) => (
      <div className="table-flex">
        <ReactCountryFlag
          countryCode={`${row.original.country_code}`}
          style={{
            width: "32px",
            height: "32px",
            borderRadius: "50%",
          }}
          svg
          title={`${row.original.country_code}`}
        />
        <span style={{ marginLeft: "12px" }}>{row.original.country}</span>
      </div>
    ),
  },
  {
    Header: "SMS Source",
    accessor: "source",
  },
  {
    Header: "Receiver's Phone No.",
    accessor: "destination",
    Cell: ({ row }) => (
      <div className="table-flex">
        <span style={{ marginLeft: "12px" }}>
          &#x2b; {row.original.destination}
        </span>
      </div>
    ),
  },
  {
    Header: "Status",
    accessor: "status",

    Cell: ({ row }) =>
      row.original.status === "delivered" ||
      row.original.status === "success" ? (
        <SuccessBtn text={`${row.original.status}`} />
      ) : row.original.status === "failed" ||
        row.original.status === "undelivered" ? (
        <ErrorBtn text={`${row.original.status}`} />
      ) : row.original.status === "pending" ? (
        <PendingBtn text={`${row.original.status}`} />
      ) : (
        "Nil"
      ),
  },

  {
    Header: "Date Added",
    accessor: "date",
    Cell: ({ row }) => {
      const [show, setShow] = useState(false);

      const history = useHistory();

      const goToSingleReport = () => {
        const id = row.original && row.original.id;
        history.push({
          pathname: `/reporting/${id}`,
          state: {
            id: id,
            data: row.original,
          },
        });
      };
      const options = [
        {
          name: "View",
          action: () => goToSingleReport(),
        },
      ];

      return (
        <div className="table-flex">
          <span className="table-flex-date">
            {moment(row.original.created_at).format("LL")} |{" "}
            <small>{moment(row.original.created_at).format("LT")}</small>
          </span>
          <DropBtn
            marginLeft={"16px"}
            onClick={() => setShow((prevCheck) => !prevCheck)}
          />

          {show && <TableDropdown options={options} />}
        </div>
      );
    },
  },
];
