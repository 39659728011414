import PropTypes from "prop-types";
import React, { useState } from "react";
import { ModalButton } from "../Modal/Modal";
import ReactFlagsSelect from "react-flags-select";
import { Form, Formik, Field } from "formik";
import Label from "../Input/Label";
import Input from "../Input/Input";
import { Statuses } from "../Input/Statuses";
import DateFilter from "../Input/DateFilter";
import moment from "moment";
import { countries } from "country-data";

const FilterData = ({ options, submitValues, isFiltering, setDisable }) => {
  const [selected, setSelected] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  const [endDate, setEndDate] = useState(new Date());

  const currentDate = moment && moment(new Date()).format("YYYY-MM-DD");

  const initialValues = {
    start_date: "",
    end_date: "",
    name: "",
    country_code: "",
    status: "",
  };

  const [state, setstate] = useState({
    active: false,
    status: "",
    index: "",
    start_date: "",
    end_date: "",
  });

  const setState1 = (date) => {
    setStartDate(date);
    setstate({
      ...state,
      start_date: moment(date).format("YYYY-MM-DD"),
    });
  };

  const setState2 = (date) => {
    setEndDate(date);
    setstate({
      ...state,
      end_date: moment(date).format("YYYY-MM-DD"),
    });
  };

  const filterApplication = (values) => {
    values.start_date =
      state.start_date !== "" ? state.start_date : currentDate;
    values.end_date = state.end_date !== "" ? state.start_date : currentDate;
    values.country_code = selected;
    values.status = state.status;

    const payload = {
      start_date: values.start_date,
      end_date: values.end_date,
      name: values.name,
      country:
        countries &&
        countries[values.country_code] &&
        countries[values.country_code].name,
      status: values.status,
    };
    submitValues(payload);
  };

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={filterApplication}>
        {({ values, isValid, dirty }) => {
          return (
            <Form id="form">
              <Field
                name="name"
                label="Name"
                placeholder="Provider Name"
                component={Input}
              />
              <div className="form-grid">
                <Field name="country_code">
                  {() => (
                    <Label label="Provider Country">
                      <ReactFlagsSelect
                        selected={selected}
                        onSelect={(code) => setSelected(code)}
                        searchable={true}
                        placeholder="Select Country"
                        id={"filter-provider-country"}
                      />
                    </Label>
                  )}
                </Field>
              </div>

              <div className="form-grid2">
                <Field name="start_date">
                  {() => (
                    <DateFilter
                      label="Start Date"
                      placeholder="Choose Start Date"
                      setDate={setStartDate}
                      onChange={setState1}
                      fixedDate={startDate}
                    />
                  )}
                </Field>

                <Field name="end_date">
                  {() => (
                    <DateFilter
                      label="End Date"
                      placeholder="Choose End Date"
                      setDate={setEndDate}
                      onChange={setState2}
                      fixedDate={endDate}
                    />
                  )}
                </Field>
              </div>
              <Statuses state={state} setstate={setstate} options={options} />

              <div id="filter-btn">
                <ModalButton
                  text={"Apply Search"}
                  disabled={isFiltering}
                  isLoading={isFiltering}
                  loadingText={"Filtering..."}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

FilterData.propTypes = {
  isFiltering: PropTypes.bool,
  options: PropTypes.any,
  setDisable: PropTypes.any,
  submitValues: PropTypes.func,
};

export default FilterData;
