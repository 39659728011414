import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";
import { jwtDecode } from "jwt-decode";
import { clearStorage } from "../../routes/PrivateRoute";

// SET CURRENT USER
export const setCurrentUser = () => ({
  type: Actions.SET_CURRENT_USER,
});

// LOG IN USER
export const userLoginStart = () => ({
  type: Actions.USER_LOGIN_START,
});

export const userLoginSuccess = (payload) => ({
  type: Actions.USER_LOGIN_SUCCESS,
  payload,
});

export const userLoginFailure = (payload) => ({
  type: Actions.USER_LOGIN_FAILURE,
  payload,
});

// VERIFY USER INVITE
export const verifyUserStart = () => ({
  type: Actions.VERIFY_INVITE_START,
});

export const verifyUserSuccess = (payload) => ({
  type: Actions.VERIFY_INVITE_SUCCESS,
  payload,
});

export const verifyUserFailure = (payload) => ({
  type: Actions.VERIFY_INVITE_FAILURE,
  payload,
});

// RESEND USER INVITE CODE
export const resendUserInviteStart = () => ({
  type: Actions.RESEND_USER_INVITE_START,
});

export const resendUserInviteSuccess = (payload) => ({
  type: Actions.RESEND_USER_INVITE_SUCCESS,
  payload,
});

export const resendUserInviteFailure = (payload) => ({
  type: Actions.RESEND_USER_INVITE_FAILURE,
  payload,
});

// LOG OUT CURRENT USER
export const logOutStart = () => ({
  type: Actions.USER_LOG_OUT_START,
});

export const logOutSuccess = (payload) => ({
  type: Actions.USER_LOG_OUT_SUCCESS,
  payload,
});

export const logOutFailure = (payload) => ({
  type: Actions.USER_LOG_OUT_FAILURE,
  payload,
});

// AUTHENTICATION
export const authenticationStart = () => ({
  type: Actions.AUTHENTICATION_START,
});

export const authenticationSuccess = (payload) => ({
  type: Actions.AUTHENTICATION_SUCCESS,
  payload,
});

export const authenticationFailure = (payload) => ({
  type: Actions.AUTHENTICATION_FAILURE,
  payload,
});

// RESEND AUTHENTICATION
export const resendAuthenticationStart = () => ({
  type: Actions.RESEND_AUTHENTICATION_START,
});

export const resendAuthenticationSuccess = (payload) => ({
  type: Actions.RESEND_AUTHENTICATION_SUCCESS,
  payload,
});

export const resendAuthenticationFailure = (payload) => ({
  type: Actions.RESEND_AUTHENTICATION_FAILURE,
  payload,
});

export const clearAuthenticationState = () => ({
  type: Actions.CLEAR_AUTHENTICATION_STATE,
});

export const userLogin = (payload, history) => async (dispatch) => {
  const API = Instance.customAxiosInstance();

  dispatch(userLoginStart());
  API.post("users/login", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(userLoginSuccess(data && data.data));
      let adminAuth = data && data.data && data.data.twoFactorAuth;
      if (adminAuth === true) {
        history.push("/verify");
      } else {
        responseHandler(response);
        Storage.set("user-access-token", data && data.data && data.data.token);
        const decoded = jwtDecode(data && data.data && data.data.token);
        Storage.set("decoded-data", JSON.stringify(decoded));
        history.push("/dashboard");
      }
    } else {
      responseHandler(response);
      dispatch(userLoginFailure(data));
    }
  });
};

export const verifyInvite = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance();

  dispatch(verifyUserStart());
  API.post("users/verify-account", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(verifyUserSuccess(data && data.data));
    } else {
      responseHandler(response);
      dispatch(verifyUserFailure(data));
    }
  });
};

export const resendUserInvite = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance();

  dispatch(resendUserInviteStart());
  API.post("users/resend-invite-code", payload).then((response) => {
    const { data, ok } = response;
    responseHandler(response);
    if (ok) {
      dispatch(resendUserInviteSuccess(data && data.data));
    } else {
      dispatch(resendUserInviteFailure(data));
    }
  });
};

export const userLogout = () => (dispatch) => {
  try {
    dispatch(logOutStart());
    clearStorage();
    dispatch(logOutSuccess());
  } catch (error) {
    dispatch(logOutFailure(error));
  }
};

export const authenticateUser = (payload, history) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(authenticationStart());
  API.post("users/verify-two-factor", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(authenticationSuccess(data.data));
      Storage.set("user-access-token", data.data.token);
      const decoded = jwtDecode(data.data.token);
      Storage.set("decoded-data", JSON.stringify(decoded));
      history.push("/dashboard");
    } else {
      responseHandler(response);
      dispatch(authenticationFailure(data));
    }
  });
};

export const resendAuthenticateUser = (payload) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));
  dispatch(resendAuthenticationStart());
  API.post("users/resend-two-factor", payload).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(resendAuthenticationSuccess(data.data));
    } else {
      responseHandler(response);
      dispatch(resendAuthenticationFailure(data));
    }
  });
};
