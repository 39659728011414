import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { ModalButton } from "../Modal/Modal";
import Input from "../Input/Input";
import { ToggleButton } from "../Input/ToggleButton";
import { Field, Form, Formik } from "formik";
import { connect } from "react-redux";
import { editProduct } from "../../redux/Actions/ProductAction";

const EditProduct = (props) => {
  const { data, isEditing, editProduct, editSuccess } = props;

  const [state, setState] = useState({
    checked: data.enabled,
  });

  const handleToggle = () => {
    setState({
      ...state,
      checked: (state.checked = !state.checked),
    });
  };

  useEffect(
    () => {
      if (editSuccess) {
        props.closeModal();
      }
    },
    // eslint-disable-next-line
    [editSuccess]
  );

  const initialValues = {
    name: data.name,
    limit: data.sms_rate_limit,
  };

  const id = data && data.id;

  const handleEdit = (values) => {
    const payload = {
      smsRateLimit: values.limit,
      enabled: state.checked,
    };
    editProduct(id, payload);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleEdit(values)}
      >
        {({ values, isValid, dirty }) => {
          return (
            <Form>
              <Field
                name="name"
                label="Product Name"
                placeholder="Product Name"
                component={Input}
                readOnly={true}
              />

              <div className="edit-email">
                <Field
                  name="limit"
                  label="SMS Limit"
                  placeholder="SMS Limit"
                  component={Input}
                />
                <span className="infinite-limit">
                  Input -1 for infinite limit
                </span>
              </div>

              <ToggleButton
                text={`Toggle to ${
                  state.checked ? "deactivate" : "activate"
                } product`}
                handleChange={handleToggle}
                checked={state.checked}
                id={"edit-info-toggle"}
              />

              <ModalButton
                text={"Save Changes"}
                loadingText={"Saving Changes"}
                isLoading={isEditing}
                disabled={isEditing}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

EditProduct.propTypes = {
  closeModal: PropTypes.func,
  data: PropTypes.shape({
    enabled: PropTypes.any,
    id: PropTypes.any,
    name: PropTypes.any,
    sms_rate_limit: PropTypes.any,
  }),
  editProduct: PropTypes.func,
  editSuccess: PropTypes.bool,
  isEditing: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isEditing: state.product.isEditingProduct,
  editSuccess: state.product.editProductSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  editProduct: (id, payload) => dispatch(editProduct(id, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct);
