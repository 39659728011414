import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";

// GET DASHBOARD METRICS
export const getDashboardMetricsStart = () => ({
  type: Actions.GET_DASHBOARD_METRICS_START,
});

export const getDashboardMetricsSuccess = (payload) => ({
  type: Actions.GET_DASHBOARD_METRICS_SUCCESS,
  payload,
});

export const getDashboardMetricsFailure = (payload) => ({
  type: Actions.GET_DASHBOARD_METRICS_FAILURE,
  payload,
});

// GET GRAPH METRICS
export const getGraphMetricsStart = () => ({
  type: Actions.GET_GRAPH_METRICS_START,
});

export const getGraphMetricsSuccess = (payload) => ({
  type: Actions.GET_GRAPH_METRICS_SUCCESS,
  payload,
});

export const getGraphMetricsFailure = (payload) => ({
  type: Actions.GET_GRAPH_METRICS_FAILURE,
  payload,
});

export const getDashboardMetrics = () => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(getDashboardMetricsStart());
  API.get("users/metrics").then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getDashboardMetricsSuccess(data && data.data));
    } else {
      dispatch(getDashboardMetricsFailure(data));
      responseHandler(response);
    }
  });
};

export const getGraphMetrics = (duration) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(getGraphMetricsStart());
  API.get(`users/graph-metrics?duration=${duration}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getGraphMetricsSuccess(data && data.data));
    } else {
      dispatch(getGraphMetricsFailure(data));
      responseHandler(response);
    }
  });
};
