import Actions from "../Types"

const initialState = {
    isGettingAll: false,
    getAllSuccess: false,
    ranks: [],
    ranksData: {},
    isSearching: false,
    searchSuccess: false,
    searchedRanks: [],
    searchedRanksData: {},
    isGettingList: false,
    getListSuccess: false,
    list: [],
    isEditing: false,
    editSuccess: false,
    data: {},
    error: null,
}

const reduxState = (state = initialState, action) => {
    switch (action.type) {
        case Actions.GET_ALL_RANKS_START:
            return {
                ...state,
                isGettingAll: true,
                getAllSuccess: false,
                ranks: [],
                ranksData: {},
                error: null
            }
        case Actions.GET_ALL_RANKS_SUCCESS:
            return {
                ...state,
                isGettingAll: false,
                getAllSuccess: true,
                ranks: action.payload && action.payload.data,
                ranksData: action.payload,
                error: null,
            }
        case Actions.GET_ALL_RANKS_FAILURE:
            return {
                ...state,
                isGettingAll: false,
                getAllSuccess: false,
                ranks: [],
                ranksData: {},
                error: action.payload
            }
        case Actions.SEARCH_RANKS_START:
            return {
                ...state,
                isSearching: true,
                searchSuccess: false,
                searchedRanks: [],
                searchedRanksData: {},
                error: null
            }
        case Actions.SEARCH_RANKS_SUCCESS:
            return {
                ...state,
                isSearching: false,
                searchSuccess: true,
                searchedRanks: action.payload && action.payload.data,
                searchedRanksData: action.payload,
                error: null,
            }
        case Actions.SEARCH_RANKS_FAILURE:
            return {
                ...state,
                isSearching: false,
                searchSuccess: false,
                searchedRanks: [],
                searchedRanksData: {},
                error: action.payload
            }
        case Actions.GET_RANKS_LIST_START:
            return {
                ...state,
                isGettingList: true,
                getListSuccess: false,
                list: [],
                error: null
            }
        case Actions.GET_RANKS_LIST_SUCCESS:
            return {
                ...state,
                isGettingList: false,
                getListSuccess: true,
                list: action.payload,
                error: null,
            }
        case Actions.GET_RANKS_LIST_FAILURE:
            return {
                ...state,
                isGettingList: false,
                getListSuccess: false,
                list: [],
            }
        case Actions.EDIT_RANKS_START:
            return {
                ...state,
                isEditing: true,
                editSuccess: false,
                data: {},
                error: null
            }
        case Actions.EDIT_RANKS_SUCCESS:
            return {
                ...state,
                isEditing: false,
                editSuccess: true,
                data: action.payload,
                error: null,
            }
        case Actions.EDIT_RANKS_FAILURE:
            return {
                ...state,
                isEditing: false,
                editSuccess: false,
                data: {},
                error: action.payload
            }
        default:
            return state;
    }
}

export default reduxState;