import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { LoaderSpin } from "../../components/Loader/Loader";
import {
  authenticateUser,
  clearAuthenticationState,
  resendAuthenticateUser,
} from "../../redux/Actions/AuthActions";
import Alert from "../../utils/notifications/Alert";

const AuthenticateForm = (props) => {
  const {
    authenticateUser,
    resendAuthentication,
    clearAuthenticationState,
    isAuthenticating,
    authenticateSuccess,
    isResending,
    resendingSucess,
    emailAddress,
  } = props;

  const history = useHistory();

  const [inputValue, setInputValue] = useState("");
  const [over, setOver] = React.useState(false);
  const [[m, s], setTime] = React.useState([5, 0]);

  const tick = () => {
    if (over) return;
    if (m === 0 && s === 0) {
      setOver(true);
    } else if (s === 0) {
      setTime([m - 1, 59]);
    } else {
      setTime([m, s - 1]);
    }
  };

  const reset = () => {
    setTime([parseInt(5), parseInt(0)]);
    setOver(false);
  };

  const [state, setState] = useState({
    value1: "",
    value2: "",
    value3: "",
    value4: "",
    value5: "",
    value6: "",
  });

  useEffect(
    () => {
      setInputValue(Object.values(state).join(""));
      const timerID = setInterval(() => tick(), 1000);
      return () => clearInterval(timerID);
    },
    // eslint-disable-next-line
    [tick]
  );

  useEffect(
    () => {
      if (authenticateSuccess) {
        setState({
          ...state,
          inputValue: "",
        });
        clearAuthenticationState();
      }
    },
    // eslint-disable-next-line
    [authenticateSuccess]
  );

  useEffect(
    () => {
      if (resendingSucess) {
        Alert("Authentication successfully sent");
        reset();
      }
    },
    // eslint-disable-next-line
    [resendingSucess]
  );

  const updateInputValue = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const moveOnMax = (field, nextId) => {
    if (field.length >= 1) {
      document.getElementById(nextId).focus();
    }
  };

  const sendVerify = () => {
    const codeNo = {
      code: inputValue,
    };
    authenticateUser(codeNo, history);
  };

  const resendVerify = () => {
    const email = {
      email: emailAddress,
    };
    resendAuthentication(email);
  };

  // const checkKey = (e) => {
  //   if (e.key === "Enter") {
  //     sendVerify();
  //   }
  // };

  return (
    <div>
      <div className="verification-wrap">
        <input
          className="verification-wrap-input"
          type="number"
          pattern="[0-9]"
          maxLength="1"
          name="value1"
          value={state.value1}
          id="one"
          onChange={updateInputValue}
          onKeyUp={() => moveOnMax(state.value1, "two")}
        />

        <input
          className="verification-wrap-input"
          type="number"
          pattern="[0-9]"
          maxLength="1"
          name="value2"
          value={state.value2}
          onChange={updateInputValue}
          id="two"
          onKeyUp={() => moveOnMax(state.value2, "three")}
        />

        <input
          className="verification-wrap-input"
          type="number"
          pattern="/[0-9]/"
          maxLength="1"
          name="value3"
          value={state.value3}
          id="three"
          onChange={updateInputValue}
          onKeyUp={() => moveOnMax(state.value3, "four")}
        />

        <input
          className="verification-wrap-input"
          type="number"
          pattern="/^-?\d*$/"
          maxLength="1"
          name="value4"
          value={state.value4}
          onChange={updateInputValue}
          id="four"
          onKeyUp={() => moveOnMax(state.value4, "five")}
        />

        <input
          className="verification-wrap-input"
          type="number"
          pattern="/^-?\d*$/"
          maxLength="1"
          name="value5"
          value={state.value5}
          id="five"
          onChange={updateInputValue}
          onKeyUp={() => moveOnMax(state.value5, "six")}
        />

        <input
          className="verification-wrap-input"
          type="number"
          pattern="/^-?\d*$/"
          maxLength="1"
          name="value6"
          value={state.value6}
          id="six"
          onChange={updateInputValue}
          onKeyUp={() => sendVerify()}
          // onKeyDown={checkKey}
        />
      </div>
      {isAuthenticating ? (
        <div className="centre-loader">
          <LoaderSpin width="40px" height="40px" />
        </div>
      ) : (
        <div>
          <div className="verification-confirmation">
            Didn’t get the code? &nbsp;
            {isResending ? (
              <span className="verification-confirmation-resend">
                Resending...
              </span>
            ) : (
              <span
                className="verification-confirmation-resend"
                onClick={resendVerify}
              >
                Resend
              </span>
            )}
          </div>
          {over ? (
            <div className="verification-time time-info">
              Code expired, click resend to get a new code
            </div>
          ) : (
            <div className="verification-time">
              {`${m.toString().padStart(2, "0")}:${s
                .toString()
                .padStart(2, "0")}`}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

AuthenticateForm.propTypes = {
  authenticateSuccess: PropTypes.bool,
  authenticateUser: PropTypes.func,
  clearAuthenticationState: PropTypes.func,
  emailAddress: PropTypes.any,
  isAuthenticating: PropTypes.bool,
  isResending: PropTypes.bool,
  resendAuthentication: PropTypes.func,
  resendingSucess: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    isAuthenticating: state.auth.isAuthenticating,
    authenticateSuccess: state.auth.authenticateSuccess,
    authenticationStatus: state.auth.authenticationStatus,
    isResending: state.auth.isResending,
    resendingSucess: state.auth.resendingSucess,
    reSendingStatus: state.auth.reSendingStatus,
  };
};

const mapDispatchToProps = (dispatch) => ({
  authenticateUser: (payload, history) =>
    dispatch(authenticateUser(payload, history)),
  resendAuthentication: (payload) => dispatch(resendAuthenticateUser(payload)),
  clearAuthenticationState: () => dispatch(clearAuthenticationState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticateForm);
