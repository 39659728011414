import PropTypes from "prop-types";
import React from "react";
import { Chart } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import {
  assignDonutData,
  assignDonutLabels,
  assignMetricsData,
} from "../../utils/functions/assignValues";
import { abbreviate } from "@pqt/abbreviate";

donutPlugin();

const Donut = (props) => {
  const { metrics } = props;

  const topProviders =
    metrics && metrics.providerInfo && metrics.providerInfo.providers;

  const topProvidersTotal =
    metrics && metrics.providerInfo && abbreviate(metrics.providerInfo.total);
  const arryLength = topProviders && topProviders.length;

  let lists = [];

  const providers = topProviders && assignMetricsData(topProviders, lists);

  const data = {
    labels: assignDonutLabels(topProviders),
    datasets: [
      {
        data: assignDonutData(topProviders),
        backgroundColor: [
          "#B9CFF9",
          "#5B8EF0",
          "#2C6EEC",
          "#0F42A4",
          "#0E347E",
        ],
      },
    ],
    text: topProvidersTotal,
  };

  const options = {
    legend: {
      display: false,
      position: "right",
    },
  };

  return (
    <section className="sms-details">
      <div className="title-wrap">
        <div className="chart-wrap2-title top">
          Top {arryLength === 1 ? "" : arryLength}
          {""} SMS Provider{arryLength === 1 ? "" : "s"}
        </div>
      </div>

      <div className="chart-wrap2-box">
        <div className="donut-box">
          <Doughnut data={data} height={195} options={options} />
        </div>
        <div className="provider-list-box">
          <ul className="provider-list">
            {providers &&
              providers.map((provider) => (
                <li className="provider-item" key={provider.name}>
                  <div className="provider-name">
                    <div
                      className="dot"
                      style={{ backgroundColor: provider.color }}
                    ></div>{" "}
                    {provider.name}
                  </div>
                  <div className="provider-count">
                    <div className="count">
                      {provider && abbreviate(provider.count)}
                    </div>
                    <div className="percent">({provider.percent} %)</div>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

Donut.propTypes = {
  metrics: PropTypes.shape({
    providerInfo: PropTypes.shape({
      providers: PropTypes.any,
      total: PropTypes.any,
    }),
  }),
};

export default Donut;

function donutPlugin() {
  /*
  var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
  Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
    draw: function () {
      originalDoughnutDraw.apply(this, arguments);

      var chart = this.chart.chart;
      var ctx = chart.ctx;
      var width = chart.width;
      var height = chart.height;

      var fontSize = (height / 114).toFixed(2);
      ctx.font = fontSize + "em Verdana";
      ctx.textBaseline = "middle";

      var text = chart.config.data.text,
        textX = Math.round((width - ctx.measureText(text).width) / 2),
        textY = height / 2;

      ctx.fillText(text, textX, textY);
    },
  });
  */
}
