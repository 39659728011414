import Actions from "../Types";

const initialState = {
  isFetchingLogs: false,
  getLoginTrailsSuccess: false,
  loginTrailsData: [],

  isFetchingAudit: false,
  getAuditTrailsSuccess: false,
  auditTrailsData: [],

  isSearching: false,
  searchSuccess: false,
  searchedAuditData: [],
  searchedAudit: {},

  isSearchingLogin: false,
  searchLoginSuccess: false,
  searchedLoginData: {},
  searchedLogin: [],

  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_LOGIN_TRAILS_START:
      return {
        ...state,
        isFetchingLogs: true,
        getLoginTrailsSuccess: false,
        loginTrailsData: [],
        error: null,
      };
    case Actions.GET_LOGIN_TRAILS_SUCCESS:
      return {
        ...state,
        isFetchingLogs: false,
        getLoginTrailsSuccess: true,
        loginTrails: action.payload,
        loginTrailsData: action.payload && action.payload.data,
        error: null,
      };
    case Actions.GET_LOGIN_TRAILS_FAILURE:
      return {
        ...state,
        isFetchingLogs: false,
        getLoginTrailsSuccess: false,
        loginTrailsData: [],
        error: action.payload,
      };
    case Actions.GET_AUDIT_TRAILS_START:
      return {
        ...state,
        isFetchingAudit: true,
        getAuditTrailsSuccess: false,
        auditTrailsData: [],
        error: null,
      };
    case Actions.GET_AUDIT_TRAILS_SUCCESS:
      return {
        ...state,
        isFetchingAudit: false,
        getAuditTrailsSuccess: true,
        auditTrails: action.payload,
        auditTrailsData: action.payload && action.payload.data,
        error: null,
      };
    case Actions.GET_AUDIT_TRAILS_FAILURE:
      return {
        ...state,
        isFetchingAudit: false,
        getAuditTrailsSuccess: false,
        auditTrailsData: [],
        error: action.payload,
      };
    case Actions.SEARCH_AUDIT_TRAILS_START:
      return {
        ...state,
        isSearching: true,
        searchSuccess: false,
        searchedAuditData: {},
        searchedAudit: [],
        error: null,
      };
    case Actions.SEARCH_AUDIT_TRAILS_SUCCESS:
      return {
        ...state,
        isSearching: false,
        searchSuccess: true,
        searchedAuditData: action.payload && action.payload.data,
        searchedAudit: action.payload,
        error: null,
      };
    case Actions.SEARCH_AUDIT_TRAILS_FAILURE:
      return {
        ...state,
        isSearching: false,
        searchSuccess: false,
        searchedAuditData: {},
        searchedAudit: [],
        error: action.payload,
      };
    case Actions.SEARCH_LOGIN_TRAILS_START:
      return {
        ...state,
        isSearchingLogin: true,
        searchLoginSuccess: false,
        searchedLoginData: {},
        searchedLogin: [],
        error: null,
      };
    case Actions.SEARCH_LOGIN_TRAILS_SUCCESS:
      return {
        ...state,
        isSearchingLogin: false,
        searchLoginSuccess: true,
        searchedLoginData: action.payload && action.payload.data,
        searchedLogin: action.payload,
        error: null,
      };
    case Actions.SEARCH_LOGIN_TRAILS_FAILURE:
      return {
        ...state,
        isSearchingLogin: false,
        searchLoginSuccess: false,
        searchedLoginData: {},
        searchedLogin: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reduxState;
