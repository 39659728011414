import PropTypes from "prop-types";
import React from "react";
import Loader from "../../assets/loading.gif";

export const Loading = ({ text }) => {
  return (
    <div className="invite-loader">
      <div className="invite-loader-image">
        <img src={Loader} alt="loader" />
      </div>

      <div>{text}</div>
    </div>
  );
};

Loading.propTypes = {
  text: PropTypes.any,
};
