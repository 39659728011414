import React from "react";
import PasswordWrap from "../../components/Wrappers/PasswordWrap";

const CreateAccount = () => {
  return (
    <PasswordWrap
      title1="Create Account"
      title2="Create a unique password to proceed"
      submitValues={""}
      type="Create Account"
    />
  );
};
export default CreateAccount;
