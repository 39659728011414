import Actions from "../Types"

const initialState = {
  isGettingRequests: false,
  getRequestsSuccess: false,
  isAuthorisingRequest: false,
  authoriseRequestSuccess: false,
  data: [],
  error: null,
}

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_REQUESTS_START:
      return {
        ...state,
        isGettingRequests: true,
        getRequestsSuccess: false,
        data: [],
        error: null
      }
    case Actions.GET_REQUESTS_SUCCESS:
      return {
        ...state,
        isGettingRequests: false,
        getRequestsSuccess: true,
        data: action.payload,
        error: null,
      }
    case Actions.GET_REQUESTS_FAILURE:
      return {
        ...state,
        isGettingRequests: false,
        getRequestsSuccess: false,
        data: [],
        error: action.payload
      }
    case Actions.AUTHORISE_REQUEST_START:
      return {
        ...state,
        isAuthorisingRequest: true,
        authoriseRequestSuccess: false,
        error: null
      }
    case Actions.AUTHORISE_REQUEST_SUCCESS:
      return {
        ...state,
        isAuthorisingRequest: false,
        authoriseRequestSuccess: true,
        error: null,
      }
    case Actions.AUTHORISE_REQUEST_FAILURE:
      return {
        ...state,
        isAuthorisingRequest: false,
        authoriseRequestSuccess: false,
        error: action.payload
      }
    default:
      return state;
  }
}

export default reduxState;