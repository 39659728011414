import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import Back from "../../assets/back.svg";
import { cancelModal, viewModal } from "./SMSProviders";
import Modal from "../../components/Modal/Modal";
import EditProvider from "../../components/Provider/EditProvider";
import { connect } from "react-redux";
import {
  editProvider,
  getSingleProvider,
} from "../../redux/Actions/ProviderActions";
import { LoaderSpin } from "../../components/Loader/Loader";
import { breakWords } from "../../utils/functions/spiltNames";
import SingleDetails from "./SingleDetails";

export const SingleProvider = (props) => {
  const {
    isGetting,
    provider,
    getSuccess,
    getProvider,
    isEditing,
    editSuccess,
    editProvider,
  } = props;
  const data = props.location.state && props.location.state.data;
  const id = data && data.id;
  const type = data && data.request_type;

  const [state, setState] = useState({
    editModal: false,
    id: "",
    checked: false,
    request: "",
    selectedCountries: "",
    addedKeys: "",
    url: "",
    system_id: "",
    password: "",
    requestStep1: true,
    requestStep2: false,
    requestStep3: false,
    requestStep4: false,
    delivered: [],
    success: [],
    undelivered: [],
    pending: [],
    failed: [],
    showLoaders: false,
    showExample: false,
    addHook: false,
    noHook: false,
  });

  useEffect(
    () => {
      getProvider(id, type, state, setState);
    },
    // eslint-disable-next-line
    []
  );

  const keys_and_values = `"Keys & Values": 
  ${
    provider &&
    provider.variable_default &&
    breakWords(provider.variable_default)
  }`;

  const closeLoaders = () => {
    setState({
      ...state,
      editModal: false,
      requestStep1: true,
      requestStep2: false,
      requestStep3: false,
      requestStep4: false,
      showLoaders: false,
    });
  };

  useEffect(
    () => {
      if (editSuccess) {
        document.getElementById("editProvider").reset();
        closeLoaders();
      }
    },
    // eslint-disable-next-line
    [editSuccess]
  );

  const statuses = provider && provider.responseMapping;

  return (
    <div className="single-provider">
      <HeaderWrapper
        title={"SMS Providers Details"}
        back={Back}
        route={"/providers"}
      >
        {getSuccess && (
          <button
            onClick={() => viewModal("editModal", state, setState)}
            className="single-provider-button"
          >
            Edit
          </button>
        )}
      </HeaderWrapper>

      {isGetting ? (
        <LoaderSpin width="40px" height="40px" />
      ) : getSuccess ? (
        <SingleDetails
          data={data}
          type={type}
          provider={provider}
          statuses={statuses}
          keys_and_values={keys_and_values}
        />
      ) : (
        <div className="single-provider-empty">
          Error fetching provider details
        </div>
      )}

      <Modal
        displayModal={state.editModal}
        closeModal={() => cancelModal("editModal", state, setState)}
        title={state.showExample ? "Example" : "Edit SMS Provider"}
        width={"574px"}
        show={state.showLoaders}
      >
        <EditProvider
          data={provider}
          state={state}
          setState={setState}
          isEditing={isEditing}
          editProvider={editProvider}
        />
      </Modal>
    </div>
  );
};

SingleProvider.propTypes = {
  editProvider: PropTypes.func,
  editSuccess: PropTypes.bool,
  getProvider: PropTypes.func,
  getSuccess: PropTypes.bool,
  isEditing: PropTypes.bool,
  isGetting: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      data: PropTypes.any,
    }),
  }),
  provider: PropTypes.shape({
    responseMapping: PropTypes.any,
    variable_default: PropTypes.any,
  }),
};

const mapStateToProps = (state) => ({
  isGetting: state.provider.isGettingSingle,
  getSuccess: state.provider.getSingleSuccess,
  provider: state.provider.provider,
  isEditing: state.provider.isEditing,
  editSuccess: state.provider.editSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getProvider: (id, type, state, setState) =>
    dispatch(getSingleProvider(id, type, state, setState)),
  editProvider: (type, id, payload) =>
    dispatch(editProvider(type, id, payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleProvider);
