import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { nameAcronym } from "../../utils/functions/acroymn";
import { ModalButton } from "../Modal/Modal";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { LoaderSpin } from "../Loader/Loader";
import { assignRankingData } from "../../utils/functions/assignValues";

const RankingData = (props) => {
  const {
    data,
    getRankingData,
    isGetting,
    getSuccess,
    editRanks,
    isEditing,
    closeModal,
  } = props;

  const [characters, updateCharacters] = useState("");

  useEffect(
    () => {
      getRankingData(data && data.country_code, updateCharacters);
    },
    // eslint-disable-next-line
    []
  );

  const checkDefaultProvider =
    characters && characters.filter((item) => item.rank === "1");
  const defaultProvider =
    checkDefaultProvider &&
    checkDefaultProvider[0] &&
    checkDefaultProvider[0].name;

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(characters);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    updateCharacters(items);
  };

  const submitRanking = () => {
    const newRanks = [];
    assignRankingData(characters, newRanks);
    const payload = { ranking: newRanks };
    editRanks(payload, closeModal);
  };

  return (
    <div className="ranking">
      <div className="ranking-header">
        <div className="ranking-header-sec">
          <p>Country</p>
          <div className="ranking-header-sec-each">
            <ReactCountryFlag
              countryCode={`${data.country_code}`}
              style={{
                width: "24px",
                height: "24px",
                borderRadius: "50%",
              }}
              svg
              title={`${data.country_code}`}
            />
            <span>{data.country}</span>
          </div>
        </div>

        {getSuccess && (
          <div className="ranking-header-sec">
            <p>Default Provider</p>
            <div className="ranking-header-sec-each">
              <div className="ranking-header-sec-each-image">
                {nameAcronym(defaultProvider)}
              </div>
              <span>{defaultProvider}</span>
            </div>
          </div>
        )}
      </div>

      {isGetting ? (
        <div className="loader-table">
          <LoaderSpin width="40px" height="40px" />
        </div>
      ) : getSuccess ? (
        <div className="ranking-lists">
          <p className="ranking-lists-header">List and Ranking</p>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="ranking-lists-ranks">
              {(provided) => (
                <div
                  className="ranking-lists-ranks"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {characters &&
                    characters.map((list, index) => (
                      <Draggable
                        key={list.name}
                        draggableId={list.name}
                        index={index}
                      >
                        {(provided) => (
                          <div
                            className="ranking-lists-ranks-single"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <span>{index + 1}</span>
                            <div className="ranking-lists-ranks-single-box">
                              <div className="ranking-lists-ranks-single-box-left">
                                <div className="ranking-lists-ranks-single-box-left-image">
                                  {nameAcronym(list.name)}
                                </div>
                                <span>{list.name}</span>
                              </div>
                              {index === 0 && (
                                <div className="ranking-lists-ranks-single-box-right"></div>
                              )}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>

          <ModalButton
            text={"Save"}
            onClick={submitRanking}
            disabled={isEditing}
            isLoading={isEditing}
            loadingText={"Saving Changes"}
          />
        </div>
      ) : (
        <div className="single-provider-empty">Error fetching lists</div>
      )}
    </div>
  );
};

RankingData.propTypes = {
  closeModal: PropTypes.any,
  data: PropTypes.shape({
    country: PropTypes.any,
    country_code: PropTypes.any,
  }),
  editRanks: PropTypes.func,
  getRankingData: PropTypes.func,
  getSuccess: PropTypes.bool,
  isEditing: PropTypes.bool,
  isGetting: PropTypes.bool,
};

export default RankingData;
