import PropTypes from "prop-types";
import React from "react";
import Err from "../../assets/btn-error.svg";
import Success from "../../assets/btn-success.svg";
import Pending from "../../assets/btn-info.svg";
import OptionIcon from "../../assets/options.svg";

export const SuccessBtn = (props) => {
  return (
    <div className={props.className ? props.className : "success-btn"}>
      <div
        className={
          props.className ? `${props.className}-image` : "success-btn-image"
        }
      >
        <div className="success-btn-image-div">
          <img src={Success} alt="success-btn" />
        </div>
      </div>
      <span style={{ textTransform: "capitalize" }}>{props.text}</span>
    </div>
  );
};

SuccessBtn.propTypes = {
  className: PropTypes.any,
  text: PropTypes.any,
};

export const ErrorBtn = (props) => {
  return (
    <div className={props.className ? props.className : "error-btn"}>
      <div
        className={
          props.className ? `${props.className}-image` : "error-btn-image"
        }
      >
        <div className="error-btn-image-div">
          <img src={Err} alt="err-btn" />
        </div>
      </div>
      <span style={{ textTransform: "capitalize" }}>{props.text}</span>
    </div>
  );
};

ErrorBtn.propTypes = {
  className: PropTypes.any,
  text: PropTypes.any,
};

export const PendingBtn = (props) => {
  return (
    <div className={props.className ? props.className : "pending-btn"}>
      <div
        className={
          props.className ? `${props.className}-image` : "pending-btn-image"
        }
      >
        <div className="pending-btn-image-div">
          <img src={Pending} alt="pending-btn" />
        </div>
      </div>
      <span style={{ textTransform: "capitalize" }}>{props.text}</span>
    </div>
  );
};

PendingBtn.propTypes = {
  className: PropTypes.any,
  text: PropTypes.any,
};

export const DropBtn = (props) => {
  return (
    <div
      className="drop-btn"
      onClick={props.onClick}
      style={{ marginLeft: `${props.marginLeft}` }}
    >
      <div className="drop-btn-img">
        <img src={OptionIcon} alt="options-icon" />
      </div>
    </div>
  );
};

DropBtn.propTypes = {
  marginLeft: PropTypes.any,
  onClick: PropTypes.any,
};
