import PropTypes from "prop-types";
import React, { useEffect } from "react";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import Back from "../../assets/back.svg";
import { ToggleButton } from "../../components/Input/ToggleButton";
import { getSingleProduct, resetKey } from "../../redux/Actions/ProductAction";
import { connect } from "react-redux";
import { LoaderSpin } from "../../components/Loader/Loader";
import moment from "moment";
import Alert from "../../utils/notifications/Alert";

export const SingleProduct = (props) => {
  const {
    isGetting,
    product,
    getSuccess,
    getSingleProduct,
    isResetingKey,
    resetKey,
  } = props;

  const data = props.location.state && props.location.state.data;
  const id = data && data.id;

  const handleResetKey = () => {
    resetKey(id);
  };

  useEffect(
    () => {
      getSingleProduct(id);
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (getSuccess) {
        Alert("success", "Product fetched successfully");
      }
    },
    // eslint-disable-next-line
    []
  );

  return (
    <div className="single-provider">
      <HeaderWrapper title={"Product Details"} back={Back} route={"/products"}>
        <button
          onClick={() => handleResetKey()}
          className="single-provider-button"
          disabled={isResetingKey}
        >
          {isResetingKey ? "Reseting Key..." : "Reset Key"}
        </button>
      </HeaderWrapper>

      {isGetting ? (
        <LoaderSpin width="40px" height="40px" />
      ) : (
        <div className="single-provider-wrap">
          <div className="single-provider-wrap-details2">
            <div className="single-provider-wrap-details2-section">
              <p>Product Name</p>
              <h4>{product && product.name}</h4>
            </div>
            <div className="single-provider-wrap-details2-section">
              <p>SMS Limit</p>
              <h4>{product && product.sms_rate_limit}</h4>
            </div>
            <div className="single-provider-wrap-details2-section">
              <p>SMS Sent</p>
              <h4>{product && product.sms_sent}</h4>
            </div>
            <div className="single-provider-wrap-details2-section">
              <p>Status</p>
              <h4>
                <ToggleButton
                  style1={{ marginTop: "0px" }}
                  style2={{ marginTop: "0px" }}
                  checked={product.enabled}
                  id={`single-switch${product.id}`}
                />
              </h4>
            </div>
            <div className="single-provider-wrap-details2-section">
              <p>Date</p>
              <h4>
                {moment(product && product.date).format("LL")} |{" "}
                {moment(product && product.time).format("LT")}
              </h4>
            </div>
          </div>

          <br />
          <br />

          <div className="single-provider-wrap-request">
            <h1 className="single-provider-wrap-request-title">API Key</h1>
            <div className="single-provider-wrap-request-body">
              <pre>{product.api_key}</pre>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

SingleProduct.propTypes = {
  getSingleProduct: PropTypes.func,
  getSuccess: PropTypes.bool,
  isGetting: PropTypes.bool,
  isResetingKey: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      data: PropTypes.any,
    }),
  }),
  product: PropTypes.shape({
    api_key: PropTypes.any,
    date: PropTypes.any,
    enabled: PropTypes.any,
    id: PropTypes.number,
    name: PropTypes.any,
    sms_rate_limit: PropTypes.any,
    sms_sent: PropTypes.any,
    time: PropTypes.any,
  }),
  resetKey: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isGetting: state.product.isGettingProduct,
  getSuccess: state.product.getProductSuccess,
  product: state.product.singleProduct,
  isResetingKey: state.product.isResetingKey,
  resetKeySuccess: state.product.resetKeySuccess,
});

const mapDispatchToProps = (dispatch) => ({
  getSingleProduct: (id) => dispatch(getSingleProduct(id)),
  resetKey: (id) => dispatch(resetKey(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleProduct);
