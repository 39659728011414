import PropTypes from "prop-types";
import React from "react";
import { userColumns } from "../../components/TableData/Admin/User";
import Table from "../../components/Tables/Table";

export const showSingleDetails = () => {};

export const Users = (props) => {
  const {
    users,
    usersData,
    getUserData,
    isGettingUsers,
    getUsersSuccess,
    state,
    searchSuccess,
    isSearchingAdmin,
    searchedAdminUsers,
    searchAdminUsers,
  } = props;

  const moreData = (page, perPage) => {
    state.showSearchResults
      ? searchAdminUsers(page, perPage, state.searchValue)
      : getUserData(page, perPage);
  };

  const searchedAdminUsersData = searchedAdminUsers && searchedAdminUsers.data;

  const totalAdminUsers = state.showSearchResults
    ? searchedAdminUsers && searchedAdminUsers.total
    : usersData && usersData.total;

  const allAdminUsers = state.showSearchResults
    ? searchedAdminUsersData
    : users;

  return (
    <div>
      <Table
        columns={userColumns}
        data={allAdminUsers}
        getData={getUserData}
        getMoreData={(page, perPage) => moreData(page, perPage)}
        emptyText1={"You haven't added any user"}
        emptyText2={"Click on the “Send Invite” button to get started"}
        showData={(data) => showSingleDetails(data)}
        isLoading={isGettingUsers || isSearchingAdmin}
        success={getUsersSuccess}
        emptyResultText={"User not found!"}
        sortSuccess={searchSuccess}
        total={totalAdminUsers}
      />
    </div>
  );
};

Users.propTypes = {
  getUserData: PropTypes.func,
  getUsersSuccess: PropTypes.bool,
  isGettingUsers: PropTypes.bool,
  isSearchingAdmin: PropTypes.bool,
  searchAdminUsers: PropTypes.func,
  searchSuccess: PropTypes.bool,
  searchedAdminUsers: PropTypes.shape({
    data: PropTypes.any,
    total: PropTypes.any,
  }),
  state: PropTypes.shape({
    searchValue: PropTypes.any,
    showSearchResults: PropTypes.any,
  }),
  users: PropTypes.any,
  usersData: PropTypes.shape({
    total: PropTypes.any,
  }),
};

export default Users;
