import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Button from "../../components/Button/Button";
import Search from "../../components/Sorting/Search";
import Add from "../../assets/add.svg";
import { productColumns } from "../../components/TableData/Products/Products";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import { cancelModal, viewModal } from "../SMS Providers/SMSProviders";
import Modal from "../../components/Modal/Modal";
import Table from "../../components/Tables/Table";
import { showSingleDetails } from "../Admin/Users";
import { connect } from "react-redux";
import {
  addProduct,
  fetchProducts,
  searchProducts,
} from "../../redux/Actions/ProductAction";
import AddProduct from "../../components/Products/AddProduct";

export const Products = (props) => {
  const {
    isFetching,
    getSuccess,
    isSearching,
    searchSuccess,
    searchedProducts,
    searchedProductsData,
    searchProducts,
    products,
    productsData,
    getProducts,
    isAdding,
    addProduct,
    addSuccess,
  } = props;

  const [state, setState] = useState({
    addProductModal: false,
    searchValue: "",
    showSearchResults: false,
  });

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);

  useEffect(
    () => {
      setState({
        ...state,
        showSearchResults: false,
      });
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (searchSuccess) {
        setState({
          ...state,
          showSearchResults: true,
        });
      }
    },
    // eslint-disable-next-line
    [searchSuccess]
  );

  const searchProductsItems = () => {
    if (state.searchValue === "") {
      setState({
        ...state,
        showSearchResults: false,
      });
      return products;
    } else {
      searchProducts(1, 10, state.searchValue);
    }
  };

  const moreData = (page, perPage) => {
    state.showSearchResults
      ? searchProducts(page, perPage, state.searchValue)
      : getProducts(page, perPage);
  };

  const totalProducts = state.showSearchResults
    ? searchedProductsData && searchedProductsData.total
    : productsData && productsData.total;

  const allProducts = state.showSearchResults ? searchedProducts : products;

  useEffect(
    () => {
      if (addSuccess) {
        document.getElementById("addProduct").reset();
        closeModal("addProductModal");
      }
    },
    // eslint-disable-next-line
    [addSuccess]
  );

  return (
    <div>
      <HeaderWrapper title={"Products"}>
        <Button
          width={"auto"}
          disabled={""}
          height={"48px"}
          testId={"button"}
          type={"submit"}
          onClick={() => openModal("addProductModal")}
        >
          <div className="button-data">
            <img src={Add} alt="add-icon" />
            <span>Add Product</span>
          </div>
        </Button>
      </HeaderWrapper>

      <div className="switch-wrapper-top">
        <span className="reporting-title">
          {totalProducts} Product{totalProducts > 1 ? "s" : ""}
        </span>
        <div style={{ display: "flex" }}>
          <Search
            width={"509px"}
            placeholder={"Search by product name"}
            searchItems={searchProductsItems}
            setState={setState}
            state={state}
          />
        </div>
      </div>

      <Table
        columns={productColumns}
        data={allProducts}
        getData={getProducts}
        emptyText1={"You haven't added any product"}
        emptyText2={"Click on the “Add Product” button to get started"}
        showData={(data) => showSingleDetails(data)}
        getMoreData={(page, perPage) => moreData(page, perPage)}
        isLoading={isFetching || isSearching}
        success={getSuccess || searchSuccess}
        emptyResultText={"Product not found!"}
        sortSuccess={searchSuccess}
        total={totalProducts}
      />

      <Modal
        displayModal={state.addProductModal}
        closeModal={() => closeModal("addProductModal")}
        title={"Add Product"}
        width={"496px"}
      >
        <AddProduct isAdding={isAdding} addProduct={addProduct} />
      </Modal>
    </div>
  );
};

Products.propTypes = {
  addProduct: PropTypes.func,
  addSuccess: PropTypes.bool,
  getProducts: PropTypes.func,
  getSuccess: PropTypes.bool,
  isAdding: PropTypes.bool,
  isFetching: PropTypes.bool,
  isSearching: PropTypes.bool,
  products: PropTypes.array,
  productsData: PropTypes.shape({
    total: PropTypes.any,
  }),
  searchProducts: PropTypes.func,
  searchSuccess: PropTypes.bool,
  searchedProducts: PropTypes.array,
  searchedProductsData: PropTypes.shape({
    total: PropTypes.any,
  }),
};

const mapStateToProps = (state) => ({
  isFetching: state.product.isFetching,
  getSuccess: state.product.fetchProductsSuccess,
  isSearching: state.product.isSearching,
  searchSuccess: state.product.searchSuccess,
  searchedProductsData: state.product.searchedProductsData,
  searchedProducts: state.product.searchedProducts,
  productsData: state.product.productsData,
  products: state.product.products,
  isAdding: state.product.isAddingProduct,
  addSuccess: state.product.addProductSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  searchProducts: (page, limit, search) =>
    dispatch(searchProducts(page, limit, search)),
  getProducts: (page, limit) => dispatch(fetchProducts(page, limit)),
  addProduct: (payload) => dispatch(addProduct(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Products);
