import PropTypes from "prop-types";
import React from "react";
import Filter from "../../components/Sorting/Filter";
import Search from "../../components/Sorting/Search";

export const SortComponents = (props) => {
  const { openModal, searchItems, setState, state } = props;

  return (
    <div className="switch-wrapper-top-right">
      <Search
        placeholder={"Search by name"}
        searchItems={searchItems}
        setState={setState}
        state={state}
        width={"400px"}
      />

      {state.active1 && (
        <Filter
          onClick={() => openModal("filterModal")}
          marginLeft={"20px"}
          marginRight={"0px"}
        />
      )}
    </div>
  );
};

SortComponents.propTypes = {
  openModal: PropTypes.func,
  searchItems: PropTypes.func,
  setState: PropTypes.func,
  state: PropTypes.shape({
    active1: PropTypes.bool,
  }),
};
