import PropTypes from "prop-types";
import React from "react";
import { RequestData } from "./Components";
import { MoreKeys } from "./MoreKeys";

export const Soap = (props) => {
  const { state, setState } = props;

  return (
    <div>
      <RequestData
        title={"Soap Request"}
        name={"soap_request"}
        text={"This contains the SMS soap request for each provider."}
      />

      <MoreKeys state={state} setState={setState} />
    </div>
  );
};

Soap.propTypes = {
  setState: PropTypes.any,
  state: PropTypes.any,
};
