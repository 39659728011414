import Actions from "../Types";

const initialState = {
  isGettingMetrics: false,
  getMetricsSuccess: false,
  metrics: {},

  isGetting: false,
  getSuccess: false,
  graph: [],

  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_DASHBOARD_METRICS_START:
      return {
        ...state,
        isGettingMetrics: true,
        getMetricsSuccess: false,
        metrics: {},
        error: null,
      };
    case Actions.GET_DASHBOARD_METRICS_SUCCESS:
      return {
        ...state,
        isGettingMetrics: false,
        getMetricsSuccess: true,
        metrics: action.payload,
        error: null,
      };
    case Actions.GET_DASHBOARD_METRICS_FAILURE:
      return {
        ...state,
        isGettingMetrics: false,
        getMetricsSuccess: false,
        metrics: {},
        error: action.payload,
      };
    case Actions.GET_GRAPH_METRICS_START:
      return {
        ...state,
        isGetting: true,
        getSuccess: false,
        graph: [],
        error: null,
      };
    case Actions.GET_GRAPH_METRICS_SUCCESS:
      return {
        ...state,
        isGetting: false,
        getSuccess: true,
        graph: action.payload,
        error: null,
      };
    case Actions.GET_GRAPH_METRICS_FAILURE:
      return {
        ...state,
        isGetting: false,
        getSuccess: false,
        graph: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reduxState;
