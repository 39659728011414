import React from "react";
import { Route, Switch } from "react-router-dom";
import Admin from "../pages/Admin/Admin";
import Dashboard from "../pages/Dashboard/Dashboard";
import Login from "../pages/Login/Login";
import Reporting from "../pages/Reporting/Reporting";
import ReportDetails from "../pages/Reporting/ReportDetails";
import RouterReporting from "../pages/RouterReporting/RouterReporting";
import RouterReportDetails from "../pages/RouterReporting/RouterReportDetails";
import CreateAccount from "../pages/CreateAccount/CreateAccount.jsx";
import VerifyAccount from "../pages/VerifyAccount/VerifyAccount.jsx";
import PrivateRoute from "./PrivateRoute";
import Profile from "../pages/Profile/Profile";
import ResetPassword from "../pages/AccountRecovery/ResetPassword";
import ForgotPassword from "../pages/AccountRecovery/ForgotPassword";
import CheckEmail from "../components/CheckEmail/CheckEmail";
import NotFound from "../pages/NotFound/NotFound";
import SMSProviders from "../pages/SMS Providers/SMSProviders";
import SingleProvider from "../pages/SMS Providers/SingleProvider";
import Products from "../pages/Products/Products";
import SingleProduct from "../pages/Products/SingleProduct";
import VerifyInvite from "../pages/VerifyInvite/VerifyInvite";
import Trails from "../pages/Trails/Trails";

const Routes = () => {
  return (
    <React.Fragment>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/create-account" component={CreateAccount} />
        <Route exact path="/verify" component={VerifyAccount} />
        <Route exact path="/verify-invite" component={VerifyInvite} />
        <Route exact path="/recover-account" component={ForgotPassword} />
        <Route exact path="/reset-password" component={ResetPassword} />
        <Route exact path="/check-email" component={CheckEmail} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/providers" component={SMSProviders} />
        <PrivateRoute exact path="/providers/:id" component={SingleProvider} />
        <PrivateRoute exact path="/reporting" component={Reporting} />
        <PrivateRoute exact path="/reporting/:id" component={ReportDetails} />
        <PrivateRoute exact path="/router_reporting" component={RouterReporting} />
        <PrivateRoute exact path="/router_reporting/:id" component={RouterReportDetails} />
        <PrivateRoute exact path="/admin" component={Admin} />
        <PrivateRoute exact path="/trails" component={Trails} />
        <PrivateRoute exact path="/profile" component={Profile} />
        <PrivateRoute exact path="/products" component={Products} />
        <PrivateRoute exact path="/products/:id" component={SingleProduct} />
        <Route exact path="*" component={NotFound} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
