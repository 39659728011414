import React, { useEffect, useState } from "react";
import { useTable, usePagination, useRowSelect } from "react-table";
import { Body } from "./Body";
import { Pagination } from "./Pagination";
import { useExportData } from "react-table-plugins";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, id, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <div className="table-select-box">
        <label htmlFor={id}></label>
        <input
          type="checkbox"
          ref={resolvedRef}
          {...rest}
          className="table-select"
          id={id}
        />
      </div>
    );
  }
);

function TableComponent(props) {
  const {
    columns,
    data,
    emptyText1,
    emptyText2,
    onClick,
    isLoading,
    success,
    emptyResultText,
    sortSuccess,
    setSelectedRows,
    // selectedRows,
    setPage,
    currentpage,
    perPage,
    totalPage,
    total,
    getNewData,
  } = props;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    selectedFlatRows,
  } = useTable(
    {
      columns,
      data,
      useRowSelect,
      useControlledState: (state) => {
        return React.useMemo(
          () => ({
            ...state,
            pageIndex: currentpage,
          }),
          [state]
        );
      },
      initialState: { pageIndex: currentpage },
      manualPagination: true,
      pageCount: totalPage,
    },
    useExportData,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: "selection",

          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ]);
    }
  );

  const rowdata = selectedFlatRows.map((d) => d.original);
  const pageOptions = total && Math.ceil(Number(total) / 10);

  useEffect(
    () => {
      setSelectedRows(rowdata);
    },
    // eslint-disable-next-line
    [selectedFlatRows]
  );

  return (
    <>
      <Body
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        prepareRow={prepareRow}
        page={page}
        emptyText1={emptyText1}
        emptyText2={emptyText2}
        testRowClick={onClick}
        isLoading={isLoading}
        success={success}
        emptyResultText={emptyResultText}
        sortSuccess={sortSuccess}
        currentpage={currentpage}
        perPage={perPage}
        totalPage={totalPage}
        className="table1"
      />
      {pageOptions > 1 && !isLoading && (
        <Pagination
          getData={(index) => getNewData(index)}
          pageOptions={pageOptions}
          setPage={setPage}
          pageIndex={currentpage}
        />
      )}
    </>
  );
}

function SelectTable(props) {
  const {
    columns,
    data,
    emptyText1,
    emptyText2,
    showData,
    isLoading,
    success,
    emptyResultText,
    sortSuccess,
    setSelectedRows,
    selectedRows,
    total,
    getData,
    getMoreData,
  } = props;

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(
    () => {
      getData(page, perPage);
    },
    // eslint-disable-next-line
    []
  );

  const getNewData = (page) => {
    getMoreData(page, perPage);
  };

  return (
    <TableComponent
      columns={columns}
      data={data}
      emptyText1={emptyText1}
      emptyText2={emptyText2}
      onClick={showData}
      isLoading={isLoading}
      success={success}
      emptyResultText={emptyResultText}
      sortSuccess={sortSuccess}
      setSelectedRows={setSelectedRows}
      selectedRows={selectedRows}
      getData={getData}
      getNewData={(data) => getNewData(data)}
      setPerPage={setPerPage}
      setPage={setPage}
      currentpage={page}
      perPage={perPage}
      totalPage={page}
      total={total}
    />
  );
}

export default SelectTable;
