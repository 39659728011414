import React from "react";
import { Form, Formik, Field } from "formik";
import Input from "../../components/Input/Input";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import { getDecodedData } from "../../utils/functions/getStorageItems";
import { getFirstName, getLastName } from "../../utils/functions/spiltNames";

export const Profile = () => {
  const { name, type, email } = getDecodedData();

  const role = type === "checker" ? "Checker" : "maker" ? "Maker" : "Viewer";
  const firstName = getFirstName(name);
  const lastName = getLastName(name);

  return (
    <div>
      <HeaderWrapper title={"Profile"}></HeaderWrapper>
      <div className="profile-wrap">
        <div className="profile-wrap-form">
          <div className="profile-wrap-title">Profile Information</div>
          <Formik
            initialValues={{
              firstName: firstName,
              lastName: lastName,
              email: email,
              role: role,
            }}
          >
            {({ isValid, dirty }) => {
              return (
                <Form id="form">
                  <div className="profile-wrap-forminput">
                    <Field
                      name="firstName"
                      label="First Name"
                      placeholder="Enter First Name"
                      component={Input}
                      readOnly
                    />
                    <Field
                      name="lastName"
                      label="Last Name"
                      placeholder="Enter Last Name"
                      component={Input}
                      readOnly
                    />
                    <Field
                      name="email"
                      label="Email Address"
                      placeholder="Enter Email Address"
                      component={Input}
                      readOnly
                    />
                    <Field
                      name="role"
                      label="Admin Role"
                      component={Input}
                      readOnly
                    />
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Profile;
