import PropTypes from "prop-types";
import React from "react";
import { Form, Formik, Field } from "formik";
import Input from "../../components/Input/Input";
import { LoginSchema } from "../../utils/validation/validation";
import { connect } from "react-redux";
import { userLogin } from "../../redux/Actions/AuthActions";
import { useHistory } from "react-router";
import { ModalButton } from "../../components/Modal/Modal";
import Storage from "../../utils/services/storage";

const LoginForm = ({ login, isLoading }) => {
  const history = useHistory();

  return (
    <div>
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={(values) => {
          const loginvalues = {
            username: values.email.trim(),
            password: values.password,
          };
          login(loginvalues, history);
          Storage.set("email-value", values.email.trim());
        }}
        validationSchema={LoginSchema}
      >
        {({ isValid, dirty }) => {
          return (
            <Form id="form">
              <div>
                <div className="form-grid">
                  <Field
                    name="email"
                    label="Email Address"
                    placeholder="Enter email address"
                    component={Input}
                  />
                </div>
                <div className="form-grid">
                  <Field
                    name="password"
                    label="Password"
                    placeholder="Enter password"
                    component={Input}
                    type="password"
                    password
                  />
                </div>
                <a href="/recover-account" className="forgot-password">
                  Forgot Password
                </a>

                <ModalButton
                  width={"100%"}
                  loadingText={"Loading"}
                  text={"Log in"}
                  isLoading={isLoading}
                  disabled={!(isValid && dirty)}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

LoginForm.propTypes = {
  isLoading: PropTypes.bool,
  login: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  login: (user, history) => dispatch(userLogin(user, history)),
});

const mapStateToProps = (state) => ({
  isLoading: state.auth.isLoading,
  currentUser: state.auth.currentUser,
  data: state.auth.data,
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
