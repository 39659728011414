import Actions from "../Types";

const initialState = {
  isAdding: false,
  addSuccess: false,
  data: {},
  isTesting: false,
  testSuccess: false,
  test: {},
  isTestingSmpp: false,
  testSmppSuccess: false,
  testSmpp: {},
  isEditing: false,
  editSuccess: false,
  isDeleting: false,
  deleteSuccess: false,
  isGettingAll: false,
  getAllSuccess: false,
  providers: [],
  providersData: {},
  isSearching: false,
  searchSuccess: false,
  searchedProviders: [],
  searchedProvidersData: {},
  isFiltering: false,
  filterSuccess: false,
  filteredProviders: [],
  filteredProvidersData: {},
  isGettingSingle: false,
  getSingleSuccess: false,
  provider: {},
  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.ADD_PROVIDER_START:
      return {
        ...state,
        isAdding: true,
        addSuccess: false,
        data: {},
        error: null,
      };
    case Actions.ADD_PROVIDER_SUCCESS:
      return {
        ...state,
        isAdding: false,
        addSuccess: true,
        data: action.payload,
        error: null,
      };
    case Actions.ADD_PROVIDER_FAILURE:
      return {
        ...state,
        isAdding: false,
        addSuccess: false,
        data: {},
        error: action.payload,
      };
    case Actions.TEST_PROVIDER_START:
      return {
        ...state,
        isTesting: true,
        testSuccess: false,
        test: {},
        error: null,
      };
    case Actions.TEST_PROVIDER_SUCCESS:
      return {
        ...state,
        isTesting: false,
        testSuccess: true,
        test: action.payload,
        error: null,
      };
    case Actions.TEST_PROVIDER_FAILURE:
      return {
        ...state,
        isTesting: false,
        testSuccess: false,
        test: {},
        error: action.payload,
      };
    case Actions.TEST_PROVIDER_SMPP_START:
      return {
        ...state,
        isTestingSmpp: true,
        testSmppSuccess: false,
        testSmpp: {},
        error: null,
      };
    case Actions.TEST_PROVIDER_SMPP_SUCCESS:
      return {
        ...state,
        isTestingSmpp: false,
        testSmppSuccess: true,
        testSmpp: action.payload,
        error: null,
      };
    case Actions.TEST_PROVIDER_SMPP_FAILURE:
      return {
        ...state,
        isTestingSmpp: false,
        testSmppSuccess: false,
        testSmpp: {},
        error: action.payload,
      };
    case Actions.EDIT_PROVIDER_START:
      return {
        ...state,
        isEditing: true,
        editSuccess: false,
        data: {},
        error: null,
      };
    case Actions.EDIT_PROVIDER_SUCCESS:
      return {
        ...state,
        isEditing: false,
        editSuccess: true,
        data: action.payload,
        error: null,
      };
    case Actions.EDIT_PROVIDER_FAILURE:
      return {
        ...state,
        isEditing: false,
        editSuccess: false,
        data: {},
        error: action.payload,
      };
    case Actions.DELETE_PROVIDER_START:
      return {
        ...state,
        isDeleting: true,
        deleteSuccess: false,
        error: null,
      };
    case Actions.DELETE_PROVIDER_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: true,
        error: null,
      };
    case Actions.DELETE_PROVIDER_FAILURE:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: false,
        error: action.payload,
      };
    case Actions.GET_ALL_PROVIDERS_START:
      return {
        ...state,
        isGettingAll: true,
        getAllSuccess: false,
        providers: [],
        providersData: {},
        error: null,
      };
    case Actions.GET_ALL_PROVIDERS_SUCCESS:
      return {
        ...state,
        isGettingAll: false,
        getAllSuccess: true,
        providers: action.payload && action.payload.data,
        providersData: action.payload,
        error: null,
      };
    case Actions.GET_ALL_PROVIDERS_FAILURE:
      return {
        ...state,
        isGettingAll: false,
        getAllSuccess: false,
        providers: [],
        providersData: {},
        error: action.payload,
      };
    case Actions.SEARCH_PROVIDERS_START:
      return {
        ...state,
        isSearching: true,
        searchSuccess: false,
        searchedProviders: [],
        searchedProvidersData: {},
        error: null,
      };
    case Actions.SEARCH_PROVIDERS_SUCCESS:
      return {
        ...state,
        isSearching: false,
        searchSuccess: true,
        searchedProviders: action.payload && action.payload.data,
        searchedProvidersData: action.payload,
        error: null,
      };
    case Actions.SEARCH_PROVIDERS_FAILURE:
      return {
        ...state,
        isSearching: false,
        searchSuccess: false,
        searchedProviders: [],
        searchedProvidersData: {},
        error: action.payload,
      };
    case Actions.FILTER_PROVIDERS_START:
      return {
        ...state,
        isFiltering: true,
        filterSuccess: false,
        filteredProviders: [],
        filteredProvidersData: {},
        error: null,
      };
    case Actions.FILTER_PROVIDERS_SUCCESS:
      return {
        ...state,
        isFiltering: false,
        filterSuccess: true,
        filteredProviders: action.payload && action.payload.data,
        filteredProvidersData: action.payload,
        error: null,
      };
    case Actions.FILTER_PROVIDERS_FAILURE:
      return {
        ...state,
        isFiltering: false,
        filterSuccess: false,
        filteredProviders: [],
        filteredProvidersData: {},
        error: action.payload,
      };
    case Actions.GET_SINGLE_PROVIDER_START:
      return {
        ...state,
        isGettingSingle: true,
        getSingleSuccess: false,
        provider: {},
        error: null,
      };
    case Actions.GET_SINGLE_PROVIDER_SUCCESS:
      return {
        ...state,
        isGettingSingle: false,
        getSingleSuccess: true,
        provider: action.payload,
        error: null,
      };
    case Actions.GET_SINGLE_PROVIDER_FAILURE:
      return {
        ...state,
        isGettingSingle: false,
        getSingleSuccess: false,
        provider: {},
      };
    default:
      return state;
  }
};

export default reduxState;
