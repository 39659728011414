import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { providerColumns } from "../../components/TableData/Provider/Provider";
import Table from "../../components/Tables/Table";
import {
  getAllProviders,
  searchProviders,
} from "../../redux/Actions/ProviderActions";
import { showSingleDetails } from "../Admin/Users";

export const Providers = (props) => {
  const {
    isGetting,
    getSuccess,
    providers,
    getProviders,
    searchSuccess,
    isSearching,
    searchedProviders,
    filteredProviders,
    filterSuccess,
    setState,
    state,
    totalProviders,
    filterProviders,
    searchProviders,
    isFiltering,
  } = props;

  useEffect(
    () => {
      setState({
        ...state,
        showSearchResults: false,
        showFilterResults: false,
      });
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (searchSuccess) {
        setState({
          ...state,
          showSearchResults: true,
          showFilterResults: false,
        });
      }
    },
    // eslint-disable-next-line
    [searchSuccess]
  );

  useEffect(
    () => {
      if (filterSuccess) {
        setState({
          ...state,
          filterModal: false,
          showSearchResults: false,
          showFilterResults: true,
        });
      }
    },
    // eslint-disable-next-line
    [filterSuccess]
  );

  const moreData = (page, perPage) => {
    state.showSearchResults
      ? searchProviders(state.searchValue, page, perPage)
      : state.showFilterResults
      ? filterProviders(page, perPage)
      : getProviders(page, perPage);
  };

  const allProviders = state.showSearchResults
    ? searchedProviders
    : state.showFilterResults
    ? filteredProviders
    : providers;

  return (
    <div>
      <Table
        isLoading={isGetting || isSearching || isFiltering}
        columns={providerColumns}
        showData={(data) => showSingleDetails(data)}
        data={allProviders}
        success={getSuccess || searchSuccess || filterSuccess}
        emptyText1={"You haven’t added any provider"}
        emptyText2={"Click on the “Add SMS Provider” button to get started"}
        emptyResultText={"Provider not found!"}
        sortSuccess={searchSuccess || filterSuccess}
        getData={getProviders}
        total={totalProviders}
        getMoreData={(page, perPage) => moreData(page, perPage)}
      />
    </div>
  );
};

Providers.propTypes = {
  filterProviders: PropTypes.func,
  filterSuccess: PropTypes.bool,
  filteredProviders: PropTypes.any,
  getProviders: PropTypes.func,
  getSuccess: PropTypes.bool,
  isFiltering: PropTypes.bool,
  isGetting: PropTypes.bool,
  isSearching: PropTypes.bool,
  providers: PropTypes.any,
  searchProviders: PropTypes.func,
  searchSuccess: PropTypes.bool,
  searchedProviders: PropTypes.array,
  setState: PropTypes.func,
  state: PropTypes.shape({
    searchValue: PropTypes.any,
    showFilterResults: PropTypes.any,
    showSearchResults: PropTypes.any,
  }),
  totalProviders: PropTypes.any,
};

const mapStateToProps = (state) => ({
  isGetting: state.provider.isGettingAll,
  getSuccess: state.provider.getAllSuccess,
  isSearching: state.provider.isSearching,
  searchSuccess: state.provider.searchSuccess,
  providers: state.provider.providers,
  isFiltering: state.provider.isFiltering,
  searchedProviders: state.provider.searchedProviders,
  filterSuccess: state.provider.filterSuccess,
  filteredProviders: state.provider.filteredProviders,
});

const mapDispatchToProps = (dispatch) => ({
  getProviders: (page, limit) => dispatch(getAllProviders(page, limit)),
  searchProviders: (search, page, limit) =>
    dispatch(searchProviders(search, page, limit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Providers);
