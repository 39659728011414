import Actions from "../Types";
import Storage from "../../utils/services/storage";

const initialState = {
  isLoading: false,
  currentUser: null,
  data: {},

  isLoggingOut: false,
  isVerifying: false,
  verifySuccess: false,
  isResendingInvite: false,
  resendInviteSuccess: false,

  isAuthenticating: false,
  authenticateSuccess: false,
  authenticationStatus: {},

  isResending: false,
  resendingSucess: false,
  reSendingStatus: {},

  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.SET_CURRENT_USER:
      return {
        ...state,
        isLoading: false,
        currentUser: Storage.get("user-access-token"),
        error: null,
        isAuthenticated: false,
      };
    case Actions.USER_LOGIN_START:
      return {
        ...state,
        isLoading: true,
        currentUser: null,
        isAuthenticated: false,
        error: null,
      };
    case Actions.USER_LOGIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentUser: action.payload,
        isAuthenticated: true,
        error: null,
      };
    case Actions.USER_LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        currentUser: null,
        isAuthenticated: false,
        error: action.payload,
      };
    case Actions.VERIFY_INVITE_START:
      return {
        ...state,
        isVerifying: true,
        verifySuccess: false,
        error: null,
      };
    case Actions.VERIFY_INVITE_SUCCESS:
      return {
        ...state,
        isVerifying: false,
        verifySuccess: true,
        error: null,
      };
    case Actions.VERIFY_INVITE_FAILURE:
      return {
        ...state,
        isVerifying: false,
        verifySuccess: false,
        error: action.payload,
      };
    case Actions.RESEND_USER_INVITE_START:
      return {
        ...state,
        isResendingInvite: true,
        resendInviteSuccess: false,
        error: null,
      };
    case Actions.RESEND_USER_INVITE_SUCCESS:
      return {
        ...state,
        isResendingInvite: false,
        resendInviteSuccess: true,
        error: null,
      };
    case Actions.RESEND_USER_INVITE_FAILURE:
      return {
        ...state,
        isResendingInvite: false,
        resendInviteSuccess: false,
        error: action.payload,
      };
    case Actions.USER_LOG_OUT_START:
      return {
        ...state,
        isLoggingOut: true,
      };
    case Actions.USER_LOG_OUT_SUCCESS:
      return {
        ...state,
        isLoggingOut: false,
        currentUser: null,
        error: null,
      };
    case Actions.USER_LOG_OUT_FAILURE:
      return {
        ...state,
        isLoggingOut: false,
      };
    case Actions.AUTHENTICATION_START:
      return {
        ...state,
        isAuthenticating: true,
        authenticateSuccess: false,
        error: null,
      };
    case Actions.AUTHENTICATION_SUCCESS:
      return {
        ...state,
        isAuthenticating: false,
        authenticateSuccess: true,
        authenticationStatus: action.payload,
        error: null,
      };
    case Actions.AUTHENTICATION_FAILURE:
      return {
        ...state,
        isAuthenticating: false,
        authenticateSuccess: false,
        error: action.payload,
      };
    case Actions.RESEND_AUTHENTICATION_START:
      return {
        ...state,
        isResending: true,
        resendingSucess: false,
        error: null,
      };
    case Actions.RESEND_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        isResending: false,
        resendingSucess: true,
        reSendingStatus: action.payload,
        error: null,
      };
    case Actions.RESEND_AUTHENTICATION_FAILURE:
      return {
        ...state,
        isResending: false,
        resendingSucess: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reduxState;
