import PropTypes from "prop-types";
import React from "react";
import { Oval } from "react-loader-spinner";

export const LoaderSpin = (props) => {
  const { color, width, height } = props;

  return (
    <div>
      <Oval
        color={color || "#0e347e"}
        height={width}
        width={height}
      />
    </div>
  );
};

LoaderSpin.propTypes = {
  color: PropTypes.string,
  height: PropTypes.any,
  width: PropTypes.any,
};
