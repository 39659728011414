import PropTypes from "prop-types";
import React from "react";
import Switch from "react-switch";

export const ToggleButton = (props) => {
  const { text, handleChange, checked, id, style1, style2 } = props;

  return (
    <div className="toggle-button" style={style1}>
      <h2>{text}</h2>
      <div className="toggle-button-body" style={style2}>
        <label htmlFor={id}>
          <Switch
            onChange={handleChange}
            checked={checked}
            uncheckedIcon={false}
            checkedIcon={false}
            width={36}
            height={22}
            handleDiameter={20}
            offColor={"#F4F5F6"}
            onColor={"#27BE63"}
            offHandleColor={"#fff"}
            boxShadow={"0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)"}
            onHandleColor={"#fff"}
            activeBoxShadow={"#fff"}
            id={id}
          />
        </label>

        <span>{checked ? "Active" : "Inactive"}</span>
      </div>
    </div>
  );
};

ToggleButton.propTypes = {
  checked: PropTypes.any,
  handleChange: PropTypes.any,
  id: PropTypes.any,
  style1: PropTypes.any,
  style2: PropTypes.any,
  text: PropTypes.any
}
