import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import Modal from "../../components/Modal/Modal";
import RankingData from "../../components/Provider/RankingData";
import { rankingColumns } from "../../components/TableData/Provider/Ranking";
import Table from "../../components/Tables/Table";
import {
  editRanks,
  getAllRanks,
  getRankList,
  searchRanks,
} from "../../redux/Actions/RanksActions";

export const Ranking = (props) => {
  const {
    isGetting,
    getSuccess,
    isSearching,
    searchSuccess,
    ranks,
    getRanks,
    searchedRanks,
    getRankingData,
    isGettingList,
    list,
    getListSuccess,
    setState,
    state,
    editRanks,
    isEditing,
    editSuccess,
    totalRanks,
    searchRanks,
  } = props;

  useEffect(
    () => {
      setState({
        ...state,
        showRanksSearchResults: false,
      });
    },
    // eslint-disable-next-line
    []
  );

  useEffect(
    () => {
      if (searchSuccess) {
        setState({
          ...state,
          showRanksSearchResults: true,
        });
      }
    },
    // eslint-disable-next-line
    [searchSuccess]
  );

  const moreData = (page, perPage) => {
    state.showRanksSearchResults
      ? searchRanks(state.searchValue, page, perPage)
      : getRanks(page, perPage);
  };

  const allRanks = state.showRanksSearchResults ? searchedRanks : ranks;

  const showSingleDetails = (data) => {
    setState({
      ...state,
      showModal: true,
      data: data,
      id: data && data.country_code,
    });
  };

  const closeModal = () => {
    setState({ ...state, showModal: false });
  };

  return (
    <div>
      <Table
        isLoading={isGetting || isSearching}
        columns={rankingColumns}
        data={allRanks}
        success={getSuccess || searchSuccess}
        emptyText1={"No available ranks"}
        emptyText2={"Click on the “Add SMS Provider” button to get started"}
        showData={(data) => showSingleDetails(data)}
        emptyResultText={"Country not found!"}
        sortSuccess={searchSuccess}
        getData={getRanks}
        total={totalRanks}
        getMoreData={(page, perPage) => moreData(page, perPage)}
      />

      {state.showModal && (
        <Modal
          displayModal={state.showModal}
          closeModal={() => closeModal()}
          title={"Ranking"}
          width={"574px"}
        >
          <RankingData
            data={state.data}
            getRankingData={(data, func) => getRankingData(data, func)}
            isGetting={isGettingList}
            getSuccess={getListSuccess}
            lists={list}
            editRanks={(payload, func) => editRanks(payload, func)}
            isEditing={isEditing}
            editSuccess={editSuccess}
            closeModal={() => closeModal()}
          />
        </Modal>
      )}
    </div>
  );
};

Ranking.propTypes = {
  editRanks: PropTypes.func,
  editSuccess: PropTypes.bool,
  getListSuccess: PropTypes.bool,
  getRankingData: PropTypes.func,
  getRanks: PropTypes.func,
  getSuccess: PropTypes.bool,
  isEditing: PropTypes.bool,
  isGetting: PropTypes.bool,
  isGettingList: PropTypes.bool,
  isSearching: PropTypes.bool,
  list: PropTypes.any,
  ranks: PropTypes.any,
  searchRanks: PropTypes.func,
  searchSuccess: PropTypes.bool,
  searchedRanks: PropTypes.any,
  setState: PropTypes.func,
  state: PropTypes.shape({
    data: PropTypes.any,
    searchValue: PropTypes.any,
    showModal: PropTypes.any,
    showRanksSearchResults: PropTypes.any,
  }),
  totalRanks: PropTypes.any,
};

const mapStateToProps = (state) => ({
  isGetting: state.ranks.isGettingAll,
  getSuccess: state.ranks.getAllSuccess,
  isSearching: state.ranks.isSearching,
  searchSuccess: state.ranks.searchSuccess,
  ranks: state.ranks.ranks,
  searchedRanks: state.ranks.searchedRanks,
  isGettingList: state.ranks.isGettingList,
  getListSuccess: state.ranks.getListSuccess,
  list: state.ranks.list,
  isEditing: state.ranks.isEditing,
  editSuccess: state.ranks.editSuccess,
});

const mapDispatchToProps = (dispatch) => ({
  searchRanks: (search, page, limit) =>
    dispatch(searchRanks(search, page, limit)),
  getRanks: (page, limit) => dispatch(getAllRanks(page, limit)),
  getRankingData: (payload, updateCharacters) =>
    dispatch(getRankList(payload, updateCharacters)),
  editRanks: (payload, func) => dispatch(editRanks(payload, func)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Ranking);
