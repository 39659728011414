import PropTypes from "prop-types";
import React, { useState } from "react";
import Button from "../../components/Button/Button";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import Add from "../../assets/add.svg";
import SwitchWrapper from "../../components/Wrappers/SwitchWrapper";
import Ranking from "./Ranking";
import Provider from "./Provider";
import Modal from "../../components/Modal/Modal";
import AddProvider from "../../components/Provider/AddProvider";
import { SortComponents } from "./SortComponents";
import FilterData from "../../components/Sorting/FilterData";
import {
  addProvider,
  testProvider,
  filterProviders,
  searchProviders,
} from "../../redux/Actions/ProviderActions";
import { connect } from "react-redux";
import { searchRanks } from "../../redux/Actions/RanksActions";
import {
  paginateFilter,
  submitFilterValues,
} from "../../utils/functions/provider";

export const viewModal = (type, state, setState) => {
  setState({ ...state, [type]: true });
};

export const cancelModal = (type, state, setState) => {
  setState({ ...state, [type]: false });
};

export const SMSProviders = (props) => {
  const {
    isAdding,
    addProvider,
    addSuccess,
    providers,
    searchProviders,
    searchedProvidersData,
    isFiltering,
    filterProviders,
    filteredProvidersData,
    providersData,
    ranks,
    searchRanks,
    ranksData,
    searchedRanksData,
  } = props;

  const [state, setState] = useState({
    filterModal: false,
    addModal: false,
    editModal: false,
    deleteModal: false,
    showSearchResults: false,
    showFilterResults: false,
    searchValue: "",
    ranksSearchValue: "",
    active1: true,
    active2: false,
    showModal: false,
    data: "",
    id: "",
    values: {},
    showLoaders: false,
    showExample: false,
    addHook: false,
    noHook: false,
  });

  const [stateRanks, setStateRanks] = useState({
    showRanksSearchResults: false,
    searchValue: "",
    showModal: false,
    data: "",
  });

  const options = [
    {
      name: "Active",
    },
    {
      name: "Disabled",
    },
  ];

  const openModal = (type) => viewModal(type, state, setState);
  const closeModal = (type) => cancelModal(type, state, setState);

  const submitValues = submitFilterValues(setState, state, filterProviders);

  const runFilter = paginateFilter(state, filterProviders);

  const searchProviderItems = () => {
    if (state.searchValue === "") {
      setState({
        ...state,
        showSearchResults: false,
      });
      return providers;
    } else {
      searchProviders(state.searchValue, 1, 10);
    }
  };

  const searchRanksItems = () => {
    if (stateRanks.searchValue === "") {
      setStateRanks({
        ...state,
        showRanksSearchResults: false,
      });
      return ranks;
    } else {
      searchRanks(stateRanks.searchValue, 1, 10);
    }
  };

  const totalProviders = state.showSearchResults
    ? searchedProvidersData && searchedProvidersData.total
    : state.showFilterResults
    ? filteredProvidersData && filteredProvidersData.total
    : providersData && providersData.total;

  const totalRanks = stateRanks.showRanksSearchResults
    ? searchedRanksData && searchedRanksData.total
    : ranksData && ranksData.total;

  const providersLength = totalProviders;
  const rankingLength = totalRanks;

  const closeLoaders = () => {
    setState({
      ...state,
      addModal: false,
      showLoaders: false,
      addHook: false,
      noHook: false,
    });
  };

  return (
    <div className="sms-providers">
      <HeaderWrapper
        title={"SMS Providers"}
        length={
          state.active1 && providersLength !== 0 && rankingLength !== 0
            ? providersLength
            : rankingLength
        }
      >
        <Button
          width={"auto"}
          onClick={() => openModal("addModal")}
          disabled={""}
          height={"48px"}
          testId={"button"}
          type={"submit"}
        >
          <div className="button-data">
            <img src={Add} alt="add-icon" />
            <span>Add SMS Provider</span>
          </div>
        </Button>
      </HeaderWrapper>

      <SwitchWrapper
        title1={"Providers"}
        title2={"Ranking"}
        state={state}
        setstate={setState}
        component1={
          <Provider
            setState={setState}
            state={state}
            totalProviders={totalProviders}
            filterProviders={(page, perPage) => runFilter(page, perPage)}
          />
        }
        component2={
          <Ranking
            setState={setStateRanks}
            state={stateRanks}
            totalRanks={totalRanks}
          />
        }
        right1={
          <SortComponents
            openModal={openModal}
            searchItems={searchProviderItems}
            setState={setState}
            state={state}
          />
        }
        right2={
          <SortComponents
            openModal={openModal}
            searchItems={searchRanksItems}
            setState={setStateRanks}
            state={stateRanks}
          />
        }
      />

      <Modal
        displayModal={state.addModal}
        closeModal={() => closeModal("addModal")}
        title={state.showExample ? "Example" : "Add SMS Provider"}
        width={"574px"}
        show={state.showLoaders}
      >
        <AddProvider
          isAdding={isAdding}
          addProvider={addProvider}
          addstate={state}
          setAddState={setState}
          addSuccess={addSuccess}
          closeLoaders={closeLoaders}
        />
      </Modal>

      <Modal
        displayModal={state.filterModal}
        closeModal={() => closeModal("filterModal")}
        title={"Filter"}
        width={"496px"}
      >
        <FilterData
          options={options}
          submitValues={submitValues}
          isFiltering={isFiltering}
        />
      </Modal>
    </div>
  );
};

SMSProviders.propTypes = {
  addProvider: PropTypes.func,
  addSuccess: PropTypes.bool,
  filterProviders: PropTypes.any,
  filteredProvidersData: PropTypes.shape({
    total: PropTypes.any,
  }),
  isAdding: PropTypes.bool,
  isFiltering: PropTypes.bool,
  providers: PropTypes.any,
  providersData: PropTypes.shape({
    total: PropTypes.any,
  }),
  ranks: PropTypes.any,
  ranksData: PropTypes.shape({
    total: PropTypes.any,
  }),
  searchProviders: PropTypes.func,
  searchRanks: PropTypes.func,
  searchedProvidersData: PropTypes.shape({
    total: PropTypes.any,
  }),
  searchedRanksData: PropTypes.shape({
    total: PropTypes.any,
  }),
};

const mapDispatchToProps = (dispatch) => ({
  addProvider: (type, payload) => dispatch(addProvider(type, payload)),
  searchProviders: (search, page, limit) =>
    dispatch(searchProviders(search, page, limit)),
  filterProviders: (page, limit, payload) =>
    dispatch(filterProviders(page, limit, payload)),
  searchRanks: (search, page, limit) =>
    dispatch(searchRanks(search, page, limit)),
  testProvider: (payload) => dispatch(testProvider(payload)),
});

const mapStateToProps = (state) => ({
  isAdding: state.provider.isAdding,
  addSuccess: state.provider.addSuccess,
  isTesting: state.provider.isTesting,
  testSuccess: state.provider.testSuccess,
  providers: state.provider.providers,
  providersData: state.provider.providersData,
  searchedProvidersData: state.provider.searchedProvidersData,
  isFiltering: state.provider.isFiltering,
  filteredProviders: state.provider.filteredProviders,
  filteredProvidersData: state.provider.filteredProvidersData,
  ranks: state.ranks.ranks,
  ranksData: state.ranks.ranksData,
  searchedRanksData: state.ranks.searchedRanksData,
});

export default connect(mapStateToProps, mapDispatchToProps)(SMSProviders);
