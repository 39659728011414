import React from "react";
import { nameAcronym } from "../../../utils/functions/acroymn";
import moment from "moment";

export const roleColumns = [
  {
    Header: "Admin Role",
    accessor: "role",
    Cell: ({ row }) => (
      <div className="table-flex">
        <div className="table-flex-image">{nameAcronym(row.original.role)}</div>
        <span style={{ marginLeft: "12px", textTransform: "capitalize" }}>
          {row.original.role}
        </span>
      </div>
    ),
  },
  {
    Header: "No of Users",
    accessor: "providers",
    Cell: ({ row }) => (
      <div className="table-flex">
        <span style={{ marginLeft: "12px" }}>{row.original.count}</span>
      </div>
    ),
  },
  {
    Header: "Role Description",
    accessor: "desc",
    Cell: ({ row }) => (
      <div className="table-flex">
        <span style={{ marginLeft: "12px" }}>{row.original.description}</span>
      </div>
    ),
  },
  {
    Header: "Date Created",
    accessor: "date",
    Cell: ({ row }) => {
      return (
        <div className="table-flex">
          <span className="table-flex-date">
            {moment(row.original.created_at).format("LL")} |{" "}
            <small>{moment(row.original.created_at).format("LT")}</small>
          </span>
        </div>
      );
    },
  },
];
