import Actions from "../Types";

const initialState = {
  isGetting: false,
  roles: [],
  getRolesSuccess: false,

  isSearching: false,
  searchedUser: {},
  searchUserSuccess: false,

  isGettingUsers: false,
  users: [],
  getUsersSuccess: false,

  isSending: false,
  invite: {},
  sendInviteSuccess: false,

  isUpdatingInfo: false,
  updateInfoSuccess: false,
  userInfo: {},

  isDeleting: false,
  deleteSuccess: false,
  deletedUser: {},

  isSearchingAdmin: false,
  searchedAdminUsers: [],
  searchAdminUsersSuccess: false,

  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.GET_ADMIN_ROLES_START:
      return {
        ...state,
        isGetting: true,
        getRolesSuccess: false,
        error: null,
      };
    case Actions.GET_ADMIN_ROLES_SUCCESS:
      return {
        ...state,
        isGetting: false,
        roles: action.payload,
        getRolesSuccess: true,
        error: null,
      };
    case Actions.GET_ADMIN_ROLES_FAILURE:
      return {
        ...state,
        isGetting: false,
        getRolesSuccess: false,
        error: action.payload,
      };

    case Actions.SEARCH_USER_START:
      return {
        ...state,
        isSearching: true,
        searchUserSuccess: false,
        error: null,
      };
    case Actions.SEARCH_USER_SUCCESS:
      return {
        ...state,
        isSearching: false,
        searchedUser: action.payload,
        searchUserSuccess: true,
        error: null,
      };
    case Actions.SEARCH_USER_FAILURE:
      return {
        ...state,
        isSearching: false,
        searchUserSuccess: false,
        error: action.payload,
      };
    case Actions.SEND_INVITE_START:
      return {
        ...state,
        isSending: true,
        sendInviteSuccess: false,
        error: null,
      };
    case Actions.SEND_INVITE_SUCCESS:
      return {
        ...state,
        isSending: false,
        invite: action.payload,
        sendInviteSuccess: true,
        error: null,
      };
    case Actions.SEND_INVITE_FAILURE:
      return {
        ...state,
        isSending: false,
        sendInviteSuccess: false,
        error: action.payload,
      };
    case Actions.GET_USERS_START:
      return {
        ...state,
        isGettingUsers: true,
        getUsersSuccess: false,
        error: null,
      };
    case Actions.GET_USERS_SUCCESS:
      return {
        ...state,
        isGettingUsers: false,
        users: action.payload && action.payload.data,
        usersData: action.payload,
        getUsersSuccess: true,
        error: null,
      };
    case Actions.GET_USERS_FAILURE:
      return {
        ...state,
        isGettingUsers: false,
        getUsersSuccess: false,
        error: action.payload,
      };
    case Actions.UPDATE_USER_INFO_START:
      return {
        ...state,
        isUpdatingInfo: true,
        updateInfoSuccess: false,
        userInfo: {},
        error: null,
      };
    case Actions.UPDATE_USER_INFO_SUCCESS:
      return {
        ...state,
        isUpdatingInfo: false,
        updateInfoSuccess: true,
        userInfo: action.payload,
        error: null,
      };
    case Actions.UPDATE_USER_INFO_FAILURE:
      return {
        ...state,
        isUpdatingInfo: false,
        updateInfoSuccess: false,
        userInfo: {},
        error: action.payload,
      };
    case Actions.DELETE_USER_INFO_START:
      return {
        ...state,
        isDeleting: true,
        deleteSuccess: false,
        error: null,
      };
    case Actions.DELETE_USER_INFO_SUCCESS:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: true,
        deletedUser: action.payload,
        error: null,
      };
    case Actions.DELETE_USER_INFO_FAILURE:
      return {
        ...state,
        isDeleting: false,
        deleteSuccess: false,
        error: action.payload,
      };
    case Actions.SEARCH_ADMIN_USERS_START:
      return {
        ...state,
        isSearchingAdmin: true,
        searchAdminUsersSuccess: false,
        error: null,
      };
    case Actions.SEARCH_ADMIN_USERS_SUCCESS:
      return {
        ...state,
        isSearchingAdmin: false,
        searchedAdminUsers: action.payload,
        searchAdminUsersSuccess: true,
        error: null,
      };
    case Actions.SEARCH_ADMIN_USERS_FAILURE:
      return {
        ...state,
        isSearchingAdmin: false,
        searchAdminUsersSuccess: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reduxState;
