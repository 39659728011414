import Actions from "../Types";

const initialState = {
  isAddingProduct: false,
  addProductSuccess: false,
  product: {},
  isFetching: false,
  fetchProductsSuccess: false,
  isSearching: false,
  searchSuccess: false,
  productsData: {},
  products: [],
  searchedProductsData: {},
  searchedProducts: [],
  isGettingProduct: false,
  getProductSuccess: false,
  singleProduct: {},
  isEditingProduct: false,
  editProductSuccess: false,
  isResetingKey: false,
  resetKeySuccess: false,
  isDeletingProduct: false,
  deleteProductSuccess: false,
  error: null,
};

const reduxState = (state = initialState, action) => {
  switch (action.type) {
    case Actions.ADD_PRODUCT_START:
      return {
        ...state,
        isAddingProduct: true,
        addProductSuccess: false,
        product: {},
        error: null,
      };
    case Actions.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        isAddingProduct: false,
        addProductSuccess: true,
        product: action.payload,
        error: null,
      };
    case Actions.ADD_PRODUCT_FAILURE:
      return {
        ...state,
        isAddingProduct: false,
        addProductSuccess: false,
        product: {},
        error: action.payload,
      };
    case Actions.GET_SINGLE_PRODUCT_START:
      return {
        ...state,
        isGettingProduct: true,
        addProductSuccess: false,
        singleProduct: {},
        error: null,
      };
    case Actions.GET_SINGLE_PRODUCT_SUCCESS:
      return {
        ...state,
        isGettingProduct: false,
        getProductSuccess: true,
        singleProduct: action.payload,
        error: null,
      };
    case Actions.GET_SINGLE_PRODUCT_FAILURE:
      return {
        ...state,
        isGettingProduct: false,
        getProductSuccess: false,
        singleProduct: {},
        error: action.payload,
      };
    case Actions.EDIT_PRODUCT_START:
      return {
        ...state,
        isEditingProduct: true,
        editProductSuccess: false,
        product: {},
        error: null,
      };
    case Actions.EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        isEditingProduct: false,
        editProductSuccess: true,
        product: action.payload,
        error: null,
      };
    case Actions.EDIT_PRODUCT_FAILURE:
      return {
        ...state,
        isEditingProduct: false,
        editProductSuccess: false,
        product: {},
        error: action.payload,
      };
    case Actions.RESET_PRODUCT_KEY_START:
      return {
        ...state,
        isResetingKey: true,
        resetKeySuccess: false,
        error: null,
      };
    case Actions.RESET_PRODUCT_KEY_SUCCESS:
      return {
        ...state,
        isResetingKey: false,
        resetKeySuccess: true,
        error: null,
      };
    case Actions.RESET_PRODUCT_KEY_FAILURE:
      return {
        ...state,
        isResetingKey: false,
        resetKeySuccess: false,
        error: action.payload,
      };
    case Actions.DELETE_PRODUCT_START:
      return {
        ...state,
        isDeletingProduct: true,
        deleteProductSuccess: false,
        product: {},
        error: null,
      };
    case Actions.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        isDeletingProduct: false,
        deleteProductSuccess: true,
        product: action.payload,
        error: null,
      };
    case Actions.DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        isDeletingProduct: false,
        deleteProductSuccess: false,
        product: {},
        error: action.payload,
      };
    case Actions.FETCH_PRODUCTS_START:
      return {
        ...state,
        isFetching: true,
        fetchProductsSuccess: false,
        productsData: {},
        products: [],
        error: null,
      };
    case Actions.FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        fetchProductsSuccess: true,
        productsData: action.payload,
        products: action.payload && action.payload.data,
        error: null,
      };
    case Actions.FETCH_PRODUCTS_FAILURE:
      return {
        ...state,
        isFetching: false,
        fetchProductsSuccess: false,
        productsData: {},
        products: [],
        error: action.payload,
      };
    case Actions.SEARCH_PRODUCTS_START:
      return {
        ...state,
        isSearching: true,
        searchSuccess: false,
        searchedProductsData: {},
        searchedProducts: [],
        error: null,
      };
    case Actions.SEARCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        isSearching: false,
        searchSuccess: true,
        searchedProductsData: action.payload,
        searchedProducts: action.payload && action.payload.data,
        error: null,
      };
    case Actions.SEARCH_PRODUCTS_FAILURE:
      return {
        ...state,
        isSearching: false,
        searchSuccess: false,
        searchedProductsData: {},
        searchedProducts: [],
        error: action.payload,
      };
    default:
      return state;
  }
};

export default reduxState;
