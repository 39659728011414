import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/brand-logo-blue.svg";

export const Headers = (props) => {
  const { title1, title2 } = props;
  return (
    <div className="login-headers">
      <h1>{title1}</h1>
      <h4>{title2}</h4>
    </div>
  );
};

Headers.propTypes = {
  title1: PropTypes.any,
  title2: PropTypes.any,
};

const AuthWrapper = (props) => {
  const { children } = props;
  return (
    <div className="container">
      <div className="auth-wrap">
        <Link to="/">
          <div className="brand-logo">
            <img src={Logo} alt="logo" />
          </div>
        </Link>
        <div className="auth-form">{children}</div>
      </div>
    </div>
  );
};

AuthWrapper.propTypes = {
  children: PropTypes.any,
};

export default AuthWrapper;
