import PropTypes from "prop-types";
import React, { useEffect } from "react";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import LineChart from "../../components/Chart/LineChart";
import Donut from "../../components/Chart/DonutChart";
import SmsProgress from "../../components/Chart/ProgressChart";
import { connect } from "react-redux";
import {
  getDashboardMetrics,
  getGraphMetrics,
} from "../../redux/Actions/DashboardAction";
import { LoaderSpin } from "../../components/Loader/Loader";

export const Dashboard = (props) => {
  const {
    getMetrics,
    isGettingMetrics,
    metrics,
    getGraph,
    isGetting,
    graph,
    getSuccess,
    getMetricsSuccess,
  } = props;

  useEffect(
    () => {
      getMetrics();
      getGraph("daily");
    }, // eslint-disable-next-line
    []
  );

  const smsCount = [
    {
      id: 1,
      total: metrics && metrics.totalSmsCount,
      title: "Total SMS Sent",
    },
    {
      id: 2,
      total: metrics && metrics.totalSmsApiProviders,
      title: "Total SMS Providers",
    },
    {
      id: 3,
      total: metrics && metrics.totalAdmin,
      title: "Total No. of Admin",
    },
    {
      id: 4,
      total: metrics && metrics.totalCountries,
      title: "Countries",
    },
  ];

  return (
    <div>
      <HeaderWrapper title={"Dashboard"}></HeaderWrapper>
      {isGettingMetrics ? (
        <LoaderSpin width="40px" height="40px" />
      ) : (
        getMetricsSuccess && (
          <>
            <div className="sms-wrap">
              {smsCount &&
                smsCount.map((count) => {
                  return (
                    <div className="sms-wrap-count" key={count.id}>
                      <div className="sms-wrap-count-total">{count.total}</div>
                      <div className="sms-wrap-count-title">{count.title}</div>
                    </div>
                  );
                })}
            </div>

            <LineChart
              getGraph={getGraph}
              isGetting={isGetting}
              graph={graph}
              getSuccess={getSuccess}
            />

            <div className="chart-wrap2">
              <SmsProgress metrics={metrics} />
              <Donut metrics={metrics} />
            </div>
          </>
        )
      )}
    </div>
  );
};

Dashboard.propTypes = {
  getGraph: PropTypes.func,
  getMetrics: PropTypes.func,
  getMetricsSuccess: PropTypes.bool,
  getSuccess: PropTypes.bool,
  graph: PropTypes.any,
  isGetting: PropTypes.bool,
  isGettingMetrics: PropTypes.bool,
  metrics: PropTypes.shape({
    totalAdmin: PropTypes.any,
    totalCountries: PropTypes.any,
    totalSmsApiProviders: PropTypes.any,
    totalSmsCount: PropTypes.any,
  }),
};

const mapDispatchToProps = (dispatch) => ({
  getMetrics: () => dispatch(getDashboardMetrics()),
  getGraph: (duration) => dispatch(getGraphMetrics(duration)),
});

const mapStateToProps = (state) => ({
  isGettingMetrics: state.dashboard.isGettingMetrics,
  getMetricsSuccess: state.dashboard.getMetricsSuccess,
  metrics: state.dashboard.metrics,
  isGetting: state.dashboard.isGetting,
  getSuccess: state.dashboard.getSuccess,
  graph: state.dashboard.graph,
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
