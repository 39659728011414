import PropTypes from "prop-types";
import React from "react";
import Input from "../../Input/Input";
import { AddMore } from "./Components";

export const MoreKeys = (props) => {
  const { state, setState } = props;

  const addNewRow = () => {
    setState({
      ...state,
      addedKeys: state.addedKeys.concat([{ key: "", value: "" }]),
    });
  };

  const setSelectedKey = (index) => (evt) => {
    const newAddedKeys = state.addedKeys.map((obj, idx) => {
      if (index !== idx) return obj;
      return { ...obj, key: evt.target.value };
    });
    setState({ ...state, addedKeys: newAddedKeys });
  };

  const setSelectedValue = (index) => (evt) => {
    const newAddedKeys = state.addedKeys.map((obj, idx) => {
      if (index !== idx) return obj;
      return { ...obj, value: evt.target.value };
    });
    setState({ ...state, addedKeys: newAddedKeys });
  };

  return (
    <div>
      {state.addedKeys &&
        state.addedKeys.map((country, index) => (
          <div className="form-grid2" key={index}>
            <Input
              name="key"
              label="Key"
              placeholder="Key"
              value={country.key}
              onChange={setSelectedKey(index)}
            />

            <Input
              name="value"
              label="Value"
              placeholder="Value"
              value={country.value}
              onChange={setSelectedValue(index)}
            />
          </div>
        ))}

      <AddMore onClick={addNewRow} />
    </div>
  );
};

MoreKeys.propTypes = {
  setState: PropTypes.func,
  state: PropTypes.shape({
    addedKeys: PropTypes.shape({
      concat: PropTypes.func,
      map: PropTypes.func,
    }),
  }),
};
