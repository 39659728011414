import React from "react";
import { PropTypes } from "prop-types";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Label from "./Label";

const DateFilter = ({
  fixedDate,
  placeholder,
  onChange,
  label,
}) => {
  return (
    <Label label={label}>
      <DatePicker
        selected={fixedDate}
        onChange={onChange}
        placeholderText={placeholder}
        dateFormat={"dd-MM-yyyy"}
      />
    </Label>
  );
};

DateFilter.propTypes = {
  fixedDate: PropTypes.any,
  label: PropTypes.any,
  onChange: PropTypes.any,
  placeholder: PropTypes.any,
  subLabel: PropTypes.any
}


export default DateFilter;
