import { combineReducers } from "redux";
import AuthReducer from "./Reducers/AuthReducer";
import ProviderReducer from "./Reducers/ProviderReducer";
import AdminReducer from "./Reducers/AdminReducer";
import ProductReducer from "./Reducers/ProductReducer";
import AuthoriserReducer from "./Reducers/AuthoriserReducer";
import TrailsReducer from "./Reducers/TrailsReducer";
import ReportingReducer from "./Reducers/ReportingReducer";
import RouterReportingReducer from "./Reducers/RouterReportingReducer";
import RanksReducer from "./Reducers/RanksReducer";
import DashboardReducer from "./Reducers/DashboardReducer";

export default combineReducers({
  auth: AuthReducer,
  provider: ProviderReducer,
  admin: AdminReducer,
  product: ProductReducer,
  checker: AuthoriserReducer,
  trails: TrailsReducer,
  reporting: ReportingReducer,
  router_reporting: RouterReportingReducer,
  ranks: RanksReducer,
  dashboard: DashboardReducer,
});
