import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { deleteUserInfo } from "../../redux/Actions/AdminActions";
import DeleteModal from "../Modal/DeleteModal";

const DeleteUser = (props) => {
  const {
    displayModal,
    deleteUserInfo,
    isDeleting,
    // deletedUser,
    deleteSuccess,
    data,
  } = props;

  const closeModal = () => {
    props.closeModal();
  };

  useEffect(
    () => {
      if (deleteSuccess) {
        closeModal();
      }
    }, // eslint-disable-next-line
    [deleteSuccess]
  );

  const handleDeleteUser = () => {
    deleteUserInfo(data.id);
  };

  return (
    <DeleteModal
      displayModal={displayModal}
      closeModal={closeModal}
      title={"Delete User"}
      type={"delete"}
      text={"Delete"}
      width={"496px"}
      disabled={isDeleting}
      name={data && data.name}
      isLoading={isDeleting}
      loadingText={"Deleting..."}
      show={false}
      onClick={handleDeleteUser}
    />
  );
};

DeleteUser.propTypes = {
  closeModal: PropTypes.func,
  data: PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.any,
  }),
  deleteSuccess: PropTypes.any,
  deleteUserInfo: PropTypes.func,
  displayModal: PropTypes.any,
  isDeleting: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  deleteUserInfo: (id) => dispatch(deleteUserInfo(id)),
});

const mapStateToProps = (state) => ({
  isDeleting: state.admin.isDeleting,
  deleteSuccess: state.admin.deleteSuccess,
  deletedUser: state.admin.deletedUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(DeleteUser);
