import PropTypes from "prop-types";
import React, { useEffect } from "react";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import Back from "../../assets/back.svg";
import {
  ErrorBtn,
  PendingBtn,
  SuccessBtn,
} from "../../components/Tables/TableButtons";
import { getSingleReport } from "../../redux/Actions/RouterReportingActions";
import { connect } from "react-redux";
import { LoaderSpin } from "../../components/Loader/Loader";
import moment from "moment";
import ReactCountryFlag from "react-country-flag";
import {countryCodeToName, countryCodeToProvider} from "../../utils/mappers/mappers.js"

export const RouterReportDetails = (props) => {
  const { isGettingSingleReport, getSingleReport, report } = props;

  const data = props.location.state && props.location.state.data;
  const id = data && data.reference_id;

  useEffect(
    () => {
      getSingleReport(id);
    },
    // eslint-disable-next-line
    []
  );

  return (
    <div>
      <HeaderWrapper
        title={"Router Reporting"}
        back={Back}
        route={"/router_reporting"}
      ></HeaderWrapper>

      {isGettingSingleReport ? (
        <LoaderSpin width="40px" height="40px" />
      ) : (
        <div className="report-wrap">
          <div className="report-details">
            <div className="report-details-title">Provider Name</div>
            <div className="report-details-title">Request Received</div>
            <div className="report-details-title">Sms Sent</div>
            <div className="report-details-title">Country</div>
            <div className="report-details-info">
              {report && countryCodeToProvider[report.country_code]}
            </div>
            <div className="report-details-info">
              {moment(report && report.request_received).format("LL")} |&nbsp;
              {moment(report && report.request_received).format("LTS")}
            </div>
            <div className="report-details-info">
              {moment(report && report.sms_sent).format("LL")} |&nbsp;
              {moment(report && report.sms_sent).format("LTS")}
            </div>
            <div className="report-details-info">
              <ReactCountryFlag
                countryCode={`${report && report.country_code}`}
                style={{
                  width: "24px",
                  height: "24px",
                  borderRadius: "50%",
                }}
                svg
                title={`${report && countryCodeToName[report.country_code]}`}
              />
              <span style={{ marginLeft: "4px" }}>
                &nbsp;{report && countryCodeToName[report.country_code]}
              </span>
            </div>
          </div>

          <div className="report-details report-message">
            <div className="report-details-title">Message Source</div>
            <div className="report-details-title">Destination Phone No.</div>
            <div className="report-details-title">Status</div>
            <div className="report-details-title">Provider Message Id</div>
            <div className="report-details-info">{report && report.sender}</div>
            <div className="report-details-info">
              &#x2b; {report && report.destination}
            </div>
            <div className="report-details-info">
            {(report && report.success === true) ? (
                <SuccessBtn
                  text="success"
                  className="success-btn2"
                />
              ) : (
                (report && report.success === false)  ? (
                  <ErrorBtn
                    text="failed"
                    className="error-btn2"
                  />
                ) : (
                  report &&
                  report.status === null && (
                    <PendingBtn
                      text="pending"
                      className="pending-btn2"
                    />
                  )
                )
              )}
            </div>
            <div className="report-details-info">{report.provider_message_id}</div>
          </div>

          <div className="report-details-title2">
            <div className="report-details-title">Message</div>
            <div className="report-details-info info-wrap">
              {report && report.message}
            </div>
          </div>
          {report.logs && report.logs.map(log => {
            return (<div className="report-details-title2">
            <div className="report-details-title">Log {moment(log.log_date).format("LL") + " | " + moment(log.log_date).format("LTS")}</div>
            <div className="report-detail-request">
              <pre>{log.message}</pre>
            </div>
          </div>);
          })}
          

        </div>
      )}
    </div>
  );
};

RouterReportDetails.propTypes = {
  getSingleReport: PropTypes.func,
  isGettingSingleReport: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      data: PropTypes.any,
    }),
  }),
  report: PropTypes.shape({
    country: PropTypes.any,
    country_code: PropTypes.any,
    created_at: PropTypes.any,
    destination: PropTypes.any,
    message: PropTypes.any,
    provider_name: PropTypes.any,
    request: PropTypes.any,
    response: PropTypes.any,
    source: PropTypes.any,
    status: PropTypes.string,
  }),
};

const mapStateToProps = (state) => ({
  isGettingSingleReport: state.router_reporting.isGettingSingleReport,
  getSingleReportSuccess: state.router_reporting.getSingleReportSuccess,
  report: state.router_reporting.report,
});

const mapDispatchToProps = (dispatch) => ({
  getSingleReport: (id) => dispatch(getSingleReport(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouterReportDetails);
