import React from "react";
import { useState } from "react";
import { nameAcronym } from "../../../utils/functions/acroymn";
import Modal from "../../Modal/Modal";
import TableDropdown from "../../Tables/Dropdown";
import { DropBtn, ErrorBtn, SuccessBtn } from "../../Tables/TableButtons";
import EditInfoModal from "../../Admin/EditInfoModal";
import {
  cancelModal,
  viewModal,
} from "../../../pages/SMS Providers/SMSProviders";
import moment from "moment";
import DeleteUser from "../../Admin/DeleteUser";

export const userColumns = [
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ row }) => (
      <div className="table-flex">
        <div className="table-flex-image">{nameAcronym(row.original.name)}</div>
        <span style={{ marginLeft: "12px" }}>{row.original.name}</span>
      </div>
    ),
  },
  {
    Header: "Email",
    accessor: "email",
    Cell: ({ row }) => <div className="table-email">{row.original.email}</div>,
  },
  {
    Header: "Role",
    accessor: "type",
    Cell: ({ row }) =>
      row.original.type === "maker"
        ? "Maker"
        : row.original.type === "checker"
        ? "Checker"
        : "Viewer",
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row }) =>
      row.original.enabled ? (
        <SuccessBtn text={"Active"} />
      ) : (
        <ErrorBtn text={"Inactive"} />
      ),
  },
  {
    Header: "Date Added",
    accessor: "date",

    Cell: ({ row }) => {
      const [show, setShow] = useState(false);

      const [state, setState] = useState({
        editInfoModal: false,
        deleteModal: false,
        deactivateModal: false,
      });

      const openModal = (type) => {
        viewModal(type, state, setState);
        setShow((prevCheck) => !prevCheck);
      };

      const options = [
        {
          name: "Edit",
          action: () => openModal("editInfoModal"),
        },
        {
          name: "Delete",
          action: () => openModal("deleteModal"),
        },
      ];

      return (
        <div className="table-flex">
          <span className="table-flex-date">
            {moment(row.original.created_at).format("LL")} |{" "}
            <small>{moment(row.original.created_at).format("LT")}</small>
          </span>
          <DropBtn
            marginLeft={"16px"}
            onClick={() => setShow((prevCheck) => !prevCheck)}
          />

          {show && <TableDropdown options={options} />}

          {state.editInfoModal && (
            <Modal
              displayModal={state.editInfoModal}
              closeModal={() => cancelModal("editInfoModal", state, setState)}
              title={"Edit Info"}
              width={"496px"}
            >
              <EditInfoModal
                data={row.original}
                cancelModal={cancelModal}
                closeModal={() => cancelModal("editInfoModal", state, setState)}
              />
            </Modal>
          )}

          <DeleteUser
            displayModal={state.deleteModal}
            closeModal={() => cancelModal("deleteModal", state, setState)}
            data={row.original}
          />
        </div>
      );
    },
  },
];
