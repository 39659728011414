import Actions from "../Types";
import Instance from "../../utils/services/api";
import responseHandler from "../../utils/notifications/responseHandler";
import Storage from "../../utils/services/storage";

// GET LOGIN TRAILS
export const getLoginTrailsStart = () => ({
  type: Actions.GET_LOGIN_TRAILS_START,
});

export const getLoginTrailsSuccess = (payload) => ({
  type: Actions.GET_LOGIN_TRAILS_SUCCESS,
  payload,
});

export const getLoginTrailsFailure = (payload) => ({
  type: Actions.GET_LOGIN_TRAILS_FAILURE,
  payload,
});

// GET AUDIT TRAILS
export const getAuditTrailsStart = () => ({
  type: Actions.GET_AUDIT_TRAILS_START,
});

export const getAuditTrailsSuccess = (payload) => ({
  type: Actions.GET_AUDIT_TRAILS_SUCCESS,
  payload,
});

export const getAuditTrailsFailure = (payload) => ({
  type: Actions.GET_AUDIT_TRAILS_FAILURE,
  payload,
});

// SEARCH AUDIT TRAILS
export const searchAuditTrailsStart = () => ({
  type: Actions.SEARCH_AUDIT_TRAILS_START,
});

export const searchAuditTrailsSuccess = (payload) => ({
  type: Actions.SEARCH_AUDIT_TRAILS_SUCCESS,
  payload,
});

export const searchAuditTrailsFailure = (payload) => ({
  type: Actions.SEARCH_AUDIT_TRAILS_FAILURE,
  payload,
});

// SEARCH LOGIN TRAILS
export const searchLoginTrailsStart = () => ({
  type: Actions.SEARCH_LOGIN_TRAILS_START,
});

export const searchLoginTrailsSuccess = (payload) => ({
  type: Actions.SEARCH_LOGIN_TRAILS_SUCCESS,
  payload,
});

export const searchLoginTrailsFailure = (payload) => ({
  type: Actions.SEARCH_LOGIN_TRAILS_FAILURE,
  payload,
});

export const getLoginTrails = (page, limit) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(getLoginTrailsStart());
  API.get(`users/login-logs?page=${page}&limit=${limit}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getLoginTrailsSuccess(data && data.data));
    } else {
      dispatch(getLoginTrailsFailure(data));
      responseHandler(response);
    }
  });
};

export const getAuditTrails = (page, limit) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(getAuditTrailsStart());
  API.get(`logs/audit-trail?page=${page}&limit=${limit}`).then((response) => {
    const { data, ok } = response;
    if (ok) {
      dispatch(getAuditTrailsSuccess(data && data.data));
    } else {
      dispatch(getAuditTrailsFailure(data));
      responseHandler(response);
    }
  });
};

export const searchAuditTrails = (page, limit, search) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(searchAuditTrailsStart());
  API.get(`logs/audit-trail?page=${page}&limit=${limit}&search=${search}`).then(
    (response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(searchAuditTrailsSuccess(data && data.data));
      } else {
        dispatch(searchAuditTrailsFailure(data));
        responseHandler(response);
      }
    }
  );
};

export const searchLoginTrails = (page, limit, search) => async (dispatch) => {
  const API = Instance.customAxiosInstance(Storage.get("user-access-token"));

  dispatch(searchLoginTrailsStart());
  API.get(`users/login-logs?page=${page}&limit=${limit}&search=${search}`).then(
    (response) => {
      const { data, ok } = response;
      if (ok) {
        dispatch(searchLoginTrailsSuccess(data && data.data));
      } else {
        dispatch(searchLoginTrailsFailure(data));
        responseHandler(response);
      }
    }
  );
};
