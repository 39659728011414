import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Search from "../../components/Sorting/Search";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import SwitchWrapper from "../../components/Wrappers/SwitchWrapper";
import {
  getAuditTrails,
  getLoginTrails,
  searchAuditTrails,
  searchLoginTrails,
} from "../../redux/Actions/TrailsActions";
import AuditTrail from "./AuditTrail";
import LoginTrail from "./LoginTrail";

export const Trails = (props) => {
  const {
    isFetchingLogs,
    getLoginTrailsSuccess,
    loginTrailsData,
    loginTrails,
    getLoginTrails,
    getAuditTrails,
    isFetchingAudit,
    getAuditTrailsSuccess,
    auditTrailsData,
    auditTrails,
    isSearching,
    searchSuccess,
    searchedAudit,
    searchedAuditData,
    searchAudit,
    isSearchingLogin,
    searchLoginSuccess,
    searchedLogin,
    searchedLoginData,
    searchLogin,
  } = props;
  const [state, setState] = useState({
    active1: true,
    active2: false,
    searchValue: "",
    searchLoginValue: "",
    showSearchResults: false,
    showSearchLoginResults: false,
  });

  const [searchState, setSearchState] = useState({
    searchValue: "",
  });

  useEffect(
    () => {
      if (searchSuccess) {
        setState({
          ...state,
          showSearchResults: true,
        });
      }
    },
    // eslint-disable-next-line
    [searchSuccess]
  );

  useEffect(
    () => {
      if (searchLoginSuccess) {
        setState({
          ...state,
          showSearchLoginResults: true,
        });
      }
    },
    // eslint-disable-next-line
    [searchLoginSuccess]
  );

  const searchAuditItems = () => {
    if (state.searchValue === "") {
      setState({
        ...state,
        showSearchResults: false,
      });
      return auditTrailsData;
    } else {
      searchAudit(1, 10, state.searchValue);
    }
  };

  const searchLoginItems = () => {
    if (searchState.searchValue === "") {
      setState({
        ...state,
        showSearchLoginResults: false,
      });
      return loginTrailsData;
    } else {
      searchLogin(1, 10, searchState.searchValue);
    }
  };

  return (
    <div>
      <HeaderWrapper title={"Trails"} />

      <SwitchWrapper
        title1={"Audit Trails"}
        title2={"Login Trails"}
        state={state}
        setstate={setState}
        component1={
          <AuditTrail
            getAuditTrails={getAuditTrails}
            auditTrailsData={auditTrailsData}
            auditTrails={auditTrails}
            isFetchingAudit={isFetchingAudit}
            getAuditTrailsSuccess={getAuditTrailsSuccess}
            state={state}
            searchSuccess={searchSuccess}
            isSearching={isSearching}
            searchedAudit={searchedAudit}
            searchedAuditData={searchedAuditData}
            searchAudit={searchAudit}
          />
        }
        component2={
          <LoginTrail
            loginTrailsData={loginTrailsData}
            isFetchingLogs={isFetchingLogs}
            getLoginTrailsSuccess={getLoginTrailsSuccess}
            loginTrails={loginTrails}
            state={state}
            searchState={searchState}
            searchSuccess={searchLoginSuccess}
            searchLogin={searchLogin}
            searchedLoginData={searchedLoginData}
            isSearching={isSearchingLogin}
            searchedLogin={searchedLogin}
            getLoginTrails={getLoginTrails}
          />
        }
        right1={
          <Search
            width={"509px"}
            placeholder={"Search audit trails"}
            searchItems={searchAuditItems}
            setState={setState}
            state={state}
          />
        }
        right2={
          <Search
            width={"509px"}
            placeholder={"Search login trails"}
            searchItems={searchLoginItems}
            setState={setSearchState}
            state={searchState}
          />
        }
      />
    </div>
  );
};

Trails.propTypes = {
  auditTrails: PropTypes.any,
  auditTrailsData: PropTypes.any,
  getAuditTrails: PropTypes.any,
  getAuditTrailsSuccess: PropTypes.any,
  getLoginTrails: PropTypes.any,
  getLoginTrailsSuccess: PropTypes.any,
  isFetchingAudit: PropTypes.bool,
  isFetchingLogs: PropTypes.bool,
  isSearching: PropTypes.bool,
  isSearchingLogin: PropTypes.bool,
  loginTrails: PropTypes.any,
  loginTrailsData: PropTypes.any,
  searchAudit: PropTypes.func,
  searchLogin: PropTypes.func,
  searchLoginSuccess: PropTypes.any,
  searchSuccess: PropTypes.any,
  searchedAudit: PropTypes.any,
  searchedAuditData: PropTypes.any,
  searchedLogin: PropTypes.any,
  searchedLoginData: PropTypes.any,
};

const mapDispatchToProps = (dispatch) => ({
  getLoginTrails: (page, limit) => dispatch(getLoginTrails(page, limit)),
  getAuditTrails: (page, limit) => dispatch(getAuditTrails(page, limit)),
  searchAudit: (page, limit, search) =>
    dispatch(searchAuditTrails(page, limit, search)),
  searchLogin: (page, limit, search) =>
    dispatch(searchLoginTrails(page, limit, search)),
});

const mapStateToProps = (state) => ({
  isFetchingLogs: state.trails.isFetchingLogs,
  getLoginTrailsSuccess: state.trails.getLoginTrailsSuccess,
  loginTrailsData: state.trails.loginTrailsData,
  loginTrails: state.trails.loginTrails,
  isFetchingAudit: state.trails.isFetchingAudit,
  getAuditTrailsSuccess: state.trails.getAuditTrailsSuccess,
  auditTrailsData: state.trails.auditTrailsData,
  auditTrails: state.trails.auditTrails,
  isSearching: state.trails.isSearching,
  searchSuccess: state.trails.searchSuccess,
  searchedAuditData: state.trails.searchedAuditData,
  searchedAudit: state.trails.searchedAudit,
  isSearchingLogin: state.trails.isSearchingLogin,
  searchLoginSuccess: state.trails.searchLoginSuccess,
  searchedLoginData: state.trails.searchedLoginData,
  searchedLogin: state.trails.searchedLogin,
});

export default connect(mapStateToProps, mapDispatchToProps)(Trails);
