import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router";
import Button from "../../components/Button/Button";
import { Failed } from "../../components/Verification/Failed";
import { Loading } from "../../components/Verification/Loading";
import { Success } from "../../components/Verification/Success";
import AuthWrapper from "../../components/Wrappers/AuthWrapper";
import {
  resendUserInvite,
  verifyInvite,
} from "../../redux/Actions/AuthActions";

export const VerifyInvite = (props) => {
  const {
    isVerifying,
    verifySuccess,
    verifyUser,
    isResending,
    resendUserInvite,
    resendInviteSuccess,
  } = props;

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();

  const inviteCode = query.get("invite-code");

  useEffect(
    () => {
      if (inviteCode) {
        const payload = {
          code: inviteCode,
        };
        verifyUser(payload);
      }
    },
    // eslint-disable-next-line
    []
  );

  const resendInvite = () => {
    const payload = {
      code: inviteCode,
    };
    resendUserInvite(payload);
  };

  return (
    <AuthWrapper>
      {isVerifying ? (
        <Loading text={"Verifying"} />
      ) : verifySuccess ? (
        <Success
          maintext={"Successful"}
          belowtext={"Email verification was successful"}
        >
          <Button
            width={"100%"}
            height={"48px"}
            testId={"button"}
            type={"submit"}
            marginTop={"40px"}
          >
            <div className="button-data">
              <a href="/">Continue to Log In</a>
            </div>
          </Button>
        </Success>
      ) : (
        <Failed
          maintext={"Not Successful"}
          belowtext={"Email verification was not successful"}
        >
          {resendInviteSuccess ? (
            <div className="invite-loader-message">
              Check back later to continue your verification
            </div>
          ) : (
            <div
              className="invite-loader-resend"
              onClick={() => resendInvite()}
            >
              {isResending ? "Resending code..." : "Resend Code"}
            </div>
          )}
        </Failed>
      )}
    </AuthWrapper>
  );
};

VerifyInvite.propTypes = {
  isResending: PropTypes.bool,
  isVerifying: PropTypes.bool,
  resendInviteSuccess: PropTypes.bool,
  resendUserInvite: PropTypes.func,
  verifySuccess: PropTypes.bool,
  verifyUser: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isVerifying: state.auth.isVerifying,
  verifySuccess: state.auth.verifySuccess,
  isResending: state.auth.isResendingInvite,
  resendInviteSuccess: state.auth.resendInviteSuccess,
  error: state.auth.error,
});

const mapDispatchToProps = (dispatch) => ({
  verifyUser: (payload) => dispatch(verifyInvite(payload)),
  resendUserInvite: (payload) => dispatch(resendUserInvite(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyInvite);
