import PropTypes from "prop-types";
import React from "react";
import Input from "../../Input/Input";
import Button from "../../Button/Button";
import Example from "./Example";
import { StatusResponse } from "./Components";
import nohook from "../../../assets/nohook.svg";
import addhook from "../../../assets/addhook.svg";

const ProviderScreen2 = (props) => {
  const { setStep1, setStep3, state, setState, values, webHookUrl } = props;
  const toggleSelection = (selection, selection2) => {
    setState({
      ...state,
      [selection]: true,
      [selection2]: false,
    });
  };

  const showExample = () => {
    setState({
      ...state,
      showExample: true,
    });
  };

  const hideExample = () => {
    setState({
      ...state,
      showExample: false,
    });
  };

  const disableBtn =
    values.initial_statusPath === "" || values.initial_messagePath === "";

  const disableBtn2 =
    values.initial_statusPath === "" ||
    values.initial_messagePath === "" ||
    values.final_statusPath === "" ||
    values.final_messagePath === "";

  const disableBtn3 = state.addHook === false && state.noHook === false;

  return state.showExample ? (
    <Example hideExample={hideExample} />
  ) : (
    <div>
      <div className="flex-steps">
        <p className="steps">Step 2 of 4</p>

        <span onClick={showExample}>See example</span>
      </div>

      <StatusResponse
        title="Initial Response"
        name1="initial_statusPath"
        label1="Status Path"
        placeholder1="Status Path"
        name2="initial_messagePath"
        label2="Messsage ID Path"
        placeholder2="Messsage ID Path"
      />

      <div className="web-hook">
        <h2>Does this provider have a web hook?</h2>
        <div className="web-hook-select">
          <div
            className="web-hook-select-btn"
            onClick={() => toggleSelection("addHook", "noHook")}
          >
            <img src={state.addHook ? addhook : nohook} alt="select-web-hook" />
          </div>
          <span>Yes It does</span>
        </div>

        <div className="web-hook-select">
          <div
            className="web-hook-select-btn"
            onClick={() => toggleSelection("noHook", "addHook")}
          >
            <img src={state.noHook ? addhook : nohook} alt="select-web-hook" />
          </div>
          <span>No it doesn’t</span>
        </div>
      </div>

      {state.addHook && (
        <>
          <div style={{ marginTop: "6px" }}>
            <StatusResponse
              title="Final Response"
              name1="final_statusPath"
              label1="Status Path"
              placeholder1="Status Path"
              name2="final_messagePath"
              label2="Messsage ID Path"
              placeholder2="Messsage ID Path"
            />
          </div>

          <div className="web-hook">
            <h2>
              Add this web hook to the sms provider dashboard if applicable
            </h2>
            <Input
              name="web-hook"
              label="Web Hook"
              readOnly
              value={webHookUrl}
            />
          </div>
        </>
      )}

      <div className="provider-btn-flex">
        <Button
          width={"47%"}
          height={"48px"}
          testId={"button"}
          type={"button"}
          marginTop={"32px"}
          className={"button2"}
          onClick={() => setStep1()}
        >
          Previous
        </Button>
        <Button
          width={"47%"}
          height={"48px"}
          testId={"button"}
          type={"button"}
          marginTop={"32px"}
          onClick={() => setStep3()}
          disabled={state.addHook ? disableBtn2 : disableBtn || disableBtn3}
        >
          Next
        </Button>
      </div>
    </div>
  );
};

ProviderScreen2.propTypes = {
  setState: PropTypes.func,
  setStep1: PropTypes.func,
  setStep3: PropTypes.func,
  state: PropTypes.shape({
    addHook: PropTypes.bool,
    noHook: PropTypes.bool,
    showExample: PropTypes.any,
  }),
  values: PropTypes.shape({
    final_messagePath: PropTypes.string,
    final_statusPath: PropTypes.string,
    initial_messagePath: PropTypes.string,
    initial_statusPath: PropTypes.string,
  }),
  webHookUrl: PropTypes.any,
};

export default ProviderScreen2;
