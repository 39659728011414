import PropTypes from "prop-types";
import React from "react";
import { roleColumns } from "../../components/TableData/Admin/Role";
import Table from "../../components/Tables/Table";
import { showSingleDetails } from "./Users";

export const Roles = (props) => {
  const { roles, getRoles, isGetting, getRolesSuccess } = props;

  const moreData = (page, perPage) => {
    getRoles(page, perPage);
  };

  const totalRoles = roles && roles.length;

  return (
    <div>
      <Table
        columns={roleColumns}
        data={roles}
        getData={getRoles}
        emptyText1={"You haven't added any roles"}
        showData={(data) => showSingleDetails(data)}
        getMoreData={(page, perPage) => moreData(page, perPage)}
        isLoading={isGetting}
        success={getRolesSuccess}
        total={totalRoles}
      />
    </div>
  );
};

Roles.propTypes = {
  getRoles: PropTypes.func,
  getRolesSuccess: PropTypes.bool,
  isGetting: PropTypes.bool,
  roles: PropTypes.shape({
    length: PropTypes.number,
  }),
};

export default Roles;
