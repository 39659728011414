import PropTypes from "prop-types";
import React from "react";

export const HeaderWrapper = (props) => {
  const { title, length, children, back, route } = props;
  return (
    <div className="page-headers">
      <div className="page-headers-title">
        {back && (
          <span className="page-headers-back">
            <a href={route}>
              <img src={back} alt="back" />
            </a>
          </span>
        )}
        <h1>{title}</h1> {length && <span>{length}</span>}
      </div>
      <div>{children}</div>
    </div>
  );
};

HeaderWrapper.propTypes = {
  back: PropTypes.any,
  children: PropTypes.any,
  length: PropTypes.any,
  route: PropTypes.any,
  title: PropTypes.any,
};

export default HeaderWrapper;
