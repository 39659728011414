import React from "react";
import { ProviderInput } from "./Components";

export const Smpp = () => {
  return (
    <div className="request">
        <ProviderInput
          name="url"
          label="URL"
          placeholder="Smpp Url"
        />

      <div className="form-grid">
        <ProviderInput
          name="system_id"
          label="System ID"
          placeholder="System ID"
        />
      </div>

      <div className="form-grid">
        <ProviderInput
          name="password"
          type="password"
          label="Password"
          placeholder="Password"
          password
        />
      </div>
    </div>
  );
};
