import React from "react";
import moment from "moment";

export const auditColumns = [
  {
    Header: "User Name",
    accessor: "user_name",
    Cell: ({ row }) => {
      return (
        <div>
          {row.original.user_name === null ? "Nil" : row.original.user_name}
        </div>
      );
    },
  },
  {
    Header: "Activities",
    accessor: "activity",
  },
  {
    Header: "Date Added",
    accessor: "date",
    Cell: ({ row }) => {
      return (
        <div className="table-flex">
          <span className="table-flex-date">
            {moment(row.original.created_at).format("LL")} |{" "}
            <small>{moment(row.original.created_at).format("LT")}</small>
          </span>
        </div>
      );
    },
  },
];
