import React, { useState } from "react";
import { ErrorBtn, DropBtn, SuccessBtn } from "../../Tables/TableButtons";
import ReactCountryFlag from "react-country-flag";
import { nameAcronym } from "../../../utils/functions/acroymn";
import TableDropdown from "../../Tables/Dropdown";
import {
  cancelModal,
  viewModal,
} from "../../../pages/SMS Providers/SMSProviders";
import { countries } from "country-data";
import { useHistory } from "react-router-dom";
import moment from "moment";
import DeleteProvider from "../../Provider/DeleteProvider";

export const providerColumns = [
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ row }) => (
      <div className="table-flex">
        <div className="table-flex-image">{nameAcronym(row.original.name)}</div>
        <span style={{ marginLeft: "12px" }}>{row.original.name}</span>
      </div>
    ),
  },
  {
    Header: "Country",
    accessor: "country",
    Cell: ({ row }) => (
      <div className="table-flex">
        <ReactCountryFlag
          countryCode={`${row.original.country_code}`}
          style={{
            width: "32px",
            height: "32px",
            borderRadius: "50%",
          }}
          svg
          title={`${row.original.country_code}`}
        />
        <span style={{ marginLeft: "12px" }}>{row.original.country}</span>
      </div>
    ),
  },
  {
    Header: "Amount",
    accessor: "price",
    Cell: ({ row }) => (
      <div className="table-flex">
        <span style={{ marginRight: "5px" }}>{row.original.price}</span>
        <div className="country-currency-code">
          {countries &&
            countries[row.original.country_code] &&
            countries[row.original.country_code].currencies &&
            countries[row.original.country_code].currencies[0]}
        </div>
      </div>
    ),
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ row }) =>
      row.original.enabled === true ? (
        <SuccessBtn text={`Active`} />
      ) : (
        <ErrorBtn text={`Disabled`} />
      ),
  },
  {
    Header: "Date",
    accessor: "created_at",
    Cell: ({ row }) => {
      const [show, setShow] = useState(false);

      const [state, setState] = useState({
        editModal: false,
        viewModal: false,
        deleteModal: false,
      });

      const history = useHistory();

      const open = (type) => {
        viewModal(type, state, setState);
        setShow((prevCheck) => !prevCheck);
      };

      const goToSinglePage = () => {
        const id = row.original && row.original.id;
        history.push({
          pathname: `/providers/${id}`,
          state: {
            id: id,
            data: row.original,
          },
        });
      };

      const options = [
        {
          name: "View",
          action: () => goToSinglePage(),
        },
        {
          name: "Delete",
          action: () => open("deleteModal"),
        },
      ];

      return (
        <div className="table-flex">
          <span className="table-flex-date">
            {moment(row.original.created_at).format("LL")} |{" "}
            <small>{moment(row.original.created_at).format("LT")}</small>{" "}
          </span>
          <DropBtn
            marginLeft={"16px"}
            onClick={() => setShow((prevCheck) => !prevCheck)}
          />

          {show && <TableDropdown options={options} />}

          <DeleteProvider
            state={state}
            data={row.original}
            closeModal={() => cancelModal("deleteModal", state, setState)}
          />
        </div>
      );
    },
  },
];
