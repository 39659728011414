import PropTypes from "prop-types";
import React from "react";
import ActiveLogo from "../../assets/active-logo.svg";
import InactiveLogo from "../../assets/inactive-logo.svg";

export const Statuses = (props) => {
  const { state, options, setstate } = props;

  const setActiveState = (index, status) => {
    setstate({
      ...state,
      active: true,
      status: status,
      index: index,
    });
  };

  return (
    <div className="messages-status">
      <p className="messages-status-label">Messages Status</p>

      <div className="messages-status-group">
        {options.map((option, index) => (
          <div
            className="messages-status-group-each"
            key={index}
            onClick={() => setActiveState(index, option.name)}
          >
            <div className="messages-status-group-each-image">
              <img
                src={
                  state.active === true && state.index === index
                    ? ActiveLogo
                    : InactiveLogo
                }
                alt="icon"
              />
            </div>
            <span className="messages-status-group-each-label">
              {option.name}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

Statuses.propTypes = {
  options: PropTypes.shape({
    map: PropTypes.func,
  }),
  setstate: PropTypes.func,
  state: PropTypes.shape({
    active: PropTypes.bool,
    index: PropTypes.any,
  }),
};
