import PropTypes from "prop-types";
import moment from "moment";
import React, { useState } from "react";
import { nameAcronym } from "../../utils/functions/acroymn";
import { LoaderSpin } from "../Loader/Loader";

const Notifications = (props) => {
  const {
    isAuthorising,
    data,
    authoriseRequest,
    isGetting,
    getRequests,
  } = props;

  const [state, setState] = useState({
    index: "",
    type: "",
  });

  const notifications = data && data.data;
  const totalRequests = data && data.total;
  const currentPage = data && data.page && Number(data.page);

  const checkerAuthoriseRequest = (type, item) => {
    const url = item && item.url;
    const payload = {
      status: type === "declined" ? type : "authorized",
      authorizationId: item && item.id && item.id.toString(),
    };
    authoriseRequest(url, payload);
  };

  const setType = (type, item) => {
    setState({
      type: type,
      index: item && item.id,
    });
    checkerAuthoriseRequest(type, item);
  };

  const showMore = () => {
    const page = currentPage + 1;
    const limit = 20;
    getRequests(page, limit);
  };

  return isGetting ? (
    <div>
      <LoaderSpin width="40px" height="40px" />
    </div>
  ) : (
    <div className="requests-notifs">
      {notifications && notifications.length === 0 ? (
        <div>There are no pending requests</div>
      ) : (
        notifications &&
        notifications.map((item, index) => (
          <div className="requests-notifs-single" key={index}>
            <div className="requests-notifs-single-left">
              <div className="requests-notifs-single-left-image">
                {nameAcronym(item.user_name)}
              </div>
            </div>
            <div className="requests-notifs-single-right">
              <div className="action">
                <span>{item.user_name}</span> {item.description}
              </div>
              <h4>{moment(item.created_at).fromNow()}</h4>

              {item.approval_state &&
                item.approval_state.toLowerCase() === "pending" && (
                  <div className="buttons">
                    <button
                      className="decline"
                      data-testid="decline"
                      onClick={() => setType("declined", item)}
                    >
                      {isAuthorising &&
                      state.index === item.id &&
                      state.type === "declined"
                        ? "Declining"
                        : "Decline"}
                    </button>
                    <button
                      className="approve"
                      data-testid="approve"
                      onClick={() => setType("authorized", item)}
                    >
                      {isAuthorising &&
                      state.index === item.id &&
                      state.type === "authorized"
                        ? "Approving"
                        : "Approve"}
                    </button>
                  </div>
                )}
            </div>

            {item.approval_state &&
              item.approval_state.toLowerCase() === "pending" && (
                <div className="requests-notifs-single-pointer"></div>
              )}
          </div>
        ))
      )}
      {totalRequests > 20 ? (
        <div onClick={() => showMore()} className="showMoreBtn">
          Show more...
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

Notifications.propTypes = {
  authoriseRequest: PropTypes.func,
  data: PropTypes.shape({
    data: PropTypes.any,
    page: PropTypes.any,
    total: PropTypes.any,
  }),
  getRequests: PropTypes.func,
  isAuthorising: PropTypes.bool,
  isGetting: PropTypes.bool,
};

export default Notifications;
