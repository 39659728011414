import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import HeaderWrapper from "../../components/Wrappers/HeaderWrapper";
import Button from "../../components/Button/Button";
import Filter from "../../components/Sorting/Filter";
import Search from "../../components/Sorting/Search";
import Modal from "../../components/Modal/Modal";
import { reportColumns } from "../../components/TableData/RouterReport/Report";
import FilterData from "../../components/Sorting/FilterData";
import { cancelModal, viewModal } from "../SMS Providers/SMSProviders";
import TableDropdown from "../../components/Tables/Dropdown";
import SelectTable from "../../components/Tables/SelectTable";
import down from "../../assets/down.svg";
import { connect } from "react-redux";
import {
  getReports,
  searchReports,
  filterReports,
} from "../../redux/Actions/RouterReportingActions";
import JsPDF from "jspdf";
import "jspdf-autotable";
import CsvDownload from "react-json-to-csv";

export const RouterReporting = (props) => {
  const {
    getReports,
    reportsData,
    searchReports,
    filterReports,
    isGettingReports,
    getReportsSuccess,
    reports,
    isSearchingReport,
    searchReportSuccess,
    searchedReports,
    searchedReportsData,
    isFilteringReport,
    filterReportSuccess,
    filteredReports,
    filteredReportsData,
  } = props;
  const [state, setState] = useState({
    filterModal: false,
    searchValue: "",
    showSearchResults: false,
    showFilterResults: false,
    values: {},
  });

  const [show, setShow] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(
    () => {
      if (searchReportSuccess) {
        setState({
          ...state,
          showSearchResults: true,
        });
      }
    },
    // eslint-disable-next-line
    [searchReportSuccess]
  );

  useEffect(
    () => {
      if (filterReportSuccess) {
        setState({
          ...state,
          showFilterResults: true,
          filterModal: false,
        });
      }
    },
    // eslint-disable-next-line
    [filterReportSuccess]
  );

  const searchReportsItems = () => {
    setShow(false);
    if (state.searchValue === "") {
      setState({
        ...state,
        showSearchResults: false,
      });
      return reports;
    } else {
      searchReports(1, 50, state.searchValue);
    }
  };

  const moreData = (page, perPage) => {
    state.showSearchResults
      ? searchReports(page, perPage, state.searchValue)
      : state.showFilterResults
      ? paginateFilter(page, perPage, state.values)
      : getReports(page, perPage);
  };

  const totalReports = state.showSearchResults
    ? searchedReportsData && searchedReportsData.total
    : state.showFilterResults
    ? filteredReportsData && filteredReportsData.total
    : reportsData && reportsData.total;

  const allReports = state.showSearchResults
    ? searchedReports
    : state.showFilterResults
    ? filteredReports
    : reports;

  const options = [
    {
      name: "Delivered",
    },
    {
      name: "Pending",
    },
    {
      name: "Failed",
    },
    {
      name: "Undelivered",
    },
    {
      name: "Success",
    },
  ];

  const getExportFileBlob = (type) => {
    setShow((prevCheck) => !prevCheck);
    if (type === "pdf") {
      const unit = "pt";
      const size = "A4";
      const orientation = "portrait";

      const marginLeft = 40;
      const doc = new JsPDF(orientation, unit, size);

      doc.setFontSize(15);

      const title = "SMS History Report";
      const headers = [
        ["PROVIDER NAME", "COUNTRY", "SOURCE", "DESTINATION", "STATUS", "DATE"],
      ];

      const data =
        selectedRows &&
        selectedRows.map((elt) => [
          elt.provider_name,
          elt.country,
          elt.source,
          elt.destination,
          elt.status,
          elt.created_at,
        ]);

      let content = {
        startY: 50,
        head: headers,
        body: data,
      };

      doc.text(title, marginLeft, 40);
      doc.autoTable(content);
      doc.save("Report.pdf");
    }
  };

  const options2 = [
    {
      name: "PDF",
      action: () => getExportFileBlob("pdf"),
    },
    {
      name: (
        <CsvDownload
          data={selectedRows}
          filename="Report.csv"
          style={{
            background: "none",
            border: "none",
            color: "#3A434B",
            fontSize: "14px",
            fontWeight: "normal",
            padding: "0px 0px",
          }}
        >
          CSV
        </CsvDownload>
      ),
      action: () => getExportFileBlob("csv"),
    },
  ];

  const submitValues = (values) => {
    setState({
      ...state,
      values: values,
    });
    const filterValues = {
      page: 1,
      limit: 10,
      search: values.name,
      country: values.country,
      startDate: values.start_date,
      endDate: values.end_date,
      status:
        values.status === "Delivered"
          ? "delivered"
          : values.status === "Pending"
          ? "pending"
          : values.status === "Failed"
          ? "failed"
          : values.status === "Undelivered"
          ? "undelivered"
          : "success",
    };
    filterReports(filterValues);
  };

  const paginateFilter = (page, limit, values) => {
    const filterValues = {
      page: page,
      limit: limit,
      search: values.name,
      country: values.country,
      startDate: values.start_date,
      endDate: values.end_date,
      status:
        values.status === "Delivered"
          ? "delivered"
          : values.status === "Pending"
          ? "pending"
          : values.status === "Failed"
          ? "failed"
          : values.status === "Undelivered"
          ? "undelivered"
          : "success",
    };
    filterReports(filterValues);
  };

  const openModal = (type) => viewModal(type, state, setState);

  const closeModal = (type) => cancelModal(type, state, setState);

  return (
    <div>
      <HeaderWrapper title={"Router Reporting"}>
        <div className="dropdown-wrap">
          <Button
            width={"auto"}
            disabled={selectedRows.length === 0}
            height={"48px"}
            testId={"button"}
            type={"submit"}
            onClick={() => setShow((prevCheck) => !prevCheck)}
          >
            <div className="button-data">
              <span>Export Report</span> &nbsp;
              <img src={down} alt="down" />
            </div>
          </Button>

          {show && <TableDropdown options={options2} />}
        </div>
      </HeaderWrapper>

      <div className="switch-wrapper-top">
        <span className="reporting-title">
          {totalReports} Report{totalReports > 1 ? "s" : ""}
        </span>
        <div className="flex-options">
          <Search
            width={"509px"}
            placeholder={"Search Phone Number like 255742073745"}
            searchItems={searchReportsItems}
            setState={setState}
            state={state}
          />
          <Filter
            marginLeft={"20px"}
            onClick={() => openModal("filterModal")}
          />
        </div>
      </div>

      <div>
        <SelectTable
          columns={reportColumns}
          data={allReports}
          getData={getReports}
          getMoreData={(page, perPage) => moreData(page, perPage)}
          emptyText1={"You don't have any report"}
          isLoading={isGettingReports || isSearchingReport || isFilteringReport}
          success={
            getReportsSuccess || searchReportSuccess || filterReportSuccess
          }
          emptyResultText={"Report not found!"}
          sortSuccess={searchReportSuccess || filterReportSuccess}
          setSelectedRows={setSelectedRows}
          selectedRows={selectedRows}
          total={totalReports}
        />
      </div>

      <Modal
        displayModal={state.filterModal}
        closeModal={() => closeModal("filterModal")}
        title={"Filter"}
        width={"496px"}
      >
        <FilterData
          options={options}
          submitValues={submitValues}
          isFiltering={isFilteringReport}
        />
      </Modal>
    </div>
  );
};

RouterReporting.propTypes = {
  filterReportSuccess: PropTypes.bool,
  filterReports: PropTypes.func,
  filteredReports: PropTypes.array,
  filteredReportsData: PropTypes.shape({
    total: PropTypes.any,
  }),
  getReports: PropTypes.func,
  getReportsSuccess: PropTypes.bool,
  isFilteringReport: PropTypes.bool,
  isGettingReports: PropTypes.bool,
  isSearchingReport: PropTypes.bool,
  reports: PropTypes.array,
  reportsData: PropTypes.shape({
    total: PropTypes.any,
  }),
  searchReportSuccess: PropTypes.bool,
  searchReports: PropTypes.func,
  searchedReports: PropTypes.array,
  searchedReportsData: PropTypes.shape({
    total: PropTypes.any,
  }),
};

const mapDispatchToProps = (dispatch) => ({
  getReports: (page, limit) => dispatch(getReports(page, limit)),
  searchReports: (page, limit, search) =>
    dispatch(searchReports(page, limit, search)),
  filterReports: (payload) => dispatch(filterReports(payload)),
});

const mapStateToProps = (state) => ({
  isGettingReports: state.router_reporting.isGettingReports,
  getReportsSuccess: state.router_reporting.getReportsSuccess,
  reports: state.router_reporting.reports,
  isSearchingReport: state.router_reporting.isSearchingReport,
  searchReportSuccess: state.router_reporting.searchReportSuccess,
  searchedReports: state.router_reporting.searchedReports,
  isFilteringReport: state.router_reporting.isFilteringReport,
  filterReportSuccess: state.router_reporting.filterReportSuccess,
  filteredReports: state.router_reporting.filteredReports,
  reportsData: state.router_reporting.reportsData,
  searchedReportsData: state.router_reporting.searchedReportsData,
  filteredreportsData: state.router_reporting.filteredreportsData,
});

export default connect(mapStateToProps, mapDispatchToProps)(RouterReporting);
