import PropTypes from "prop-types";
import React, { useState } from "react";
import { ModalButton } from "../Modal/Modal";
import Input from "../Input/Input";
import { ToggleButton } from "../Input/ToggleButton";
import { Field, Form, Formik } from "formik";
import { AddProductsSchema } from "../../utils/validation/validation";

const AddProduct = (props) => {
  const { isAdding, addProduct } = props;

  const [state, setState] = useState({
    checked: true,
  });

  const handleToggle = () => {
    setState({
      ...state,
      checked: (state.checked = !state.checked),
    });
  };

  const initialValues = {
    name: "",
    limit: "",
  };

  const submitValues = (values) => {
    const payload = {
      name: values.name,
      smsRateLimit: values.limit,
      enabled: state.checked,
    };
    addProduct(payload);
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={AddProductsSchema}
        onSubmit={(values) => submitValues(values)}
      >
        {({ isValid, dirty }) => {
          return (
            <Form id="addProduct">
              <Field
                name="name"
                label="Product Name"
                placeholder="Product Name"
                component={Input}
              />

              <div className="edit-email">
                <Field
                  name="limit"
                  label="SMS Limit"
                  placeholder="SMS Limit"
                  component={Input}
                />
                <span className="infinite-limit">
                  Input -1 for infinite limit
                </span>
              </div>

              <ToggleButton
                text={`Toggle to ${
                  state.checked ? "deactivate" : "activate"
                } product`}
                handleChange={handleToggle}
                checked={state.checked}
                id={"edit-info-toggle"}
              />

              <ModalButton
                text={"Save"}
                loadingText={"Saving"}
                isLoading={isAdding}
                disabled={!(isValid && dirty) || isAdding}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

AddProduct.propTypes = {
  addProduct: PropTypes.func,
  isAdding: PropTypes.bool,
};

export default AddProduct;
